<template>
  <div class="container">
    <div class="content">
      <b-row>
        <b-col>
          <b-card v-if="!isSelectRenter && !typeInRenter && renterIds.length < 0" header="Wie möchten Sie den Mieter wählen?">
            <b-row>
              <b-col class="text-center">
                <b-button v-on:click.prevent="setSelectRenter(true)"
                          id="selectRenterBtn"
                          v-if="hasRenter"
                          v-b-tooltip="'Der Mieter ist bereits angelegt und Ihnen bekannt, hier klicken um nach diesem zu suchen und Ihn dann auszuwählen'"
                          variant="success">Bestandsmieter auswählen
                </b-button>
                <b-button
                    v-if="!hasRenter"
                    disabled
                    v-b-tooltip="'Es sind noch keine Mieter angelegt, deshalb kann kein Bestandsmieter verwendet werden.'"
                >Bestandsmieter auswählen
                </b-button>
              </b-col>
              <b-col class="text-center">
                <b-button v-on:click="setTypeInRenter" variant="primary"
                          id="createRenterBtn"
                          v-b-tooltip="'Sie haben den Mieter noch nicht im System hinterlegt? Kein Problem, hier klicken um Ihn anzulegen'">
                  Neuen Mieter anlegen
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card header="Mieter bereits ausgewählt" v-if="renterIds.length > 0">
            <b-row>
              <b-col v-for="renter in selectedRenters" v-bind:key="renter.id">
                <b>Ausgewählt:</b>
                {{ renter.prename }} {{ renter.lastname }}<br>
              </b-col>
            </b-row>
            <b-button @click.prevent="setTypeInRenter()"><b-icon icon="plus"></b-icon></b-button>
          </b-card>
          <b-card header="Mieter auswählen" v-if="isSelectRenter">
            <b-button @click.prevent="setSelectRenter(false)"
                      v-b-tooltip="'Klicken Sie hier um wieder zur Auswahl zurück zukehren'">
              <b-icon icon="arrow-left-square-fill"/>
            </b-button>
            <br/><br/>
            <b-input
                type="text"
                placeholder="Hier den Namen des Mieters eingeben"
                v-on:keyup="loadRenters"></b-input>
            <div class="text-center" v-if="rentersLoading">
              <b-icon animation="cylon" icon="three-dots"></b-icon>
            </div>
            <br>
            <b-table v-if="!rentersLoading && renters != null" :items="renters.content" :fields="this.fields">
              <template #cell(actions)="data" class="sm-col">
                <b-button variant="success" v-on:click="selectRenter(data)">
                  <b-icon icon="check" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card>
          <b-card header="Mieterdaten erfassen"
                  v-if="!isSelectRenter && typeInRenter">
            <b-form @submit.prevent="onSubmitRenter(renter)" novalidate>
              <b-row>
                <b-col cols="2">
                  <b-form-group>
                    <InfoLabel label="Anrede" tooltip="Wählen Sie die korrekte Anrede aus"/>
                    <b-form-select
                        id="salutation"
                        v-model="renter.salutation"
                        required
                        placeholder="---"
                        :state="salutationState"
                        :options="salutations"
                    >
                    </b-form-select>
                    <b-form-invalid-feedback>
                      Bitte wählen Sie eine Anrede aus
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Vorname" tooltip="Tragen Sie den Vornamen Ihres Mieters ein"/>
                    <b-form-input
                        id="prename"
                        v-model="renter.prename"
                        required
                        placeholder="Bspw.: Max"
                        :state="prenameState"
                    >
                    </b-form-input>

                    <b-form-invalid-feedback>
                      Bitte tragen Sie mindestens 3 Zeichen ein.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Nachname" tooltip="Tragen Sie den Nachnamen Ihres Mieters ein"/>
                    <b-form-input
                        id="lastname"
                        v-model="renter.lastname"
                        :state="lastnameState"
                        required
                        placeholder="Bspw.: Mustermann"
                    >
                    </b-form-input>

                    <b-form-invalid-feedback>
                      Bitte tragen Sie mindestens 3 Zeichen ein.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Straße und Hausnummer" tooltip="Tragen Sie die Straße Ihres Mieters ein"/>
                    <b-form-input
                        id="street"
                        v-model="renter.street"
                        :state="streetState"
                        placeholder="Bspw.: Musterstraße"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Bitte tragen Sie mindestens 3 Zeichen ein.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <InfoLabel label="Postleitzahl" tooltip="Tragen Sie die Postleitzahl Ihres Mieters ein"/>
                    <b-form-input
                        id="street"
                        v-model="renter.zipCode"
                        :state="zipCodeState"
                        required
                        placeholder="Bspw.: 00000"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Die eingetragene PLZ ist falsch
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Stadt" tooltip="Tragen Sie die Stadt Ihres Mieters ein"/>
                    <b-form-input
                        id="city"
                        v-model="renter.city"
                        :state="cityState"
                        required
                        placeholder="Bspw.: Musterstadt"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Bitte tragen Sie mindestens 3 Zeichen ein.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Telefonnummer" tooltip="Hier können Sie die Telefonnummer eintragen (optional)"/>

                    <b-input-group append="☎">
                      <b-form-input
                          id="phone"
                          :state="phoneState"
                          v-model="renter.contactInformation.telephone"
                          placeholder="Bspw.: 0"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback
                        :state="phoneState">
                      Die eingegebene Telefonnummer
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Mobilfunknummer"
                               tooltip="Hier können Sie die Mobilfunknummer eintragen (optional)"/>
                    <b-input-group append="📱">
                      <b-form-input
                          id="mobile"
                          :state="mobileState"
                          v-model="renter.contactInformation.mobilephone"
                          placeholder="Bspw.: 0"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback
                        :state="mobileState">
                      Die eingegebene Mobilfunknummer
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="E-Mail Adresse"
                               tooltip="Hier können Sie die E-Mail Adresse eintragen (optional)"/>
                    <b-input-group append="@">
                      <b-form-input
                          id="mobile"
                          type="email"
                          :state="emailState"
                          v-model="renter.contactInformation.email"
                          placeholder="Bspw.: info@example.de"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback
                        :state="emailState">
                      Die eingegebene E-Mail Adresse ist nicht korrekt
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button v-on:click="setSelectRenter(true)"
                            v-if="hasRenter"
                            v-b-tooltip="'Der Mieter ist bereits angelegt und Ihnen bekannt, hier klicken um nach diesem zu suchen und Ihn dann auszuwählen'"
                            variant="success">Bestandsmieter auswählen
                  </b-button>
                </b-col>
                <b-col>
                  <b-button type="submit" class="float-right" variant="primary">Mieter speichern</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col>
          <b-card header="Mietverhältnis erstellen">
            <b-form @submit.prevent="onSubmit" novalidate>
              <b-row>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Datum des Mietbeginns" tooltip="Tragen Sie hier das Datum des Mietbeginns ein"/>
                    <b-form-datepicker
                        id="input-start"
                        v-model="renting.start"
                        type="date"
                        :value-as-date="true"
                        :state="startState"
                        placeholder="Bspw.: 11.12.2020"
                    ></b-form-datepicker>
                    <b-form-invalid-feedback>Bitte ein Datum auswählen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Datum der letzten Nebenkostenabrechnung"
                               tooltip="Wann wurde die letzte Nebenkostenabrechnung erstellt?"/>
                    <b-form-datepicker
                        id="input-5"
                        v-model="renting.lastBillingOfIncidentals"
                        :state="incidentalState"
                        type="date"
                        :value-as-date="true"
                        placeholder="Bspw.: 11.12.2020"
                    ></b-form-datepicker>
                    <b-form-invalid-feedback>Bitte ein Datum auswählen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-row>
                <b-col>
                  <b-form-group v-if="this.loadingContract">
                    <InfoLabel label="Mietvertrag wird hochgeladen"
                               tooltip="Der Mietvertrag wird gerade hochgeladen, bitte haben Sie einen Augenblick Geduld"/>
                    <div class="text-center">
                      <b-icon animation="cylon" icon="three-dots"></b-icon>
                    </div>
                  </b-form-group>
                  <b-form-group v-if="renting.contractFileId == null && !loadingContract">
                    <InfoLabel label="Mietvertrag hochladen"
                               tooltip="Bitte wählen Sie eine Datei aus, welche den Mietvertrag enthält"/>
                    <b-form-file
                        placeholder="Datei hochladen (PDF, JPEG oder PNG)"
                        :state="contractState"
                        v-on:change="uploadContract"
                    ></b-form-file>
                  </b-form-group>
                  <b-form-group v-if="renting.contractFileId != null && !loadingContract">
                    <InfoLabel label="Mietvertrag bereits hochgeladen"
                               tooltip="Der Mietvertrag wurde bereits hochgeladen"/>
                    <br/>
                    <b-button
                        variant="secondary"
                        v-on:click="this.resetContractFile"
                    >Neue Datei hochladen
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group v-if="loadingCertificate">
                    <InfoLabel label="Übergabeprotokoll wird hochgeladen"
                               tooltip="Das Übergabeprotokoll wird gerade hochgeladen, bitte haben Sie einen Augenblick Geduld"/>
                    <div class="text-center">
                      <b-icon animation="cylon" icon="three-dots"></b-icon>
                    </div>
                  </b-form-group>
                  <b-form-group v-if="renting.handoverCertificateFileId == null && !loadingCertificate">
                    <InfoLabel label="Übergabeprotokoll" tooltip="Laden Sie hier das Übergabeprotokoll hoch"/>
                    <b-form-file
                        v-on:change="this.uploadCertificate"
                        :state="certificateState"
                        placeholder="Datei hochladen (PDF, JPEG oder PNG)"
                    ></b-form-file>
                  </b-form-group>
                  <b-form-group v-if="renting.handoverCertificateFileId != null && !loadingCertificate">
                    <InfoLabel label="Übergabeprotokoll bereits hochgeladen"
                               tooltip="Das Übergabeprotokoll wurde bereits hochgeladen"/>
                    <b-button
                        variant="secondary"
                        v-on:click="this.resetCertificate"
                    >Neue Datei hochladen
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group id="input-group-9">
                    <InfoLabel label="Monatsmiete kalt (optional)"
                               tooltip="Hier tragen Sie die monatliche Kaltmiete ein"/>
                    <b-input-group append="€">
                      <b-form-input
                          v-model="renting.lease"
                          :state="leaseState"
                          :formatter="formatter.formatNumber"
                          lazy-formatter
                          type="text"
                          placeholder="Bspw.: 300"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="leaseState">{{
                        validator.noCorrectNumberMessage()
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Nebenkostenabschlag (optional)"
                               tooltip="Hier tragen Sie den monatlichen Nebenkostenabschlag ein"/>
                    <b-input-group append="€">
                      <b-form-input
                          v-model="renting.incidentalRate"
                          type="text"
                          :state="incidentalRateState"
                          :formatter="formatter.formatNumber"
                          lazy-formatter
                          placeholder="Bspw.: 100"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="incidentalRateState">{{
                        validator.noCorrectNumberMessage()
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Kaution (optional)" tooltip="Hier tragen Sie die vereinnahmte Kaution ein"/>
                    <b-input-group append="€">
                      <b-form-input
                          v-model="renting.surety"
                          :state="suretyState"
                          lazy-formatter
                          :formatter="formatter.formatNumber"
                          type="text"
                          placeholder="Bspw.: 1000"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="suretyState">{{
                        validator.noCorrectNumberMessage()
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Personen" tooltip="Hier tragen Sie die im Mietverhältnis wohnenden Personen"/>
                    <b-input-group append="👤">
                      <b-form-input
                          v-model="renting.persons"
                          :state="personsState"
                          type="text"
                          placeholder="Bspw.: 2"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="personsState">{{
                        validator.noCorrectNumberMessage()
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Schlüssel (optional)"
                               tooltip="Hier tragen Sie die Anzahl der ausgehändigten Türschlüssel ein"/>
                    <b-form-input
                        v-model="renting.unitKeyCount"
                        type="number"
                        :state="unitKeyCountState"
                        placeholder="Bspw.: 1"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ validator.noCorrectNumberMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Briefkastenschlüssel (optional)"
                               tooltip="Hier tragen Sie die Anzahl der ausgehändigten Briefkastenschlüssel ein"/>
                    <b-form-input
                        id="input-11"
                        v-model="renting.letterBoxKeyCount"
                        :state="letterBoxKeyCountState"
                        type="number"
                        placeholder="Bspw.: 1"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ validator.noCorrectNumberMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-button type="submit" v-if="!this.loadingCertificate && !this.loadingContract" class="float-right"
                        variant="primary">Mietverhältnis speichern
              </b-button>
              <b-button disabled v-if="this.loadingCertificate || this.loadingContract" class="float-right"
                        variant="primary">Mietverhältnis speichern
              </b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import CreateUpdateRenter from "@/models/api/create-update-renter";
import CreateUpdateRenting from "@/models/api/create-renting";
import ValidatorService from "@/services/validator.service";
import messageService from '@/services/message.service';
import FileService from "@/services/file.service";
import HTTP from "@/http";
import {Salutations} from "@/models/static/salutations";
import {germanFormatter} from '@/services/formatter';
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateRenting',
  components: {InfoLabel},
  data() {
    return {
      renters: [],
      selectedRenters: [],
      renter: new CreateUpdateRenter(null, '', '', '', '', '', '', '', ''),
      salutations: Salutations.get(),
      renterIds: [],
      loadingContract: false,
      loadingCertificate: false,
      typeInRenter: true,
      isSelectRenter: false,
      rentersLoading: false,
      validator: ValidatorService,
      hasRenter: false,
      formatter: germanFormatter,
      fields: [
        {
          key: "prename",
          label: "Vorname",
          sortable: true
        },
        {
          key: "lastname",
          label: "Nachname",
          sortable: true
        },
        {
          key: 'street',
          label: 'Straße',
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      renting: new CreateUpdateRenting(this.$route.params.unitId, "", "", "", "", null, null, null, null, null, null,[]),
      salutationState: null,
      prenameState: null,
      lastnameState: null,
      cityState: null,
      zipCodeState: null,
      streetState: null,
      phoneState: null,
      mobileState: null,
      emailState: null,
      startState: null,
      incidentalState: null,
      contractState: null,
      incidentalRateState: null,
      leaseState: null,
      suretyState: null,
      personsState: null,
      unitKeyCountState: null,
      letterBoxKeyCountState: null,
      certificateState: null
    }
  },
  created() {
    if (this.$route.params.unitId !== undefined) {
      HTTP.get("/units/" + this.$route.params.unitId).then(response => {
        this.renting.buildingId = response.data.buildingId;
        HTTP.get("/buildings/" + this.renting.buildingId).then(response => {
          let building = response.data;
            if (this.renter.zipCode == null || this.renter.zipCode == "") {
              this.renter.zipCode = building.zipCode;
            }

            if (this.renter.city == null || this.renter.city == "") {
              this.renter.city = building.city;
            }

            if (this.renter.street == null || this.renter.street == "") {
              this.renter.street = building.street;
            }
        });
      });
    }
    HTTP.get("/renters").then(resp => {
      if (resp.data.totalElements > 0) {
        this.hasRenter = true;
      }
    });
  },
  methods: {
    validateForm: function () {
      this.renting.incidentalRate = this.formatter.parseNumber(this.renting.incidentalRate);
      this.renting.surety = this.formatter.parseNumber(this.renting.surety);
      this.renting.lease = this.formatter.parseNumber(this.renting.lease);

      this.incidentalState = !ValidatorService.isEmptyAndNotRequired(this.renting.lastBillingOfIncidentals);
      this.startState = !ValidatorService.isEmptyAndRequired(this.renting.start);
      this.contractState = true;
      this.certificateState = true;
      this.leaseState = !ValidatorService.correctNumberNotRequired(this.renting.lease);
      this.suretyState = !ValidatorService.correctNumberNotRequired(this.renting.surety);
      this.incidentalRateState = !ValidatorService.correctNumberNotRequired(this.renting.incidentalRate);
      this.letterBoxKeyCountState = !ValidatorService.correctNumberNotRequired(this.renting.letterBoxKeyCount);
      this.unitKeyCountState = !ValidatorService.correctNumberNotRequired(this.renting.unitKeyCount);
      this.personsState = !ValidatorService.correctNumberNotRequired(this.renting.persons);

      if (this.renterIds.length === 0 && !this.validateFormRenter()) {
        messageService.hint("Kein Mieter gewählt", "Bitte tragen Sie alle roten Felder beim Mieter aus, oder wählen Sie einen Mieter aus.");
        if (document.getElementById('selectRenterBtn') !== null) {
          document.getElementById('selectRenterBtn').focus();
        } else if (document.getElementById('createRenterBtn') !== null) {
          document.getElementById('createRenterBtn').focus();
        }
        return false;
      }

      return this.incidentalState
          && this.contractState
          && this.certificateState
          && this.incidentalRateState
          && this.suretyState
          && this.leaseState
          && this.letterBoxKeyCountState
          && this.unitKeyCountState
          && this.startState
          && (this.validateFormRenter() || this.renterIds.length > 0);
    },

    validateFormRenter: function () {
      this.salutationState = !ValidatorService.nullSelect(this.renter.salutation);
      this.prenameState = !ValidatorService.isEmptyAndRequired(this.renter.prename);
      this.lastnameState = !ValidatorService.isEmptyAndRequired(this.renter.lastname);
      this.streetState = !ValidatorService.isEmptyAndRequired(this.renter.street);
      this.cityState = !ValidatorService.isEmptyAndRequired(this.renter.city);
      this.zipCodeState = !ValidatorService.zipCode(this.renter.zipCode);
      this.phoneState = !ValidatorService.isEmptyAndNotRequired(this.renter.contactInformation.telephone);
      this.mobileState = !ValidatorService.isEmptyAndNotRequired(this.renter.contactInformation.mobilephone);
      this.emailState = !(ValidatorService.isEmptyAndNotRequired(this.renter.contactInformation.email)
          && !ValidatorService.validateEmail(this.renter.contactInformation.email));


      return this.salutationState
          && this.prenameState
          && this.streetState
          && this.cityState
          && this.zipCodeState
          && this.phoneState
          && this.mobileState
          && this.emailState
          && this.lastnameState;
    },
    resetRenter: function () {
      this.renterIds = [];
      this.renters = [];
      this.isSelectRenter = false;
      this.typeInRenter = false;
      this.renting.renterId = null;
    },
    selectRenter: function (data) {
      this.selectedRenters.push(data.item);
      this.renterIds.push(data.item.id);
      this.renting.renterIds.push(data.item.id);
      this.isSelectRenter = false;
    },
    setTypeInRenter: function () {
      this.typeInRenter = true;
      this.renter = new CreateUpdateRenter(null, '', '',  this.renter?.street, this.renter?.zipCode, this.renter?.city, '', '', '');
    },
    setSelectRenter: function (value) {
      this.isSelectRenter = value;
      this.typeInRenter = false;
    },
    loadRenters: function (it) {
      this.rentersLoading = true;
      let search = it.target.value;
      HTTP.get("/renters?search=" + search).then(result => {
        this.renters = result.data;
        this.rentersLoading = false;
      });
    },
    submitRenting: function () {
      if (this.$route.params.id !== undefined) {
        HTTP.put("/rentings/" + this.$route.params.id, this.unit).then(() => {
          this.$router.push({name: "ShowUnit", params: {id: this.renting.unitId}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Mietverhältnis erfolgreich bearbeitet');
          }).catch(() => {
            messageService.error('Speichern fehlgeschlagen', 'Mietverhältnis konnte nicht gespeichert werden');
          });
        });
      } else {
        HTTP.post("/rentings", this.renting).then(() => {
          this.$router.push({name: "ShowUnit", params: {id: this.renting.unitId}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Mietverhältnis erfolgreich erstellt');
          });
        }).catch(() => {
          messageService.error('Speichern fehlgeschlagen', 'Mietverhältnis konnte nicht gespeichert werden');
        });
      }
    },
    onSubmit: function () {
      if (!this.validateForm()) {
        return;
      }

      if (this.typeInRenter) {
        this.onSubmitRenter().then(() => {
          this.submitRenting();
        }).catch((e) => {
          console.log(e);
          messageService.error('Speichern fehlgeschlagen', 'Mietverhältnis konnte nicht gespeichert werden, bitte tragen Sie einen Mieter ein!');
        });
      } else {
        this.submitRenting();
      }
    },
    onSubmitRenter: function () {
      if (!this.validateFormRenter()) {
        console.log("failed validation");
        return new Promise((resolve, reject) => reject());
      }

      if (this.renter.id == null) {
        return HTTP.post("/renters", this.renter).then(success => {
          this.renterIds.push(success.data.id);
          this.renter = success.data;
          this.renting.renterIds.push(success.data.id);
          this.selectedRenters.push(success.data);
          this.typeInRenter = false;
          messageService.success('Speichern erfolgreich', 'Mieter erfolgreich erstellt');
        });
      } else {
        return HTTP.put("/renters/" + this.renter.id, this.renter).then(success => {
          this.renterIds.push(success.data.id);
          this.renter = success.data;
          this.renting.renterIds.push(success.data.id);

          messageService.success('Speichern erfolgreich', 'Mieter erfolgreich bearbeitet');
        });
      }
    },
    uploadContract: function (item) {
      console.log(item.target.files);
      this.loadingContract = true;
      FileService.upload(item.target.files[0]).then(resp => {
        this.renting.contractFileId = resp.data.id;
        this.loadingContract = false;
        messageService.success('Speichern erfolgreich', 'Datei erfolgreich hochgeladen');
      }).catch(() => {
        this.renting.contractFileId = null;
        this.loadingContract = false;
        messageService.error('Speichern fehlgeschlagen', 'Datei konnte nicht hochgeladen werden');
      });
    },
    resetContractFile: function () {
      this.renting.contractFileId = null;
    },
    uploadCertificate: function (item) {
      this.loadingCertificate = true;
      FileService.upload(item.target.files[0]).then(resp => {
        this.renting.handoverCertificateFileId = resp.data.id;
        this.loadingCertificate = false;
        messageService.success('Speichern erfolgreich', 'Datei erfolgreich hochgeladen');
      }).catch(() => {
        this.renting.handoverCertificateFileId = null;
        this.loadingCertificate = false;
        messageService.error('Speichern fehlgeschlagen', 'Datei konnte nicht hochgeladen werden');
      });
    },
    resetCertificate: function () {
      this.renting.handoverCertificateFileId = null;
    }
  }
}
</script>
<style>

</style>