<template>
  <b-container>
    <div class="content">
      <Loading v-if="documentFile === null"/>
      <b-card header="Dokument anzeigen" v-if="documentFile !== null">
        <b>Name des Dokuments</b> <br/>{{ documentFile.name }}<br/><br/>
        <b>Beschreibung des Dokuments</b> <br/>{{ documentFile.description }}
        <hr/>
        <FilePreview :file-id="fileId"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";
import FilePreview from "@/components/FilePreview";
import Loading from "@/components/Loading";

export default {
  name: 'ShowDocumentFile',
  components: {Loading, FilePreview},
  created() {
    this.loadFile();
  },
  data() {
    return {
      documentFile: null,
      loading: true,
      fileId: null
    }
  },
  methods: {
    loadFile: function () {
      HTTP.get("/document-files/" + this.$route.params.id).then(response => {
        this.documentFile = response.data;
        this.fileId = this.documentFile.fileId;
      });
    }
  }
}
</script>
<style scoped>
</style>