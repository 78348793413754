import Vue from "vue";
import VueRouter from "vue-router";
import Start from "@/views/Start";
import Login from "@/views/auth/Login";
import CreateBuilding from "@/views/building/CreateBuilding";
import Logout from "@/views/auth/Logout";
import Register from "@/views/auth/Register";
import ShowBuilding from "@/views/building/ShowBuilding";
import CreateUnit from "@/views/unit/CreateUnit";
import CreateRenting from "@/views/renting/CreateRenting";
import ShowUnit from "@/views/unit/ShowUnit";
import ListRenter from "@/views/renter/ListRenter";
import CreateRenter from "@/views/renter/CreateRenter";
import ShowRenter from "@/views/renter/ShowRenter";
import ShowRenting from "@/views/renting/ShowRenting";
import UserSetting from "@/views/user/UserSetting";
import CreateSubUser from "@/views/user/CreateSubUser";
import CreateCounter from "@/views/counter/CreateCounter";
import ShowCounter from "@/views/counter/ShowCounter";
import CreateMonition from "@/views/monition/CreateMonition";
import ListMonitions from "@/views/monition/ListMonitions";
import ShowMonition from "@/views/monition/ShowMonition";
import ProcessMonition from "@/views/monition/ProcessMonition";
import CreateSupplier from "@/views/supplier/CreateSupplier";
import ListSuppliers from "@/views/supplier/ListSuppliers";
import ShowSupplier from "@/views/supplier/ShowSupplier";
import CreateBuildingCost from "@/views/building/CreateBuildingCost";
import ListIncidentals from "@/views/incidentals/ListIncidentals";
import CreateIncidental from "@/views/incidentals/CreateIncidental";
import CreateBuildingUsageCost from "@/views/building/CreateBuildingUsageCost";
import Search from "@/views/Search";
import CreateRepair from "@/views/repair/CreateRepair";
import ShowRepair from "@/views/repair/ShowRepair";
import Support from "@/views/Support";
import CreateDocumentFile from "@/views/document-file/CreateDocumentFile";
import ShowDocumentFile from "@/views/document-file/ShowDocumentFile";
import ResetPassword from "@/views/auth/ResetPassword";
import ObtainPassword from "@/views/auth/ObtainPassword";
import ListBuildings from "@/views/building/ListBuildings";
import CreateGarage from "@/views/garage/CreateGarage";
import Booking from "@/views/user/Booking.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {
            name: 'Start'
        }
    },
    {
        path: '/search/:q?',
        name: 'Search',
        component: Search,
        meta: {
            routeType: 'Search',
            title: "Suche"
        }
    },
    {
        path: '/buildings/list/:page?',
        name: 'ListBuildings',
        component: ListBuildings,
        meta: {
            routeType: 'Building',
            title: "Immolert Gebäudeübersicht"
        }
    },
    {
        path: '/dash/:page?',
        name: 'Start',
        component: Start,
        meta: {
            routeType: 'Dashboard',
            title: "Immolert Dashboard"
        }
    },
    {
        path: '/buildings/create/:id?',
        name: 'CreateBuilding',
        component: CreateBuilding,
        meta: {
            routeType: 'Building',
            title: "Immolert Gebäude erstellen"
        }
    },
    {
        path: '/buildings/:id/:page?',
        name: 'ShowBuilding',
        component: ShowBuilding,
        meta: {
            routeType: 'Building',
            title: "Immolert Gebäude ansehen"
        }
    },
    {
        path: '/buildings/:id/tab/:activeTab?',
        name: 'ShowBuildingTab',
        component: ShowBuilding,
        meta: {
            routeType: 'Building',
            title: "Immolert Gebäude ansehen"
        }
    },
    {
        path: '/units/create/:buildingId/:id?',
        name: 'CreateUnit',
        component: CreateUnit,
        meta: {
            routeType: 'Building',
            title: "Immolert Einheit erstellen"
        }
    },
    {
        path: '/renting/create/:unitId?',
        name: 'CreateRenting',
        component: CreateRenting,
        meta: {
            routeType: 'Building',
            title: "Immolert Mietverhältnis erstellen"
        }
    },
    {
        path: '/units/view/:id/:page?',
        name: 'ShowUnit',
        component: ShowUnit,
        meta: {
            routeType: 'Building',
            title: "Immolert Einheit ansehen"
        }
    },
    {
        path: '/renters/:page?',
        name: 'ListRenter',
        component: ListRenter,
        meta: {
            routeType: 'Renter',
            title: "Immolert Mieter auflisten"
        }
    },
    {
        path: '/renters/create/:id?',
        name: 'CreateRenter',
        component: CreateRenter,
        meta: {
            routeType: 'Renter',
            title: "Immolert Mieter erstellen"
        }
    },
    {
        path: '/renters/view/:id/:page?',
        name: 'ShowRenter',
        component: ShowRenter,
        meta: {
            routeType: 'Renter',
            title: "Immolert Mieter anzeigen"
        }
    },
    {
        path: '/rentings/:id',
        name: 'ShowRenting',
        component: ShowRenting,
        meta: {
            routeType: 'Building',
            title: "Immolert Mietverhältnis anzeigen"
        }
    },
    {
        path: '/user/settings/:page?',
        name: 'UserSetting',
        component: UserSetting,
        meta: {
            routeType: 'Setting',
            title: "Immolert Benutzereinstellungen"
        }
    },
    {
        path: '/sub-user/create',
        name: 'CreateSubUser',
        component: CreateSubUser,
        meta: {
            routeType: 'Setting',
            title: "Immolert Unteraccount erstellen"
        }
    },
    {
        path: '/sub-user/edit/:id',
        name: 'EditSubUser',
        component: CreateSubUser,
        meta: {
            routeType: 'Setting',
            title: "Immolert Unteraccount bearbeiten"
        }
    },
    {
        path: '/counter/create/:unitId/:id?',
        name: 'CreateCounter',
        component: CreateCounter,
        meta: {
            routeType: 'Building',
            title: 'Zähler anlegen'
        }
    },
    {
        path: '/counter/:id',
        name: 'ShowCounter',
        component: ShowCounter,
        meta: {
            routeType: 'Building',
            title: 'Zähler anzeigen'
        }
    },
    {
        path: '/monitions/list/single/:unitId/:page?',
        name: 'ListMonitions',
        component: ListMonitions,
        meta: {
            routeType: 'Monition',
            title: 'Mahnwesen anzeigen'
        }
    },
    {
        path: '/monitions/list/all/:page?',
        name: 'ListAllMonitions',
        component: ListMonitions,
        meta: {
            routeType: 'Monition',
            title: 'Mahnwesen anzeigen'
        }
    },
    {
        path: '/monitions/create/:unitId?',
        name: 'CreateMonition',
        component: CreateMonition,
        meta: {
            routeType: 'Monition',
            title: 'Mahnverfahren starten'
        }
    },
    {
        path: '/monitions/show/:id',
        name: 'ShowMonition',
        component: ShowMonition,
        meta: {
            routeType: 'Monition',
            title: 'Mahnverfahren ansehen'
        }
    },
    {
        path: '/monitions/process/:id',
        name: 'ProcessMonition',
        component: ProcessMonition,
        meta: {
            routeType: 'Monition',
            title: 'Mahnverfahren Schreiben erstellen'
        }
    },
    {
        path: '/suppliers/create/:id?',
        name: 'CreateSupplier',
        component: CreateSupplier,
        meta: {
            routeType: 'Supplier',
            title: 'Lieferant anlegen'
        }
    },
    {
        path: '/suppliers/list/:page?',
        name: 'ListSuppliers',
        component: ListSuppliers,
        meta: {
            routeType: 'Supplier',
            title: 'Lieferanten auflisten'
        }
    },
    {
        path: '/suppliers/show/:id',
        name: 'ShowSupplier',
        component: ShowSupplier,
        meta: {
            routeType: 'Supplier',
            title: 'Lieferanten anzeigen'
        }
    },
    {
        path: '/building-costs/create/:buildingId/:id?/:copy?',
        name: 'CreateBuildingCost',
        component: CreateBuildingCost,
        meta: {
            routeType: 'Building',
            title: 'Gebäudekosten anlegen / bearbeiten'
        }
    },
    {
        path: '/building-usage-costs/create/:buildingId/:id?/:copy?',
        name: 'CreateBuildingUsageCost',
        component: CreateBuildingUsageCost,
        meta: {
            routeType: 'Building',
            title: 'Verbrauchskosten anlegen / bearbeiten'
        }
    },
    {
        path: '/incidentals/list/unit/:unitId/:page?',
        name: 'ListIncidentalsUnit',
        component: ListIncidentals,
        meta: {
            routeType: 'Incidental',
            title: 'Nebenkostenabrechnungen'
        }
    },
    {
        path: '/incidentals/list/:page?',
        name: 'ListIncidentals',
        component: ListIncidentals,
        meta: {
            routeType: 'Incidental',
            title: 'Nebenkostenabrechnungen'
        }
    },
    {
        path: '/incidentals/create/:unitId?/:rentingId?',
        name: 'CreateIncidental',
        component: CreateIncidental,
        meta: {
            routeType: 'Incidental',
            title: 'Nebenkostenabrechnungen erstellen'
        }
    },
    {
        path: '/incidentals/edit/:id?',
        name: 'EditIncidental',
        component: CreateIncidental,
        meta: {
            routeType: 'Incidental',
            title: 'Nebenkostenabrechnungen bearbeiten'
        }
    },
    {
        path: '/repairs/create/:buildingId?',
        name: 'CreateRepair',
        component: CreateRepair,
        meta: {
            routeType: 'Building',
            title: 'Reparatur anlegen'
        }
    },
    {
        path: '/repairs/edit/:id?',
        name: 'EditRepair',
        component: CreateRepair,
        meta: {
            routeType: 'Building',
            title: 'Reparatur anlegen'
        }
    },
    {
        path: '/repairs/show/:id?',
        name: 'ShowRepair',
        component: ShowRepair,
        meta: {
            routeType: 'Building',
            title: 'Reparatur anzeigen'
        }
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            routeType: 'Support',
            title: "Immolert Feedback & Support"
        }
    },
    {
        path: '/document/get/:id',
        name: 'ShowDocumentFile',
        component: ShowDocumentFile,
        meta: {
            routeType: "Document",
            title: "Dokument ansehen"
        }
    },
    {
        path: '/document/create/:entityId/:entityType/:returnTo',
        name: 'CreateDocumentFile',
        component: CreateDocumentFile,
        meta: {
            routeType: 'Document',
            title: "Dokument erstellen"
        }
    },
    {
        path: '/garage/create/:buildingId/:id?/:copy?',
        name: 'CopyGarage',
        component: CreateGarage,
        meta: {
            routeType: 'Building',
            title: "Garage erstellen"
        }
    },
    {
        path: '/garage/create/:buildingId',
        name: 'CreateGarage',
        component: CreateGarage,
        meta: {
            routeType: 'Building',
            title: "Garage erstellen"
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            title: "Immolert logout"
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            title: "Immolert Benutzerregistrierung"
        }
    },
    {
        path: '/book/:hint?',
        name: 'Book',
        component: Booking,
        meta: {
            title: "Immolert kostenpflichtiges Abonnement abschließen"
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: "Immolert Login"
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: "Immolert Login"
        }
    },
    {
        path: '/pwd/:token',
        name: 'ObtainPassword',
        component: ObtainPassword,
        meta: {
            title: "Immolert Login"
        }
    }
];

const router = new VueRouter({routes});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/reset-password', '/assets/login-background.jpg'];
    const authRequired = !publicPages.includes(to.path) && to.name !== "ObtainPassword";

    const loggedIn = localStorage.getItem('token');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn && to.path !== "/login") {
        next('/login');
    } else {
        next();
    }
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || "Immolert";
    });
});

export default router