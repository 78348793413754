<template>
  <div class="container">
    <div class="content">
      <b-row>
        <b-col sm="12" lg="4">
          <div class="card">
            <div class="card-header">
              Stammdaten der Einheit
              <b-badge variant="primary" class="float-right">{{ unit.type | germanUnitType }}</b-badge>
            </div>
            <div class="card-body">
              <b>
                <b-icon icon="file-text"></b-icon>
                Name der Einheit</b><br>
              {{ unit.name }}<br><br>
              <b>
                <b-icon icon="file-text"></b-icon>
                Beschreibung der Einheit</b><br>
              {{ unit.notation }}<br><br>
              <TagList entity-type="UNIT" :entity-id="unit.id" v-if="unit.id !== undefined"/>
            </div>
          </div>
        </b-col>
        <b-col sm="12" lg="8">
          <b-card header="Zähler" v-if="counters == null">
            <b-skeleton-table/>
          </b-card>
          <b-card header="Zähler" v-if="counters != null && counters.totalElements === 0">
            <NoResultsCreateSuggest text="Noch keine Zähler hinterlegt"
                                    :route="{name:'CreateCounter', params: {unitId:this.unit.id}}"
                                    tooltip="Um Strom, Wasser und Heizungsverbräuche richtig zu erfassen ist es notwendig die Zählerstände zu erfassen"/>
          </b-card>
          <b-card header="Zähler" v-if="counters != null && counters.totalElements > 0">
            <b-table :items="counters.content" :fields="counterFields" responsive
                     class="card-table" striped>
              <template #cell(counterType)="data">
                {{ data.item.counterType|germanCounterType }}
              </template>
              <template #cell(actions)="data">
                <b-button variant="primary" size="sm" :to="{ name: 'ShowCounter', params: { id : data.item.id }}"
                          v-b-tooltip.leftbottom="'Klicken Sie hier um den Zähler anzuzeigen.'">
                  <b-icon icon="eye"></b-icon>
                </b-button>&nbsp;<b-button variant="warning" size="sm"
                                           :to="{ name: 'CreateCounter', params: { unitId: unit.id, id : data.item.id }}"
                                           v-b-tooltip.leftbottom="'Klicken Sie hier um den Zähler zu bearbeiten.'">
                <b-icon icon="pencil"></b-icon>
              </b-button>&nbsp;
                <b-button variant="danger" size="sm" @click.prevent="deleteCounterModal(data.item.id)"
                          v-b-tooltip.leftbottom="'Klicken Sie hier um den Zähler zu löschen.'">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
            </b-table>
            <b-button :to="{name:'CreateCounter', params: {unitId:this.unit.id}}"
                      v-b-tooltip="'Klicken Sie hier um einen neuen Zähler zu erstellen.'"
                      class="float-right"
                      variant="primary">
              Weiteren Zähler anlegen
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <br>
      <b-row v-if="this.renting != null">
        <b-col>
          <b-card class="text-right">
            <b-row>
              <b-col lg="2" sm="12" class="text-left font-weight-bold">
                Aktionen:
              </b-col>
              <b-col sm="12" lg="4">
                <b-button variant="primary" :block="true" :to="{name: 'ListIncidentalsUnit', params: {unitId: unit.id}}"
                          v-b-tooltip="'Klicken Sie auf diesen Button um eine neue Nebenkostenabrechnung für den Mieter zu erstellen'">
                  Nebenkostenabrechnungen
                </b-button>
              </b-col>
              <b-col sm="12" lg="3">
                <b-button variant="warning" :block="true" :to="{name: 'ListMonitions', params:{unitId:unit.id}}"
                          v-b-tooltip="'Ihr Mieter ist mit der Miete im Rückstand, starten Sie hier das Mahnwesen'">
                  Mahnwesen
                </b-button>
              </b-col>
              <b-col sm="12" lg="3">
                <b-button variant="danger" :block="true"
                          v-on:click="archiveRentingModal()"
                          v-b-tooltip="'Das Mietverhältnis wurde beendet? Dann klicken Sie hier um das Mietverhältnis zu archivieren'">
                  Mietverhältnis archivieren
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <br>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Mietverhältnis">
            <b-card-text>
              <Renting v-if="this.loaded" :renting-value.sync="this.renting" :unit="this.unit"/>
            </b-card-text>
          </b-tab>
          <b-tab title="Reparaturen">
            <ListRepair :unit-id="unit.id"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <br>
      <Comment :entity-id="this.$route.params.id" :entity-type="'unit'"></Comment>
      <br>
      <b-row v-if="archivedRentings != null && archivedRentings.totalElements > 0">
        <b-col>
          <b-card header="Ehemalige Mietverhältnisse">
            <b-table :items="archivedRentings.content"
                     class="card-table"
                     :fields="archivedRentingFields"
                     :no-local-sorting="true"
                     v-on:sort-changed="loadArchivedRentings">
              <template #cell(renter)="data">
                {{ data.item.renterPrename }}
                {{ data.item.renterLastname }}
              </template>
              <template #cell(actions)="data" class="sm-col">
                <router-link :to="{name:'ShowRenting', params: {'id':data.item.id}}"
                             class="btn btn-primary btn-sm"
                             v-b-tooltip="'Klicken Sie hier um das archivierte Mietverhältnis anzuzeigen.'">
                  <b-icon icon="eye" font-scale="1"></b-icon>
                </router-link>&nbsp;&nbsp;
                <b-button size="sm" variant="danger" @click.prevent="deleteRentingModal(data.item.id)"
                          v-b-tooltip="'Klicken Sie hier um das archivierte Mietverhältnis zu löschen.'">
                  <b-icon icon="trash" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
            <PageableTable v-bind:pageable="archivedRentings" v-bind:toRoute="'ShowUnit'"/>
          </b-card>
        </b-col>
      </b-row>
      <b-modal id="delete-renting-modal" title="Mietverhältnis löschen?" ok-variant="danger" ok-title="Löschen"
               cancel-title="Abbrechen" @ok="deleteRenting()">Möchten Sie das Mietverhältnis wirklich löschen?
      </b-modal>
      <b-modal id="delete-counter-modal" title="Zähler löschen?" ok-variant="danger" ok-title="Löschen"
               cancel-title="Abbrechen" @ok="deleteCounter()">Möchten Sie den Zähler wirklich löschen?
      </b-modal>
      <b-modal id="archive-renting-modal" title="Mietverhältnis archivieren?" ok-variant="danger" ok-title="Archivieren"
               cancel-title="Abbrechen" @ok="archiveRenting()">Möchten Sie das Mietverhälnis wirklich archivieren?
        <br><br>
        <i class="small">Nach dem archivieren können Sie das Mietverhälnis weiterhin einsehen. Es wird allerdings als
          beendet angesehen und es können keine Mahnungen mehr erstellt werden</i>
        <hr>
        <b-form-group label="Datum der Beendigung">
          <b-datepicker v-model="archiveDate" :min="mindate" :value-as-date="true"/>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>
<script>
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import messageService from '@/services/message.service';
import PageableTable from "@/components/PageableTable";
import ListRepair from "@/views/repair/ListRepairs";
import Renting from "@/components/Renting";
import Comment from "@/components/Comment";
import Unit from "@/models/unit";
import HTTP from "@/http";
import TagList from "../../components/tag/TagList";

export default {
  name: 'ShowUnit',
  components: {
    TagList,
    ListRepair,
    Comment,
    NoResultsCreateSuggest,
    Renting, PageableTable
  },
  data() {
    return {
      unit: new Unit(),
      renting: null,
      archivedRentings: null,
      renter: null,
      items: null,
      archiveDate: new Date(),
      mindate: new Date(),
      counters: null,
      page: this.$route.params.page,
      archivedRentingFields: [
        {
          key: "renter",
          label: "Mieter"
        },
        {
          key: "lastUpdate",
          label: "Letzte Änderung"
        },
        {
          key: "actions",
          label: "Aktionen"
        }
      ],
      counterFields: [
        {
          key: 'counterId',
          label: 'ID / Bezeichnung'
        },
        {
          key: 'counterType',
          label: 'Typ'
        },
        {
          key: 'currentValue',
          label: 'Letzter Stand',
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ],
      rentingDeleteId: null,
      counterDeleteId: null,
      loaded: false
    }
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.page = to.params.page;
        this.loadArchivedRentings();
      }
    }
  },
  async created() {
    if (this.$route.params.id !== undefined) {
      await Promise.all([
          this.loadRenting(),
          this.loadArchivedRentings(),
          this.loadCounters(),
          HTTP.get("/units/" + this.$route.params.id).then(response => {
            this.unit = response.data;
            this.items = [
              {
                name: "Name",
                value: this.unit.name
              },
              {
                name: "Beschreibung",
                value: this.unit.notation
              }
            ];

          })]);
      this.loaded = true;
    }
  },
  methods: {
    deleteRentingModal: function (id) {
      this.rentingDeleteId = id;
      this.$bvModal.show("delete-renting-modal");
    },
    deleteCounterModal: function (id) {
      this.counterDeleteId = id;
      this.$bvModal.show("delete-counter-modal");
    },
    archiveRentingModal: function () {
      this.$bvModal.show("archive-renting-modal");
    },
    loadCounters: function () {
      return HTTP.get("/counters?unitId=" + this.$route.params.id).then(response => {
        this.counters = response.data;
      });
    },
    loadRenting: function () {
      return HTTP.get("/rentings?sort=_id,desc&archived=false&unitId=" + this.$route.params.id)
          .then(response => {
            if (response.data.totalElements > 0) {
              this.renting = response.data.content[0];
              this.mindate = this.renting.start;
            }
          });
    },
    deleteCounter: function () {
      HTTP.delete("/counters/" + this.counterDeleteId).then(() => {
        this.loadCounters();
        messageService.success('Löschen erfolgreich', 'Zähler erfolgreich gelöscht');
      });
    },
    deleteRenting: function () {
      HTTP.delete("/rentings/" + this.rentingDeleteId).then(() => {
        this.loadArchivedRentings();
        messageService.success('Löschen erfolgreich', 'Mietverhältnis erfolgreich gelöscht');
      }).catch(()=>{
        messageService.hint('Nicht gelöscht','Das Mietverhältnis konnte nicht gelöscht werden, weil noch Nebenkostenabrechnungen existieren');
      });
    },
    archiveRenting: function () {

      HTTP.post("/rentings/archive/" + this.renting.id + "?end=" + this.archiveDate.toISOString(), null).then(() => {
        this.renting = null;
        this.loadArchivedRentings();
      })
    },
    loadArchivedRentings: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }
      let sortStr = "";
      if (sort !== undefined) {
        sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      return HTTP.get("/rentings?sort=_id,desc&archived=true&unitId=" + this.$route.params.id + sortStr)
          .then(response => {
            this.archivedRentings = response.data;
          });
    }
  }
}
</script>
<style>

</style>