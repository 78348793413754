export class SupplierTypes {
    static get() {
        return [
            {
                value: null,
                text: 'Bitte auswahl treffen'
            },
            {
                value: 'ARTISAN',
                text: 'Handwerker'
            },
            {
                value: 'SERVICE_PROVIDER',
                text: 'Dienstleister'
            },
            {
                value: 'PROVISIONER',
                text: 'Versorger'
            },
            {
                value: 'OTHER',
                text: 'Sonstiges'
            }
        ];
    }

    static germanType(type) {
        let types = this.get();
        for (let i = 0; i < types.length; i++) {
            if (types[i].value == type) {
                return types[i].text;
            }
        }
        return type;
    }
}