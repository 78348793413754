<template>
  <b-container>
    <div class="content">
      <b-card header="Suche">
        Sie haben nach <b>{{ query }}</b> gesucht
        <hr>
        <b-table :items="results" :fields="fields" v-if="results!=null && results.length > 0">
          <template #cell(entityTitle)="data">
            {{data.item.entityTitle}}
            {{data.item.entityContent}}
          </template>
          <template #cell(type)="data">
            <b-icon v-if="data.item.clazz == 'com.spsoftware.immolert.model.Comment'" icon="card-text"
                    v-b-tooltip="'Bei diesem Ergebnis handelt es sich um einen Kommentar'"/>
            <b-icon v-if="data.item.clazz == 'com.spsoftware.immolert.model.supplier.Supplier'" icon="persons"
                    v-b-tooltip="'Bei diesem Ergebnis handelt es sich um einen Zulieferer'"/>
            <b-icon v-if="data.item.clazz == 'com.spsoftware.immolert.model.Tag'" icon="tag"
                    v-b-tooltip="'Bei diesem Ergebnis handelt es sich um einen Tag'"/>
            <b-icon v-if="data.item.clazz == 'com.spsoftware.immolert.model.rent.Renter'" icon="person"
                    v-b-tooltip="'Bei diesem Ergebnis handelt es sich um einen Mieter'"/>
            <b-icon v-if="data.item.clazz == 'com.spsoftware.immolert.model.building.Building'" icon="house"
                    v-b-tooltip="'Bei diesem Ergebnis handelt es sich um ein Haus'"/>
          </template>
          <template #cell(actions)="data">
            <b-button @click="show(data.item)" size="sm" variant="primary"
                      v-b-tooltip="'Klicken Sie hier um das Ergebnis anzuzeigen'">
              <b-icon icon="eye"/>
            </b-button>
          </template>
        </b-table>
        <b-alert variant="secondary" :show="results != null && results.length == 0">
          Leider konnten wir zur angegebene
          Suche keine Ergebnisse finden
        </b-alert>
        <b-skeleton-table v-if="results == null"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";

export default {
  name: 'Search',
  data() {
    return {
      query: this.$route.params.q,
      fields: [
        {
          key: 'type',
          label: "Typ",
          class: 'sm-col',
          sortable: false
        },
        {
          key: 'entityTitle',
          label: "Name",
          sortable: false
        }, {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col',
          sortable: false
        }
      ],
      results: null
    }
  },
  watch: {
    $route: function (to, from) {
      if (to !== from) {
        this.query = this.$route.params.q;
        this.search();
      }
    }
  },
  created() {
    if (this.query !== null && this.query !== undefined) {
      this.search();
    }
  },
  methods: {
    search: function () {
      HTTP.get("/search?query=" + this.query).then(response => {
        this.results = response.data.content;
      })
    },
    show: function (item) {
      if (item.clazz === 'com.spsoftware.immolert.model.supplier.Supplier') {
        this.$router.push({name: 'ShowSupplier', params: {id: item.entityId}});
      }
      if (item.clazz === 'com.spsoftware.immolert.model.rent.Renter') {
        this.$router.push({name: 'ShowRenter', params: {id: item.entityId}});
      }
      if (item.clazz === 'com.spsoftware.immolert.model.building.Building') {
        this.$router.push({name: 'ShowBuilding', params: {id: item.entityId}});
      }
      if (item.clazz === 'com.spsoftware.immolert.model.unit.Unit') {
        this.$router.push({name: 'ShowUnit', params: {id: item.entityId}});
      }
      if (item.clazz === 'com.spsoftware.immolert.model.Comment') {
        HTTP.get("/comments/" + item.entityId).then(response => {
          let comment = response.data;
          if (comment.entity === 'supplier') {
            this.$router.push({name: 'ShowSupplier', params: {id: comment.referenceId}});
          } else if (comment.entity === 'Building') {
            this.$router.push({name: 'ShowBuilding', params: {id: comment.referenceId}});
          } else if (comment.entity === 'BillingOfIncidentals') {
            this.$router.push({name: 'CreateIncidental', params: {id: comment.referenceId}});
          } else if (comment.entity === 'Monition') {
            this.$router.push({name: 'ShowMonition', params: {id: comment.referenceId}});
          } else if (comment.entity === 'renter') {
            this.$router.push({name: 'ShowRenter', params: {id: comment.referenceId}});
          } else if (comment.entity === 'unit') {
            this.$router.push({name: 'ShowUnit', params: {id: comment.referenceId}});
          }
        })
      }
      if (item.clazz === 'com.spsoftware.immolert.model.Tag') {
        HTTP.get("/tags/" + item.entityId).then(response => {
          let tag = response.data;
          if (tag.entityType === 'UNIT') {
            this.$router.push({name: 'ShowUnit', params: {id: tag.entityId}});
          }
        })
      }
    }
  }
}
</script>
<style>

</style>