export class CounterTypes {
    static get() {
        return [
            {
                value: null,
                text: 'Bitte auswahl treffen'
            },
            {
                value: 'HEATING',
                text: 'Heizung'
            },
            {
                value: 'ELECTRIC',
                text: 'Strom'
            },
            {
                value: 'WATER',
                text: 'Wasser'
            },
            {
                value: 'WARM_WATER',
                text: 'Warmwasser'
            },
            {
                value: 'OTHER',
                text: 'Sonstige'
            }
        ];
    }

    static germanType(type) {
        let types = this.get();
        for (let i = 0; i < types.length; i++) {
            if (types[i].value == type) {
                return types[i].text;
            }
        }
        return type;
    }
}