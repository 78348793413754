import { render, staticRenderFns } from "./Infobox.vue?vue&type=template&id=3beb5efa&scoped=true&"
import script from "./Infobox.vue?vue&type=script&lang=js&"
export * from "./Infobox.vue?vue&type=script&lang=js&"
import style0 from "./Infobox.vue?vue&type=style&index=0&id=3beb5efa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3beb5efa",
  null
  
)

export default component.exports