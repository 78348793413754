<template>
  <b-container>
    <div class="content">
      <b-card>
        <template #header>
          Dokument erstellen für <b>{{ purpose }}</b>
        </template>
        <b-card-body>
          <b-form @submit.prevent="createDocument">
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group>
                  <InfoLabel label="Name für das Dokument" tooltip="Hier tragen Sie den Namen des Dokuments ein"/>
                  <b-form-input type="text" v-model="documentFile.name" placeholder="Beispieldokument"
                                required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-group v-if="loadingUpload">
                  <InfoLabel label="Bild wird hochgeladen"
                             tooltip="Das Dokument wird aktuell hochgeladen, bitte haben Sie einen Augenblick Geduld"/>
                  <div class="text-center">
                    <b-icon animation="cylon" icon="three-dots"></b-icon>
                  </div>
                </b-form-group>
                <b-form-group v-if="documentFile.fileId == null && !loadingUpload">
                  <InfoLabel label="Datei des Dokuments" tooltip="Hinterlegen Sie hier die Datei des Dokuments"/>
                  <b-form-file
                      placeholder="Datei hochladen (PDF, JPEG oder PNG)"
                      v-on:change="this.uploadImage"
                  ></b-form-file>
                </b-form-group>
                <b-form-group v-if="documentFile.fileId != null && !loadingUpload">
                  <InfoLabel label="Dokument bereits hochgeladen"
                             tooltip="Das Dokument wurde erfolgreich hochgeladen, zum ersetzen einfach auf Neue Datei hochladen klicken"/>
                  <br/>
                  <b-button
                      variant="secondary"
                      v-on:click="this.resetImage"
                  >Neue Datei hochladen
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <InfoLabel label="Beschreibung des Dokuments (optional)"
                             tooltip="Tragen Sie hier eine Beschreibung für das Dokument ein"/>
                  <b-form-textarea v-model="documentFile.description"
                                   placeholder="Hier können Sie eine kurze Beschreibung eintragen"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button type="submit" class="float-right" :disabled="loadingUpload" variant="primary">Dokument speichern
            </b-button>
          </b-form>
        </b-card-body>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import messageService from "@/services/message.service";
import FileService from "@/services/file.service";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateDocumentFile',
  components: {InfoLabel},
  data() {
    return {
      documentFile: {
        entityType: this.$route.params.entityType,
        entityId: this.$route.params.entityId,
        fileId: null,
        description: null,
        name: null
      },
      fileIdState: null,
      nameState: null,
      descriptionState: null,
      loadingUpload: false,
      purpose: ''
    }
  },
  created() {
    if (this.documentFile.entityType == 'renter') {
      HTTP.get("/renters/" + this.documentFile.entityId).then((user) => {
        this.purpose += 'Mieter ' + user.data.prename + ' ' + user.data.lastname;
      })
    }
    if (this.documentFile.entityType == 'building') {
      HTTP.get("/buildings/" + this.documentFile.entityId).then((building) => {
        this.purpose += 'Gebäude ' + building.data.street + ', ' + building.data.zipCode + ' ' + building.data.city;
      })
    }
  },
  methods: {
    createDocument: function () {
      HTTP.post("/document-files", this.documentFile).then(() => {
        const route = typeof this.$route.params.returnTo === 'string' ? decodeURIComponent(this.$route.params.returnTo) : this.$route.params.returnTo;
        this.$router.push(route).then(() => {
          messageService.success("Dokument hinterlegt", "Dokument wurde erfoglreich gespeichert und hinterlegt.");
        });
      })
    },
    uploadImage: function (item) {
      this.loadingUpload = true;
      FileService.upload(item.target.files[0]).then(resp => {
        this.documentFile.fileId = resp.data.id;
        this.loadingUpload = false;
        messageService.success('Speichern erfolgreich', 'Datei erfolgreich hochgeladen');
      }).catch(error => {
        this.documentFile.fileId = null;
        this.loadingUpload = false;
        if (error.response.status == 400) {
          messageService.hint('Dateityp nicht unterstützt', 'Bitte laden Sie einen unterstützten Dateityp hoch (PDF, JPEG)');
        } else {
          messageService.error('Speichern fehlgeschlagen', 'Datei konnte nicht hochgeladen werden');
        }
      });
    },
    resetImage: function () {
      this.documentFile.fileId = null;
      HTTP.delete('/files/' + this.documentFile.fileId);
    }
  }
}
</script>
<style scoped>

</style>