<template>
  <div id="app">
    <b-navbar toggleable="lg" v-if="isLoggedIn() && !isRegisterOrLogin()" type="dark" class="topnav">
      <b-container>
        <b-navbar-brand href="#">
          <img src="/immolert-icon-invers.png"
               style="max-width:175px; margin: auto; display: block; margin-bottom: 10px"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-bind:class="{active:isActive('Dashboard')}" :to="{name:'Start'}">
              Übersicht
            </b-nav-item>
            <b-nav-item v-bind:class="{active:isActive('Building')}" :to="{name:'ListBuildings'}">
              Gebäude
            </b-nav-item>
            <b-nav-item v-bind:class="{active:isActive('Renter')}" :to="{name:'ListRenter'}">
              Mieter
            </b-nav-item>
            <b-nav-item v-bind:class="{active:isActive('Incidental')}" :to="{name: 'ListIncidentals'}">
              Nebenkosten
            </b-nav-item>
            <b-nav-item v-bind:class="{active:isActive('Monition')}" :to="{name: 'ListAllMonitions'}">
              Mahnwesen
            </b-nav-item>
            <b-nav-item v-bind:class="{active:isActive('Supplier')}" :to="{name: 'ListSuppliers'}">
              Dienstleister
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-form
                v-b-tooltip="'Suchen Sie hier nach allen möglichen Dingen wie z.B.: Gebäuden, Einheiten, Mietern oder Dokumenten. Bspw.: Bonn Beethovenstraße'"
                style="margin-top: -3px; margin-right: 20px" @keypress="searchIfEnter">
              <b-form-input placeholder="Suchen..." class="hard-edge" size="sm"
                            v-model="searchQuery"></b-form-input>
              <b-button variant="primary" @click="search()" size="sm">Ok</b-button>
            </b-nav-form>
            <b-nav-item :to="{name: 'UserSetting'}"
                        class="nav-icon"
                        v-b-tooltip="'Benutzereinstellungen'"
                        v-bind:class="{active:isActive('Setting')}">
              <font-awesome-icon icon="cogs"/>
            </b-nav-item>
            <b-nav-item :to="{name: 'Support'}"
                        class="nav-icon"
                        v-b-tooltip="'Feedback & Support'"
                        v-bind:class="{active:isActive('Support')}">
              <font-awesome-icon icon="question"/>
            </b-nav-item>
            <!-- <b-nav-item :to="{name: 'Payment'}"
                         class="nav-icon"
                         v-b-tooltip="'Abonnementverwaltung'"
                         v-bind:class="{active:isActive('Setting')}">
               <font-awesome-icon icon="euro-sign"/>
             </b-nav-item>-->
            <b-nav-item :to="{name: 'Logout'}"
                        v-b-tooltip="'Abmelden'"
                        class="nav-icon">
              <font-awesome-icon icon="power-off"/>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <b-alert v-if="testAccount" variant="secondary" show>Sie nutzen einen kostenlosen Testaccount
      <router-link :to="{name:'Book'}">Jetzt upgraden</router-link>
    </b-alert>
    <router-view @logout="logOut()" @refreshTestAccountState="refreshTestAccountState()"/>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import HTTP from "@/http";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      searchQuery: null,
      testAccount: false,
      jwt: localStorage.getItem("token") == null ? null : VueJwtDecode.decode(localStorage.getItem("token")),
    }
  },
  mounted() {
    this.refreshTestAccountState();
  },
  methods: {
    logOut() {
      this.testAccount = false;
    },
    refreshTestAccountState() {
      if (this.jwt == null) {
        return;
      }

      HTTP.get("/users/" + this.jwt.userId).then(response => {
        this.testAccount = response.data.testAccount;
      });
    },
    isActive(routeType) {
      return this.$route.meta.routeType == routeType;
    },
    isRegisterOrLogin() {
      return this.$route.name === "Login" || this.$route.name === "Register";
    },
    isLoggedIn() {
      return localStorage.getItem('token') != null;
    },
    search() {
      if (this.$router.currentRoute.params.q === this.searchQuery) {
        return;
      }
      this.$router.push({name: 'Search', params: {q: this.searchQuery}});
    },
    searchIfEnter(key) {
      if (key.keyCode === 13) {
        this.search();
      }
    }
  }
}
</script>

<style>
.container {
  max-width: 1400px;
}

.nav-icon {
  color: rgba(255, 255, 255, 0.75);
}

.nav-icon.active {
  color: white !important;
}

.nav-icon:hover {
  color: #ccc;
}

#app {
  font-family: 'Open Sans', Avenir, Helvetica, Arial, Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #435c75;
}

.text-sm {
  font-size: 11pt;
}
body {
  background-color: white !important;
}

/*.card-header{
  background-color: #72a3d2 !important;
  color: white;
}*/

.card-header {
  background-color: #f9f9f9;
}

.card {
  box-shadow: 10px 10px 20px -21px rgba(0, 0, 0, 0.75);
  border-radius: 1px;
  margin-bottom: 8px;
}

.content {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn-primary {
  background-color: #55799d !important;
  border: 1px solid #3c5772 !important;
}

.btn-warning {
  background-color: #e3d26c !important;
  border: 1px solid #e3d26c !important;
  color: #fff;
}

.btn-danger {
  background-color: #d27272 !important;
  border: 1px solid #c86666 !important;
}

.topnav {
  /*background-color: #5d84aa !important;*/
  background-color: #435c75 !important;
}

.btn {
  border-radius: 0 !important;
}

th {
  border-top: none !important;
}

.hard-edge {
  border-radius: 0 !important;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(151, 151, 151, 0.05);
}

.card-table tr th:first-child, .card-table tr td:first-child {
  padding-left: 1.2rem;
}

.card-table tr th:last-child, .card-table tr td:last-child {
  padding-right: 1.2rem;
}

.card-table {
  width: calc(100% + 2.4rem);
  margin-right: -1.2rem;
  margin-left: -1.2rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  border-color: #6693be;
  background-color: #72a3d2 !important;
}


.sm-col {
  max-width: 175px !important;
  width: 175px;
}

.xs-col {
  max-width: 200px !important;
  width: 200px;
}

.property_head {
  margin-bottom: 12px;
  font-weight: bold;
}

.custom-file-label::after {
  content: 'Datei auswählen' !important;
}

.md-col {
  max-width: 70px;
}
</style>
