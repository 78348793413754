<template>
  <div class="container">
    <Loading v-if="stats == null" style="margin-top: 30px"/>
    <div class="content" v-if="stats != null && stats.buildingCount === 0">
      <b-card>
        <NoResultsCreateSuggest
            text="Es wurde noch nichts angelegt. Nachdem Sie das erste Gebäude angelegt haben, erhalten Sie eine Übersicht."
            :route="{name:'CreateBuilding'}" btn-text="Jetzt starten"/>
      </b-card>
    </div>
    <div class="content" v-if="stats != null && stats.buildingCount > 0">
      <h2 style="text-align: center;">Ihre Statistiken</h2><br>
      <b-row>
        <b-col sm="12" lg="4">
          <b-card header="Gebäude">
            <b-row>
              <b-col sm="12" lg="8">
                Angelegt:
              </b-col>
              <b-col sm="12" lg="4" class="text-right">
                {{ stats.buildingCount }}
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col sm="12" lg="8">
                Ø Einheiten:
              </b-col>
              <b-col sm="12" lg="4" class="text-right">
                {{ stats.avgUnitCount |dec }}
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col sm="12" lg="8">
                Ø Quadratmeter:
              </b-col>
              <b-col sm="12" lg="4" class="text-right">
                {{ stats.buildingSquareMeters |dec }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" lg="4">
          <b-card header="Einheiten">
            <b-row>
              <b-col sm="12" lg="5">
                Angelegt:
              </b-col>
              <b-col sm="12" lg="7" class="text-right">
                {{ stats.unitCount }}
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col sm="12" lg="5">
                Ø Quadratmeter:
              </b-col>
              <b-col sm="12" lg="7" class="text-right">
                {{ stats.avgUnitSquareMeters | dec }}
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col sm="12" lg="5">
                Vermietet:
              </b-col>
              <b-col sm="12" lg="7" class="text-right">
                <b-progress :max="stats.unitCount" :variant="unitUsage" show-progress height="20px">
                  <b-progress-bar :value="stats.rentingCount">
                    {{ stats.rentingCount }} / {{ stats.unitCount }}
                  </b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" lg="4">
          <b-card header="Dokumente">
            <b-row>
              <b-col sm="12" lg="5">
                Speicherplatz:
              </b-col>
              <b-col sm="12" lg="7">
                <b-progress :max="max" :variant="dataUsage" show-progress height="20px" style="position: relative">
                  <div style="margin-top: 10px; width: 100%; text-align: center; position: absolute"
                       v-if="stats.dataUsage < 60">{{ stats.dataUsage |percent }}
                  </div>
                  <div style="margin-top: 10px; width: 100%; text-align: center; position: absolute; color: white"
                       v-else>{{ stats.dataUsage |percent }}
                  </div>
                  <b-progress-bar :value="stats.dataUsage">&nbsp;</b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col sm="12" lg="5">
                Anzahl:
              </b-col>
              <b-col sm="12" lg="7" class="text-right">
                {{ stats.documentCount }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <br>
      <br>
      <h2 style="text-align: center">Ihre offenen Aufgaben</h2><br>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Offene Nebenkostenabrechnungen">
            <b-card-text v-if="billingOfIncidentals !== null && billingOfIncidentals.totalElements > 0">
              <b-row>
                <b-col lg="10" sm="12">
                  <p style="margin-top: 8px; margin-bottom: 8px" class="text-sm">Hier sehen Sie die 25 ältesten noch
                    nicht bearbeiteten
                    Nebenkostenabrechnungen die erstellt wurden,
                    mit einem Klick auf "Bearbeiten" können Sie passende Aktionen ausführen, oder die Abrechnunge weiter
                    bearbeiten. Wenn Sie auf den blauen Button mit dem Dokument Icon klicken bekommen Sie die
                    Nebenkostenabrechnung als PDF Datei erstellt.</p>
                </b-col>
                <b-col lg="2" sm="12">
                  <b-dropdown
                      class="mr-1 float-right"
                      variant="primary"
                      v-b-tooltip="'Klicken Sie hier um zu den Nebenkostenabrechnungen zu gelangen oder eine neue zu erstellen.'"
                      text="Aktionen" right>
                    <b-dropdown-item :to="{name:'ListIncidentals'}">Auflisten</b-dropdown-item>
                    <b-dropdown-item :to="{name:'CreateIncidental'}">Erstellen</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <hr>
              <b-table responsive="sm" ref="billingOfIncidentalsTable" sort-desc sort-by="created"
                       :items="billingOfIncidentals.content" no-local-sorting v-on:sort-changed="loadIncidentals"
                       :fields="billingOfIncidentalsFields" class="card-table" striped>
                <template #cell(state)="data">
                  <b-badge :variant="data.item.state | boiStateBadge">{{ data.item.state | boiStateGerman }}</b-badge>
                </template>
                <template #cell(created)="data">
                  {{ data.item.created | germanDate }}
                </template>
                <template #cell(actions)="data">
                  <b-dropdown size="sm"
                              class="mr-1"
                              variant="success"
                              v-b-tooltip.left="'Klicken Sie hier um den Status der Abrechnung zu ändern, oder diese zu bearbeiten.'"
                              text="Bearbeiten" right>
                    <b-dropdown-item @click="switchStateModal('SENT', data.item.id)"
                                     v-if="data.item.state === 'IN_WORK'">Als
                      Versendet markieren
                    </b-dropdown-item>
                    <b-dropdown-item @click="switchStateModal('OVERDUE', data.item.id)"
                                     v-if="data.item.state === 'SENT'">Als
                      überfällig markieren
                    </b-dropdown-item>
                    <b-dropdown-item @click="switchStateModal('PAYMENT_RECEIVED', data.item.id)"
                                     v-if="data.item.state === 'SENT' || data.item.state === 'OVERDUE'">Als bezahlt
                      markieren
                    </b-dropdown-item>
                    <b-dropdown-item @click="switchStateModal('GOT_ISSUES', data.item.id)"
                                     v-if="data.item.state !== 'ISSUES' && data.item.state !== 'PAID'">Als Problemfall
                      markieren
                    </b-dropdown-item>
                    <b-dropdown-item :to="{name:'EditIncidental', params: {id:data.item.id}}">Bearbeiten
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="deleteModal(data.item.id)">Löschen</b-dropdown-item>
                  </b-dropdown>
                  <b-button size="sm" @click.prevent="showIncidental(data.item.id)" variant="primary">
                    <b-spinner label="Datei wird erstellt" small v-if="loading(data.item.id)"></b-spinner>
                    <b-icon icon="file-earmark-text" v-else
                            v-b-tooltip="'Klicken Sie hier um sich die Abrechnung als PDF anzeigen zu lassen'"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <br><br><br>
            </b-card-text>
            <b-card-text v-if="billingOfIncidentals === null || billingOfIncidentals.totalElements === 0">
              <div class="text-center"><br/><br/>
                <b-icon icon="emoji-smile" scale="5em"/>
                <br/><br/><br/><br/>
                Aktuell gibt es keine offenen
                <router-link :to="{name: 'ListIncidentals'}">Nebenkostenabrechnungen</router-link>
                <div class="clearfix"></div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Offene Mahnverfahren">
            <b-row v-if="monitions != null && monitions.totalElements > 0">
              <b-col lg="10" sm="12">
                <p style="margin-top: 8px; margin-bottom: 8px">Hier sehen Sie die 25 ältesten offenen Mahnverfahren die
                  erstellt wurden, mit Klick auf die Lupe gelangen Sie direkt zum jeweiligen Mahnverfahren.</p>
              </b-col>
              <b-col lg="2" sm="12">
                <b-dropdown
                    class="mr-1 float-right"
                    variant="primary"
                    v-b-tooltip="'Klicken Sie hier um zu den Mahnverfahren zu gelangen oder ein neues zu erstellen.'"
                    text="Aktionen" right>
                  <b-dropdown-item :to="{name:'ListAllMonitions'}">Auflisten</b-dropdown-item>
                  <b-dropdown-item :to="{name:'CreateMonition'}">Erstellen</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <hr v-if="monitions != null && monitions.totalElements > 0">
            <b-card-text v-if="monitions != null && monitions.totalElements > 0">
              <b-table responsive ref="monitionTable" :items="monitions.content" :fields="monitionFields"
                       class="card-table" striped>
                <template #cell(actions)="data">
                  <b-button variant="primary" size="sm" v-b-tooltip="'Mahnung anzeigen'"
                            :to="{name:'ShowMonition', params: {id: data.item.id}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>&nbsp;
                  <b-button variant="success" v-b-tooltip="'Mahnung als bezahlt markieren'" size="sm"
                            @click="markAsPaid(data.item.id,data.index)"
                            v-if="data.item.state !== 'PAID' && data.item.state !== 'FAILED'&& data.item.state !== 'CANCELED'">
                    <b-icon icon="cash"></b-icon>
                  </b-button>
                </template>
                <template #cell(overdueSince)="data">
                  {{ data.item.overdueSince | germanDate }}
                </template>
                <template #cell(state)="data">
                  <b-badge :variant="data.item.state | monitionStateBadge">{{ data.item.state | monitionStateGerman }}
                  </b-badge>
                </template>
              </b-table>
            </b-card-text>
            <b-card-text v-if="monitions == null || monitions.totalElements == 0">
              <div class="text-center"><br/><br/>
                <b-icon icon="emoji-smile" scale="5em"/>
                <br/><br/><br/><br/>
                Aktuell gibt es keine offenen
                <router-link :to="{name:'ListAllMonitions'}">Mahnverfahren</router-link>
                <div class="clearfix"></div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Neuste Notizen">
            <b-card-text v-if="comments !== null && comments.totalElements > 0">
              <p style="margin-top: 8px; margin-bottom: 8px">Hier sehen Sie die 25 neusten Notizen/Bemerkungen die
                erstellt wurden, mit Klick auf die Lupe gelangen Sie direkt zum dazugehörigen Datensatz.</p>
              <hr>
              <b-table responsive :items="comments.content" :fields="commentFields" class="card-table" striped>
                <template #cell(created)="data">
                  {{ data.item.created | germanDate }}
                </template>
                <template #cell(entity)="data">
                  {{ data.item.entity | entityNameToGerman }}
                </template>
                <template #cell(actions)="data">
                  <b-button v-if="data.item.entity === 'Building'" size="sm" variant="primary"
                            :to="{name:'ShowBuilding', params: {id:data.item.referenceId}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>
                  <b-button v-if="data.item.entity === 'unit'" size="sm" variant="primary"
                            :to="{name:'ShowUnit', params: {id:data.item.referenceId}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>
                  <b-button v-if="data.item.entity === 'supplier'" size="sm" variant="primary"
                            :to="{name:'ShowSupplier', params: {id:data.item.referenceId}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>
                  <b-button v-if="data.item.entity === 'renter'" size="sm" variant="primary"
                            :to="{name:'ShowRenter', params: {id:data.item.referenceId}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>
                  <b-button v-if="data.item.entity === 'Monition'" size="sm" variant="primary"
                            :to="{name:'ShowMonition', params: {id:data.item.referenceId}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>
                  <b-button v-if="data.item.entity === 'BillingOfIncidentals'" size="sm" variant="primary"
                            :to="{name:'CreateIncidental', params: {id:data.item.referenceId}}">
                    <b-icon icon="eye"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-card-text>
            <b-card-text v-if="comments == null || comments.totalElements == 0">
              <div class="text-center"><br/><br/>
                <b-icon icon="cloud-slash" scale="5em"/>
                <br/><br/><br/><br/>
                Es wurden noch keine Notizen hinterlegt
                <div class="clearfix"></div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>

import HTTP from "@/http";
import Loading from "@/components/Loading";
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import messageService from "@/services/message.service";

export default {
  name: 'Start',
  components: {NoResultsCreateSuggest, Loading},
  data() {
    return {
      value: 1,
      max: 100,
      unitUsage: "primary",
      dataUsage: "primary",
      comments: null,
      monitions: null,
      stats: null,
      billingOfIncidentals: null,
      commentFields: [
        {
          key: "created",
          label: "Erstellt",
          class: 'sm-col'
        },
        {
          key: "entity",
          label: "Bereich",
          class: 'md-col'
        },
        {
          key: "comment",
          label: "Kommentar / Notiz"
        }, {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      monitionFields: [
        {
          key: "building",
          label: 'Gebäude'
        },
        {
          key: "unit",
          label: 'Einheit'
        },
        {
          key: "overdueSince",
          sortable: true,
          label: "Überfällig seit"
        },
        {
          key: "state",
          sortable: true,
          label: "Mahnstufe"
        },
        {
          key: "actions",
          label: "Aktionen",
          class: 'sm-col'
        }
      ],
      billingOfIncidentalsFields: [
        {
          key: 'buildingName',
          label: 'Gebäude',
          sortable: false
        },
        {
          key: 'unitName',
          label: 'Einheit',
          sortable: false
        },
        {
          key: 'created',
          label: 'Erstellt',
          sortable: true
        },
        {
          key: 'state',
          label: 'Status',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        },
      ],
      loadingId: null,
    }
  },
  created() {
    this.loadStats();
    this.loadComments();
    this.loadMonitions();
    this.loadIncidentals();
  },
  methods: {
    loading: function (id) {
      return id === this.loadingId;
    },
    markAsPaid: function (id, i) {
      HTTP.post("/process-monition/" + id + "/MARK_AS_PAID").then(() => {
        HTTP.get("/monitions/" + id).then(response => this.monitions.content[i].state = response.data.state);
      });
    },
    loadComments: function () {
      HTTP.get("/comments?size=25&sort=id,desc").then(response => {
        this.comments = response.data;
      });
    },
    loadMonitions: function () {
      HTTP.get("/monitions?size=25&state=OPEN&sort=id,asc").then(response => {
        this.monitions = response.data;
        for (let i = 0; i < this.monitions.content.length; i++) {
          HTTP.get("/buildings/" + this.monitions.content[i].buildingId).then(resp => {
            this.monitions.content[i].building = resp.data.street + " " + resp.data.city;
            this.$refs.monitionTable.refresh();
          });
          HTTP.get("/units/" + this.monitions.content[i].unitId).then(resp => {
            this.monitions.content[i].unit = resp.data.name;
            this.$refs.monitionTable.refresh();
          });
        }
      });
    },
    showIncidental: function (id) {
      this.loadingId = id;
      HTTP.get("/billing-of-incidentals/pdf/" + id, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "nebenkostenabrechnung.pdf"
            link.click()
            URL.revokeObjectURL(link.href)
            this.loadingId = null
          }).catch(() => {
            messageService.error("Fehler aufgetreten","Bitte wenden Sie sich falls der Fehler häufiger auftritt an den Support");
      })
    },
    loadIncidentals: function (sort) {
      let sortStr = '';
      if (sort === undefined || sort === null) {
        sortStr = "&sort=created,desc";
      } else {
        sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/billing-of-incidentals?size=25" + sortStr + "&state=IN_WORK").then(response => {
        this.billingOfIncidentals = response.data;
        for (let i = 0; i < this.billingOfIncidentals.content.length; i++) {
          HTTP.get("/units/" + this.billingOfIncidentals.content[i].unitId).then(response => {
            let unit = response.data;
            this.billingOfIncidentals.content[i].unitName = unit.name;
            this.$refs.billingOfIncidentalsTable.refresh();

            HTTP.get("/buildings/" + unit.buildingId).then(response => {
              this.billingOfIncidentals.content[i].buildingName = response.data.city + " " + response.data.street;
              this.$refs.billingOfIncidentalsTable.refresh();
            });
          });
        }
      });
    },
    loadStats: function () {
      HTTP.get("/dashboard-statistics").then(response => {
        this.stats = response.data;
        this.unitUsage = this.stats.rentingCount < this.stats.unitCount ? "warning" : "success";
        if (this.stats.dataUsage <= 80) {
          this.dataUsage = "success";
        } else if (this.stats.dataUsage > 80) {
          this.dataUsage = "warning";
        } else if (this.stats.dataUsage > 90) {
          this.dataUsage = "danger";
        }
      }).catch(error => {
        if (error.response.status === 401 || error.response.status === 403) {
          this.$router.push({name: "Login"})
        }
      });
    }
  }

}
</script>

<style scoped>
.start_cta {
  padding-left: 30px;
  padding-right: 30px;
}

</style>