<template>
  <div class="container">
    <div class="content">
      <b-row>
        <b-col lg="4" sm="12">
          <div class="card">
            <div class="card-header text-center">Gebäudeinformationen</div>
            <div class="card-body">
              <div class="property_head">
                <b-icon icon="building"></b-icon>
                Daten zum Gebäude
              </div>
              Anschrift: {{ building.street }} {{ building.zipCode }} {{ building.city }}<br/>
              Erstellungdatum: {{ building.created | germanDate }}<br/>
              <hr/>
              <b-row>
                <b-col>

                  <div class="property_head">
                    Dokumente
                  </div>
                  {{ stats.documentCount }}<br/>
                </b-col>
                <b-col>

                  <div class="property_head">
                    Kommentare
                  </div>
                  {{ stats.commentCount }}<br/>
                </b-col>
                <b-col>
                  <div class="property_head">
                    Reparaturen
                  </div>
                  {{ stats.repairCount }}<br/>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col lg="4" sm="12">
          <div class="card">
            <div class="card-header text-center">Flächen & Beträge</div>
            <div class="card-body">
              <b-row>
                <b-col>
                  <div class="property_head">
                    <b-icon icon="arrows-fullscreen"></b-icon>
                    Nutzfläche
                  </div>
                  <span v-if="building.livingSquareMeters !== null && building.livingSquareMeters !== undefined">{{
                      building.livingSquareMeters
                    }} qm</span>
                  <span v-if="building.livingSquareMeters === null || building.livingSquareMeters === undefined">keine Angabe</span>
                </b-col>
                <b-col>
                  <div class="property_head">
                    <b-icon icon="cash"></b-icon>
                    Mieteinahmen mtl.
                  </div>
                  <span v-if="stats.totalRent !== null && stats.totalRent !== undefined">{{
                      stats.totalRent
                    }} €</span>
                  <span v-if="stats.totalRent === null || stats.totalRent === undefined">keine Angabe</span>

                </b-col>
              </b-row>

              <br/>
              <hr/>
              <b-row>
                <b-col>
                  <div class="property_head">
                    <b-icon icon="aspect-ratio"></b-icon>
                    Nebenfläche
                  </div>
                  <span
                      v-if="building.secondaryAreaSquareMeters !== null && building.secondaryAreaSquareMeters !== undefined">{{
                      building.secondaryAreaSquareMeters
                    }} qm</span>
                  <span
                      v-if="building.secondaryAreaSquareMeters === null || building.secondaryAreaSquareMeters === undefined">keine Angabe</span>
                </b-col>
                <b-col>
                  <div class="property_head">
                    <b-icon icon="cash"></b-icon>
                    Kosten pro Jahr
                  </div>
                  <span v-if="stats.totalCost !== null && stats.totalCost !== undefined">{{
                      stats.totalCost
                    }} €</span>
                  <span v-if="stats.totalCost === null || stats.totalCost === undefined">keine Angabe</span>

                </b-col>
              </b-row>

            </div>
          </div>
        </b-col>
        <b-col lg="4" sm="12">
          <div class="card">
            <div class="card-header text-center">Einheiten</div>
            <div class="card-body">
              <div class="property_head">
                <b-icon icon="bricks"></b-icon>
                Angelegt
              </div>
              <b-progress :max="stats.totalUnits" variant="secondary" show-progress height="20px">
                <b-progress-bar :value="stats.unitCount">
                  {{ stats.unitCount }} / {{ stats.totalUnits }}
                </b-progress-bar>
              </b-progress>
              <hr>
              <div class="property_head">
                <b-icon icon="cash"></b-icon>
                Vermietet
              </div>
              <b-progress :max="stats.unitCount" variant="primary" show-progress height="20px">
                <b-progress-bar :value="stats.rentingCount">
                  {{ stats.rentingCount }} / {{ stats.unitCount }}
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Einheitenübersicht" :active="activeTab === 'units'">
            <b-card-text>
              <b-skeleton-table v-if="units == null"/>
              <div v-if="units!=null && units.totalElements === 0">
                <NoResultsCreateSuggest text="Noch keine Einheit angelegt"
                                        :route="{name:'CreateUnit', params: {buildingId:this.building.id}}"/>
              </div>
              <div v-if="units!=null && units.totalElements > 0">
                <router-link tag="a" :to="{name:'CreateUnit', params: {buildingId:this.building.id}}"
                             class="btn btn-primary float-right"
                             v-b-tooltip="'Klicken Sie hier um eine weitere Wohneinheit in diesem Gebäude zu erstellen'">
                  Weitere
                  Einheit anlegen
                </router-link>
                <br/><br/>
                <b-table striped hover
                         :responsive="true"
                         class="card-table"
                         :no-local-sorting="true"
                         v-on:sort-changed="loadUnits"
                         :items="items"
                         :fields="fields">
                  <template #cell(type)="data">
                    <b-badge variant="primary">{{ data.item.type|germanUnitType }}</b-badge>
                  </template>
                  <template #cell(rented)="data">
                    <b-badge variant="success" v-if="data.item.currentRenterId">Ja</b-badge>
                    <b-badge variant="danger" v-if="!data.item.currentRenterId">Nein</b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <router-link :to="{name:'ShowUnit', params: {'id':data.item.id}}"
                                 class="btn btn-primary btn-sm"
                                 v-b-tooltip.hover.left="'Klicken Sie hier um sich die Einheit anzeigen zu lassen.'">
                      <b-icon icon="eye" font-scale="1"></b-icon>
                    </router-link>&nbsp;&nbsp;
                    <router-link :to="{name:'CreateUnit', params: {'buildingId':building.id,'id':data.item.id}}"
                                 v-b-tooltip.hover.left="'Klicken Sie hier um die Einheit zu bearbeiten.'"
                                 class="btn btn-warning btn-sm">
                      <b-icon icon="pencil" font-scale="1"></b-icon>
                    </router-link>&nbsp;&nbsp;
                    <b-button size="sm" variant="danger" @click.prevent="unitDeleteModal(data.item.id)"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Einheit anzeigen zu löschen.'">
                      <b-icon icon="trash" font-scale="1"></b-icon>
                    </b-button>
                  </template>
                </b-table>
                <PageableTable v-bind:pageable="units" v-bind:toRoute="'ShowBuilding'"
                               v-bind:routing-params="{id:this.id}"/>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Gebäudekosten" :active="activeTab === 'building-cost'">
            <b-card-text>
              <b-skeleton-table v-if="buildingCosts == null"/>
              <div v-if="buildingCosts !=null && buildingCosts.totalElements === 0">
                <NoResultsCreateSuggest text="Noch keine Kosten angelegt"
                                        :route="{name:'CreateBuildingCost', params: {buildingId:this.building.id}}"/>
              </div>
              <div v-if="buildingCosts!=null && buildingCosts.totalElements > 0">
                <b-button :to="{name:'CreateBuildingCost', params: {buildingId:this.building.id}}"
                          v-b-tooltip="'Hier klicken um Gebäudekosten anzulegen.'"
                          variant="primary"
                          class="float-right">Weitere
                  Kosten anlegen
                </b-button>
                <b-table striped
                         :responsive="true"
                         class="card-table"
                         :items="buildingCosts.content"
                         :fields="buildingCostFields"
                         :no-local-sorting="true"
                         v-on:sort-changed="loadBuildingCost">
                  <template #cell(billingPointKey)="data">
                    {{ data.item.billingPointKey | germanBillingPoint }}
                  </template>
                  <template #cell(cost)="data">
                    {{ data.item.cost | currency }}
                  </template>
                  <template #cell(active)="data">
                    <b-checkbox switch v-model="data.item.active" @change="toggleCost(data.item)"/>
                  </template>
                  <template #cell(actions)="data">
                    <b-button size="sm" variant="secondary"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Gebäudekosten zu kopieren'"
                              :to="{name: 'CreateBuildingCost', params: {id:data.item.id, buildingId: building.id, copy:true}}">
                      <b-icon icon="journals"/>
                    </b-button>&nbsp;
                    <b-button size="sm" variant="warning"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Gebäudekosten zu bearbeiten'"
                              :to="{name: 'CreateBuildingCost', params: {id:data.item.id, buildingId: building.id}}">
                      <b-icon icon="pencil"/>
                    </b-button>&nbsp;
                    <b-button size="sm" variant="danger"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Gebäudekosten zu löschen'"
                              @click="buildingCostDeleteModal(data.item.id)">
                      <b-icon icon="trash"/>
                    </b-button>
                  </template>
                </b-table>
                <RouterlessPageable :pageable="buildingCosts" @change="loadBuildingCostPage"/>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Verbrauchskosten" :active="activeTab === 'building-usage-cost'">
            <b-card-text>
              <b-skeleton-table v-if="buildingUsageCosts == null"/>
              <div v-if="buildingUsageCosts !=null && buildingUsageCosts.totalElements === 0">
                <NoResultsCreateSuggest text="Noch keine Kosten angelegt"
                                        :route="{name:'CreateBuildingUsageCost', params: {buildingId:this.building.id}}"/>
              </div>
              <div v-if="buildingUsageCosts!=null && buildingUsageCosts.totalElements > 0">
                <b-button :to="{name:'CreateBuildingUsageCost', params: {buildingId:this.building.id}}"
                          v-b-tooltip="'Klicken Sie hier um neue Verbrauchskosten anzulegen'"
                          variant="primary"
                          class="float-right">Weitere
                  Kosten anlegen
                </b-button>
                <b-table striped
                         :responsive="true"
                         class="card-table"
                         :items="buildingUsageCosts.content"
                         :fields="buildingUsageCostFields"
                         :no-local-sorting="true"
                         v-on:sort-changed="loadBuildingUsageCost">
                  <template #cell(counterType)="data">
                    {{ data.item.counterType | germanCounterType }}
                  </template>
                  <template #cell(pricePerUnit)="data">
                    {{ data.item.pricePerUnit | currency }}
                  </template>
                  <template #cell(active)="data">
                    <b-checkbox switch v-model="data.item.active" @change="toggleUsageCost(data.item)"/>
                  </template>
                  <template #cell(actions)="data">
                    <b-button size="sm" variant="secondary"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Verbrauchskosten zu kopieren'"
                              :to="{name: 'CreateBuildingUsageCost', params: {id:data.item.id, buildingId: building.id, copy:true}}">
                      <b-icon icon="journals"/>
                    </b-button>&nbsp;
                    <b-button size="sm" variant="warning"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Verbrauchskosten zu editieren'"
                              :to="{name: 'CreateBuildingUsageCost', params: {id:data.item.id, buildingId: building.id}}">
                      <b-icon icon="pencil"/>
                    </b-button>&nbsp;
                    <b-button size="sm" variant="danger" @click="buildingUsageCostDeleteModal(data.item.id)"
                              v-b-tooltip.hover.left="'Klicken Sie hier um die Verbrauchskosten zu löschen'">
                      <b-icon icon="trash"/>
                    </b-button>
                  </template>
                </b-table>
                <RouterlessPageable :pageable="buildingUsageCosts" @change="loadBuildingUsageCostPage"/>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Reparaturen" :active="activeTab === 'repairs'">
            <ListRepairs :building-id="this.id"/>
          </b-tab>
          <b-tab title="Dokumente" :active="activeTab === 'documents'">
            <DocumentFileList :entity-id="this.id" :entity-type="'building'" :table-class="'card-table'"
                              :return-to="{name: 'ShowBuildingTab', params: {id: this.id, activeTab:'documents'}}"/>
          </b-tab>
          <b-tab title="Garagen / Stellplätze" :active="activeTab === 'garage'">
            <b-card-text>
              <b-skeleton-table v-if="garages == null"/>
              <div v-if="garages !=null && garages.totalElements === 0">
                <NoResultsCreateSuggest text="Noch keine Garagen / Stellplätze angelegt"
                                        :route="{name:'CreateGarage', params: {buildingId:this.building.id}}"/>
              </div>
              <div v-if="garages!=null && garages.totalElements > 0">
                <b-button :to="{name:'CreateGarage', params: {buildingId:this.building.id}}"
                          v-b-tooltip="'Klicken Sie hier um neue Garagen anzulegen'"
                          variant="primary"
                          class="float-right">Weitere
                  Garagen anlegen
                </b-button>
                <b-table striped
                         :responsive="true"
                         class="card-table"
                         :items="garages.content"
                         :fields="garageFields"
                         :no-local-sorting="true"
                         v-on:sort-changed="loadGarages">
                  <template #cell(type)="data">
                    {{ data.item.type | germanGarageType }}
                  </template>

                  <template #cell(rentingId)="data">
                    <b-badge variant="success" v-if="data.item.rentingId">Ja</b-badge>
                    <b-badge variant="danger" v-if="!data.item.rentingId">Nein</b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <div class="float-right">
                      <b-button size="sm" variant="primary"
                                v-if="data.item.rentingId !== null"
                                v-b-tooltip.hover.left="'Klicken Sie hier um zum Mietverhältnis zu gelangen'"
                                :to="{name: 'ShowUnit', params: {id:data.item.unitId}}">
                        <b-icon icon="card-text"/>
                      </b-button>&nbsp;
                      <b-button size="sm" variant="secondary"
                                v-b-tooltip.hover.left="'Klicken Sie hier um die Garage zu kopieren'"
                                :to="{name: 'CopyGarage', params: {id:data.item.id, buildingId: building.id, copy:true}}">
                        <b-icon icon="journals"/>
                      </b-button>&nbsp;
                      <b-button size="sm" variant="warning"
                                v-b-tooltip.hover.left="'Klicken Sie hier um die Garage zu editieren'"
                                :to="{name: 'CreateGarage', params: {id:data.item.id, buildingId: building.id}}">
                        <b-icon icon="pencil"/>
                      </b-button>&nbsp;
                      <b-button size="sm" variant="danger" @click="garageDeleteModal(data.item.id)"
                                v-b-tooltip.hover.left="'Klicken Sie hier um die Garage zu löschen'">
                        <b-icon icon="trash"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <RouterlessPageable :pageable="garages" @change="loadGarages"/>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      <hr>
      <Comment entity-type="Building" :entity-id="this.id"/>
    </div>
    <b-modal id="delete-unit-modal" title="Einheit löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen" @ok="deleteUnit()">Möchten Sie die ausgewählte Einheit wirklich löschen?
    </b-modal>
    <b-modal id="delete-cost-modal" title="Gebäudekosten löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen" @ok="deleteBuildingCost()">Möchten Sie die ausgewählten Kosten wirklich löschen?
    </b-modal>
    <b-modal id="delete-usage-cost-modal" title="Verbrauchskosten löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen" @ok="deleteBuildingUsageCost()">Möchten Sie die ausgewählten Kosten wirklich
      löschen?
    </b-modal>
    <b-modal id="delete-garage-modal" title="Garage löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen" @ok="deleteGarage()">Möchten Sie die ausgewählte Garage wirklich
      löschen?
    </b-modal>

  </div>
</template>
<script>
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import RouterlessPageable from "@/components/RouterlessPageable";
import messageService from '@/services/message.service';
import PageableTable from "@/components/PageableTable";
import ListRepairs from "@/views/repair/ListRepairs";
import Comment from "@/components/Comment";
import Building from "@/models/building";
import HTTP from "@/http";
import DocumentFileList from "@/components/DocumentFileList";

export default {
  name: 'ShowBuilding',
  components: {DocumentFileList, RouterlessPageable, PageableTable, NoResultsCreateSuggest, Comment, ListRepairs},
  data() {
    return {
      activeTab: this.$route.params.activeTab === undefined ? 'units' : this.$route.params.activeTab,
      id: this.$route.params.id,
      building: new Building(this.id, "", "", "", 0, 0, 0),
      buildingCosts: null,
      buildingUsageCosts: null,
      units: null,
      page: this.$route.params.page,
      sort: undefined,
      "items": [],
      buildingCostDeleteId: null,
      buildingUsageCostDeleteId: null,
      garageDeleteId: null,
      unitDeleteId: null,
      stats: {},
      garages: null,
      garageFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'vehicles',
          label: 'Fahrzeugplätze',
          sortable: true
        },
        {
          key: 'type',
          label: 'Typ',
          sortable: true
        },
        {
          key: 'rentingId',
          label: 'Vermietet'
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'xs-col'
        }
      ],
      buildingCostFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'cost',
          label: 'Kosten',
          sortable: true
        },
        {
          key: 'billingPointKey',
          label: 'Umlageschlüssel',
          sortable: true
        },
        {
          key: 'active',
          label: 'Aktiv',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      buildingUsageCostFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'pricePerUnit',
          label: 'Preis pro Verbrauchseinheit',
          sortable: true
        },
        {
          key: 'counterType',
          label: 'Zählertyp',
          sortable: true
        },
        {
          key: 'active',
          label: 'Aktiv',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      "fields": [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "type",
          label: "Typ",
          sortable: true
        },
        {
          key: "rented",
          label: "Vermietet"
        },
        {
          key: "squareMeters",
          label: "Quadratmeter",
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      costSortStr: '',
      unitSortStr: '',
      usageCostSortStr: ''
    }
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.page = to.params.page;
        this.loadUnits(this.sort);
      }
    }
  },
  methods: {
    unitDeleteModal: function (id) {
      this.unitDeleteId = id;
      this.$bvModal.show("delete-unit-modal");
    },
    buildingCostDeleteModal: function (id) {
      this.buildingCostDeleteId = id;
      this.$bvModal.show('delete-cost-modal');
    },
    buildingUsageCostDeleteModal: function (id) {
      this.buildingUsageCostDeleteId = id;
      this.$bvModal.show('delete-usage-cost-modal');
    },
    garageDeleteModal: function (id) {
      this.garageDeleteId = id;
      this.$bvModal.show('delete-garage-modal');
    },
    toggleCost: function (item) {
      HTTP.put("/building-costs/" + item.id, item);
    },
    toggleUsageCost: function (item) {
      HTTP.put("/building-usage-costs/" + item.id, item);
    },
    deleteBuildingCost: function () {
      HTTP.delete("/building-costs/" + this.buildingCostDeleteId).then(() => {
        messageService.success('Löschen erfolgreich', 'Kosten erfolgreich gelöscht');
        this.loadBuildingCost();
      })
    },
    deleteGarage: function () {
      HTTP.delete("/garages/" + this.garageDeleteId).then(() => {
        messageService.success('Löschen erfolgreich', 'Garage erfolgreich gelöscht');
        this.loadGarages();
      }).catch(error => {
        if (error.response.status === 412) {
          messageService.hint('Garage nicht gelöscht', 'Die Garage kann nicht gelöscht werden, ein Mietverhältnis verweist auf diese');
        }
      })
    },
    deleteBuildingUsageCost: function () {
      HTTP.delete("/building-usage-costs/" + this.buildingUsageCostDeleteId).then(() => {
        messageService.success('Löschen erfolgreich', 'Kosten erfolgreich gelöscht');
        this.loadBuildingUsageCost();
      })
    },
    deleteUnit: function () {
      HTTP.delete("/units/" + this.unitDeleteId).then(() => {
        messageService.success('Löschen erfolgreich', 'Einheit erfolgreich gelöscht');
        this.loadUnits();
      }).catch(error => {
        if (error.response.status === 412) {
          messageService.hint('Löschen fehlgeschlagen', 'Einheit kann nicht gelöscht werden, es gibt noch dazugehörige Datensätze (Mietverhältnisse, Mahnungen und co.)');
        }
      });
    },
    loadGarages: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort !== undefined) {
        this.unitSortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/garages/?page=" + currentPage + this.unitSortStr + "&buildingId=" + this.id)
          .then(resp => {
            this.garages = resp.data;
          });
    },
    loadUnits: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort !== undefined) {
        this.unitSortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/units/?page=" + currentPage + this.unitSortStr + "&buildingId=" + this.id)
          .then(resp => {
            this.units = resp.data;
            this.items = this.units.content;
          });
    },
    loadBuildingCostPage: function (page) {
      let currentPage = page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      HTTP.get("/building-costs/?page=" + currentPage + this.costSortStr + "&buildingId=" + this.id)
          .then(resp => {
            this.buildingCosts = resp.data;
          });
    },
    loadBuildingUsageCostPage: function (page) {
      let currentPage = page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      HTTP.get("/building-usage-costs/?page=" + currentPage + this.usageCostSortStr + "&buildingId=" + this.id)
          .then(resp => {
            this.buildingUsageCosts = resp.data;
          });
    },
    loadBuildingCost: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort !== undefined) {
        this.costSortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/building-costs/?page=" + currentPage + this.costSortStr + "&buildingId=" + this.id)
          .then(resp => {
            this.buildingCosts = resp.data;
          });
    },
    loadBuildingUsageCost: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort !== undefined) {
        this.usageCostSortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/building-usage-costs/?page=" + currentPage + this.usageCostSortStr + "&buildingId=" + this.id)
          .then(resp => {
            this.buildingUsageCosts = resp.data;
          });
    }
  },
  created() {
    HTTP.get("/buildings/" + this.id)
        .then(resp => this.building = resp.data);
    HTTP.get("/building-statistics/" + this.id).then(response => this.stats = response.data);
    this.loadUnits();
    this.loadBuildingCost();
    this.loadBuildingUsageCost();
    this.loadGarages();
  }
}
</script>
<style scoped>
.counter {
  font-size: 20px !important;
}
</style>