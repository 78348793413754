<template>
  <div class="container">
    <div class="content">
      <b-alert v-if="user.testAccount && hint" variant="danger" show>
        Ihr Testaccount ist ausgelaufen, aktivieren Sie Ihr Abonnement
      </b-alert>
      <b-alert v-if="!user.testAccount && hint && !paid" variant="danger" show>
        Ihr Abonnement wurde gekündigt.
      </b-alert>
      <b-alert v-if="paid" variant="success" show>
        Ihr Abonnement wurde erfolgreich erstellt, klicken Sie auf Ihren gewünschten Menüpunkt um die Software zu verwenden.
      </b-alert>
      <b-card class="form-signin" v-if="!loading && !done" no-body>
        <b-card-header style="background: #435c75; border-radius: 0 !important; color: white">
          Kostenpflichtig bestellen
        </b-card-header>
        <b-card-body>
          <b-alert variant="warning" v-model="showWarning" dismissible>
            Bitte füllen Sie alle Felder korrekt aus.
          </b-alert>
          <b-form @submit.prevent="create()" novalidate>
            <b-row>
              <b-col sm="6" cols="12">
                <b-row>
                  <b-col sm="3" cols="12">
                    <b-form-group id="input-group-salutation" label="Anrede" label-for="salutation">
                      <b-form-select v-model="user.salutation" :options="this.salutations" :state="salutationState"
                                     id="salutation">
                      </b-form-select>
                      <b-form-invalid-feedback>Bitte eine Anrede auswählen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" cols="12">
                    <b-form-group label="Ihr Vorname:" v-b-tooltip="'Tragen Sie hier Ihren Vornamen ein'">
                      <b-form-input type="text"
                                    :state="prenameState"
                                    v-model="user.prename"
                                    placeholder="Ihr Vorname"></b-form-input>
                      <b-form-invalid-feedback>Bitte einen korrekten Vornamen ein</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" cols="12">
                    <b-form-group label="Ihr Nachname:" v-b-tooltip="'Tragen Sie hier Ihren Nachnamen ein'">
                      <b-form-input type="text"
                                    :state="lastnameState"
                                    v-model="user.lastname"
                                    placeholder="Ihr Nachname"></b-form-input>
                      <b-form-invalid-feedback>Bitte einen korrekten Nachnamen ein</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="user.salutation === 'FIRM'">
                  <b-col>
                    <b-form-group label="Firmenname:" v-b-tooltip="'Tragen Sie hier Ihren Firmennamen ein'">
                      <b-form-input type="text"
                                    :state="firmState"
                                    v-model="user.firm"
                                    placeholder="Ihr Firmenname"></b-form-input>
                      <b-form-invalid-feedback>Bitte einen korrekten Firmennamen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Ihre Straße und Hausnummer:" v-b-tooltip="'Tragen Sie hier Ihre Anschrift ein'">
                      <b-form-input type="text"
                                    :state="streetState"
                                    v-model="user.street"
                                    placeholder="Ihre Anschrift"></b-form-input>
                      <b-form-invalid-feedback>Bitte eine korrekte Email Adresse eintragen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4" cols="12">
                    <b-form-group label="Postleitzahl:" v-b-tooltip="'Tragen Sie hier Ihre PLZ ein'">
                      <b-form-input type="text"
                                    :state="zipCodeState"
                                    v-model="user.zipCode"
                                    @input="zipCodeAutocomplete"
                                    placeholder="Ihre PLZ"></b-form-input>
                      <b-form-invalid-feedback>Bitte eine korrekte Postleitzahl eintragen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Ihre Stadt:" v-b-tooltip="'Tragen Sie hier Ihre Stadt ein'">
                      <b-form-input type="text"
                                    :state="cityState"
                                    v-model="user.city"
                                    placeholder="Ihre Stadt"></b-form-input>
                      <b-form-invalid-feedback>Bitte eine korrekte Stadt eintragen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="6" cols="12">
                <b-row>
                  <b-col sm="6" cols="12">
                    <b-form-group label="Wie möchten Sie zahlen:">
                      <b-form-radio v-model="user.paymentType" value="INVOICE" switch> Überweisung
                      </b-form-radio>
                      <b-form-radio v-model="user.paymentType" value="SEPA" switch> SEPA Lastschrift
                      </b-form-radio>
                    </b-form-group>
                  </b-col>
                  <b-col class="text-center" style="font-size: 40px; color: green">
                    59,99 € <br/>
                    <div style="line-height: 18px;">
                      <span style="font-size: 18px; ">pro Jahr</span><br/>
                      <span style="font-size: 12px; ">(ink. 19% MwSt.)</span>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="user.paymentType === 'SEPA'">
                  <b-col sm="6" cols="12">
                    <b-form-group label="Ihre IBAN:">
                      <b-form-input type="text"
                                    v-b-tooltip="'Tragen Sie hier Ihre IBAN ein'"
                                    v-model="user.bankingData.iban"
                                    :state="ibanState"
                                    placeholder="Eine gültige IBAN"></b-form-input>
                      <b-form-invalid-feedback>Bitte ein korrekte IBAN eintragen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Ihre BIC:">
                      <b-form-input type="text"
                                    v-b-tooltip="'Tragen Sie hier Ihre BIC ein'"
                                    v-model="user.bankingData.bic"
                                    :state="bicState"
                                    placeholder="Eine gültige BIC"></b-form-input>
                      <b-form-invalid-feedback>Bitte ein korrekte BIC eintragen</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-form-checkbox :state="agbState" v-model="agb" switch> Ich akzeptiere die <a
                    href="https://www.immolert.de/agb" target="_blank">AGB</a>
                </b-form-checkbox>
                <b-form-checkbox :state="gdprState" v-model="gdpr" switch> Ich akzeptiere die <a
                    href="https://www.immolert.de/datenschutzerklaerung" target="_blank">Datenschutzvereinbarungen</a>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" cols="12">
                <b-form-group label="Ihre E-Mail Adresse:" v-b-tooltip="'Tragen Sie hier Ihre E-Mail Adresse ein'">
                  <b-form-input type="email"
                                :state="emailState"
                                v-model="user.email"
                                placeholder="E-Mail Adresse" disabled></b-form-input>
                  <b-form-invalid-feedback>Bitte eine korrekte E-Mail Adresse eintragen</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <br>
            <b-alert :show="error" variant="danger">Es gibt bereits einen Account mit dieser E-Mail Adresse</b-alert>

            <b-row>
              <b-col>
                <b-button variant="success" type="submit"
                          style="padding-left: 40px; padding-right: 40px; display: block; margin: auto">Jetzt
                  kostenpflichtig bestellen
                </b-button>
              </b-col>
            </b-row>
            <br>
          </b-form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import {Salutations} from "@/models/static/salutations";
import zipCodeService from "@/services/zipcode.service";
import validator from "@/services/validator.service";
import HTTP from "@/http";
import VueJwtDecode from "vue-jwt-decode";
import AuthService from "@/services/auth.service";

export default {
  name: 'UserSetting',
  components: {},
  data() {
    return {
      user: {
        salutation: null,
        contactInformation: {},
        paymentType: "SEPA", bankingData: {},
        city: null,
        country: 'DE'
      },
      hint: this.$route.params.hint,
      showWarning: false,
      loading: false,
      message: '',
      salutations: Salutations.get(),
      selectedSalutation: null,
      emailState: null,
      passwordState: null,
      salutationState: null,
      prenameState: null,
      lastnameState: null,
      zipCodeState: null,
      cityState: null,
      streetState: null,
      agbState: null,
      gdprState: null,
      firmState: null,
      ibanState: null,
      bicState: null,
      gdpr: false,
      agb: false,
      done: false,
      error: false,
      paid: false,
      jwt: VueJwtDecode.decode(localStorage.getItem("token")),
    }
  },
  created() {
    HTTP.get("/users/" + this.jwt.userId).then(response => {
      this.user = response.data;
      if(this.user.bankingData === undefined || this.user.bankingData === null) {
        this.user.bankingData = {};
      }
      this.user.country = 'DE';
    });

    let tag = document.createElement("script");
    tag.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=AW-694450850");
    document.head.appendChild(tag);
  },
  methods: {
    gtag: function () {
      if (window.dataLayer === undefined) {
        return;
      }
      window.dataLayer.push(arguments);
    },
    gtag_report_conversion: function (url) {
      this.gtag('js', new Date());

      this.gtag('config', 'AW-694450850');
      var callback = function () {
        if (typeof (url) != 'undefined') {
          window.location = url;
        }
      };
      this.gtag('event', 'conversion', {
        'send_to': 'AW-694450850/dBDXCPiO3_MBEKL1kcsC',
        'event_callback': callback
      });
      return false;
    },
    zipCodeAutocomplete: function () {
      let ac = zipCodeService.autocomplete(this.user.zipCode);
      if (ac === null || ac === undefined) {
        return;
      }
      ac.then(city => this.user.city = city);
    },
    validateForm: function () {
      this.emailState = !validator.isEmptyAndRequired(this.user.email) && validator.validateEmail(this.user.email);
      this.prenameState = !validator.isEmptyAndRequired(this.user.prename);
      this.lastnameState = !validator.isEmptyAndRequired(this.user.lastname);
      this.cityState = !validator.isEmptyAndRequired(this.user.city);
      this.streetState = !validator.isEmptyAndRequired(this.user.street);
      this.zipCodeState = !validator.zipCode(this.user.zipCode);
      this.salutationState = this.user.salutation !== null;
      this.gdprState = this.gdpr;
      this.agbState = this.agb;
      this.firmState = this.user.salutation !== "FIRM" || !validator.isEmptyAndRequired(this.user.firm);
      this.ibanState = validator.isValidIBANNumber(this.user.bankingData.iban) || this.user.paymentType === "INVOICE";
      this.bicState = validator.isValidBicNumber(this.user.bankingData.bic) || this.user.paymentType === "INVOICE";


      return this.emailState && this.agbState && this.gdprState && this.lastnameState && this.prenameState
          && this.cityState && this.streetState && this.zipCodeState && this.salutationState && this.firmState && this.bicState && this.ibanState;
    },
    create() {
      if (!this.validateForm()) {
        /*this.$refs.signform.scrollTo({
          top: 0,
          behavior: 'smooth',
        });*/
        this.showWarning = true;
        return;
      }

      this.loading = true;
      HTTP.put("/users/book/" + this.jwt.userId, this.user)
          .then(() => {
            this.done = true;
            this.loading = false;
            AuthService.refresh();
            this.paid = true;
            this.$emit('refreshTestAccountState');
            this.gtag_report_conversion();
          }).catch(() => {
        this.error = true;
        this.loading = false;
      });
    }
  }
}
</script>
<style>

</style>