<template>
  <div class="container">
    <div class="content">
      <b-card header="Unteraccount anlegen">
        <b-form @submit.prevent="createSubUser">
          <b-form-group id="input-group-salutation" label="Anrede" label-for="salutation">
            <b-form-select v-model="selected" required :options="this.salutations" id="salutation">
            </b-form-select>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group id="input-group-prename" label="Vorname des Nutzers:" label-for="prename">
                <b-form-input type="text" id="prename" v-model="subuser.prename" required
                              placeholder="Vorname"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-lastname" label="Nachname des Nutzers:" label-for="lastname">
                <b-form-input type="text" id="lastname" v-model="subuser.lastname" required
                              placeholder="Nachname"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group v-if="this.$route.params.id === undefined || this.$route.params.id === null"
                            id="input-group-email" label="E-Mail Adresse des Nutzers:" label-for="email">
                <b-form-input type="email" id="email" v-model="subuser.email" required
                              placeholder="email"></b-form-input>
              </b-form-group>
              <b-form-group v-if="this.$route.params.id !== undefined && this.$route.params.id !== null"
                            id="input-group-email" label="E-Mail Adresse des Nutzers:" label-for="email">
                <b-form-input type="email" id="email" v-model="subuser.email" disabled
                              placeholder="email"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group v-if="this.$route.params.id === undefined || this.$route.params.id === null"
                            id="input-group-password" label="Passwort des Nutzers:" label-for="password">
                <b-form-input type="password" id="password" v-model="subuser.password" required
                              placeholder="Passwort"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Benutzerrechte">
            <b-form-checkbox-group id="checkbox-group-2" v-model="roles" name="flavour-2">
              <b-form-checkbox v-for="role in availableRoles" :key="role.key" :value="role.key">{{ role.value }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <button class="btn btn-primary float-right" type="submit">Benutzer speichern</button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>
import messageService from '@/services/message.service';
import {Salutations} from "@/models/static/salutations";
import CreateUser from "@/models/api/create-user";
import HTTP from "@/http";

export default {
  name: 'CreateSubUser',
  data() {
    return {
      subuser: new CreateUser(),
      selected: null,
      roles: [],
      availableRoles: [
        {
          key: "",
          value: "Gebäude"
        }
      ],
      salutations: Salutations.get(),
    }
  },
  created() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      HTTP.get("/sub-users/" + this.$route.params.id).then(result => {
        this.subuser = result.data;
        this.roles = this.subuser.roles;
        this.selected = this.subuser.salutation;
      });

    }
    HTTP.get("/roles").then(response => {
      let roleList = response.data;
      this.availableRoles = [];
      if (this.$route.params.id === undefined || this.$route.params.id === null) {
        this.roles = roleList;
      }
      roleList.forEach(it => {
        this.availableRoles.push({
          key: it,
          value: it
        });
      });
    });
  },
  methods: {
    createSubUser: function () {
      this.subuser.salutation = this.selected;
      this.subuser.roles = this.roles;
      if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
        HTTP.put("/sub-users/" + this.$route.params.id, this.subuser).then(() => {
          this.$router.push({name: "UserSetting"}).then(() => {
            messageService.success('Speichern erfolgreich', 'Unteraccount erfolgreich bearbeitet');
          });
        }).catch(() => {
          messageService.error('Speichern fehlgeschlagen', 'Beim Anlegen ist ein Fehler aufgetreten');
        });
      } else {
        HTTP.post("/sub-users", this.subuser).then(() => {
          this.$router.push({name: "UserSetting"}).then(() => {
            messageService.success('Speichern erfolgreich', 'Unteraccount erfolgreich angelegt');
          });
        }).catch(() => {
          messageService.error('Speichern fehlgeschlagen', 'Beim Anlegen ist ein Fehler aufgetreten');
        });
      }
    }
  }
}

</script>
<style></style>