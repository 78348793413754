import HTTP from "@/http";
import CreateFile from "@/models/api/create-file";

class FileService {
    upload(file) {
        return this.toBase64(file).then(base64 => {
            base64 = base64.split(",");
            let createFile = new CreateFile(file.name, base64[1]);
            return HTTP.post('/files', createFile);
        });
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

}

export default new FileService();