export default class Building {
    constructor(id, street, city, country, unitCount, rentingCount, repairCount) {
        this.id = id;
        this.street = street;
        this.city = city;
        this.country = country;
        this.unitCount = unitCount;
        this.rentingCount = rentingCount;
        this.repairCount = repairCount;
    }
}
