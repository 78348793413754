<template>
  <div class="container">
    <div class="content">
      <b-row>
        <b-col>
          <b-card header="Einheit erstellen">
            <b-form @submit.prevent="onSubmit" novalidate>
              <b-form-group>
                <InfoLabel tooltip="Wenn es sich um eine normale Wohnimmobilie handelt wählen Sie privat"
                           label="Wählen Sie aus um welchen Typ es sich handelt"/>
                <b-form-select :options="unitTypes" v-model="unit.type" :state="unitTypesState"/>
                <b-form-invalid-feedback>
                  Bitte wählen Sie einen Typ aus.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <InfoLabel tooltip="Tragen Sie hier den Namen der Einheit ein, bspw.: Wohnung 2, 1. Stock."
                           label="Geben Sie den Namen der Einheit ein"/>
                <b-form-input
                    v-model="unit.name"
                    :state="nameState"
                    type="text"
                    required
                    placeholder="bspw.: Wohnung 2, 1. Stock"
                />
                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <InfoLabel label="Allgemeiner Vermerk (optional)"
                           tooltip="Vermerk für Sie mit einer Kurzinformation (optional)"/>
                <b-form-textarea
                    v-model="unit.notation"
                    :state="notationState"
                    placeholder="Vermerk hier eintragen"
                />
                <b-form-invalid-feedback>
                  Bitte tragen Sie maximal 2000 Zeichen ein
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Quadratmeter der Einheit"
                               tooltip="Hier tragen Sie die Wohnfläche der Einheit ein, diese darf nicht größer als die Gebäudefläche sein."/>
                    <b-form-input
                        type="text"
                        :state="squareMetersState"
                        v-model="unit.squareMeters"
                        required
                        placeholder="Bspw.: 30"
                    />
                    <b-form-invalid-feedback>
                      Bitte tragen Sie eine Zahl ein die kleiner ist als die Gebäude QM
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Anzahl Räume (optional)"
                               tooltip="Hier tragen Sie die Anzahl der Räume in der Einheit ein."/>
                    <b-form-input
                        :state="roomsState"
                        v-model="unit.rooms"
                        type="text"
                        placeholder="Bspw.: 3"
                    ></b-form-input>

                    <b-form-invalid-feedback>
                      Bitte tragen Sie eine Zahl ein
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <InfoLabel label="Stockwerk (optional)"
                               tooltip="Hier tragen Sie ein, in welchem Stockwerk sich die Einheit befindet."/>
                    <b-form-input
                        v-model="unit.floor"
                        :state="floorState"
                        type="text"
                        placeholder="Bspw.: 1"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Bitte tragen Sie eine Zahl ein
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-button type="submit" class="float-right" v-if="this.$route.params.id === undefined" variant="primary"
                        id="next-step-save"
                        style="margin-left: 4px">Einheit speichern und Mietverhältnis anlegen
              </b-button>
              <b-button type="submit" class="float-right" variant="secondary" id="no-step-save">Einheit speichern
              </b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import messageService from '@/services/message.service';
import {UnitTypes} from "@/models/static/unit-types";
import {germanFormatter} from "@/services/formatter";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateUnit',
  components: {InfoLabel},
  data() {
    return {
      unitTypes: UnitTypes.get(),
      unit: {
        type: null,
        buildingId: this.$route.params.buildingId
      },
      building: null,
      nameState: null,
      roomsState: null,
      floorState: null,
      squareMetersState: null,
      unitTypesState: null,
      notationState: null
    }
  },
  created() {
    if (this.$route.params.buildingId !== undefined) {
      HTTP.get("/buildings/" + this.$route.params.buildingId).then(response => this.building = response.data);
    }

    if (this.$route.params.id !== undefined) {
      HTTP.get("/units/" + this.$route.params.id).then(response => {
        this.unit = response.data;
        HTTP.get("/buildings/" + this.unit.buildingId).then(response => this.building = response.data);
      });
    }
  },
  methods: {
    validateForm() {
      let isValid = true;

      if (this.unit.name == null || this.unit.name.length < 3) {
        isValid = false;
        this.nameState = false;
      } else {
        this.nameState = true;
      }

      if (this.unit.notation !== undefined && (this.unit.notation !== null && this.unit.notation !== "" && this.unit.notation.length > 2000)) {
        isValid = false;
        this.notationState = false;
      } else {
        this.notationState = true;
      }

      if (this.unit.type === null || this.unit.type === undefined) {
        isValid = false;
        this.unitTypesState = false;
      } else {
        this.unitTypesState = true;
      }


      this.unit.squareMeters = germanFormatter.parseNumber(this.unit.squareMeters);

      if (isNaN(this.unit.squareMeters) || this.unit.squareMeters == null || (this.building.livingSquareMeters !== null && this.building.livingSquareMeters < this.unit.squareMeters)) {
        isValid = false;
        this.squareMetersState = false;
      } else {
        this.squareMetersState = true;
      }

      this.unit.rooms = germanFormatter.parseNumber(this.unit.rooms);

      if (isNaN(this.unit.rooms) || !Number.isInteger(parseInt(this.unit.rooms))) {
        isValid = false;
        this.roomsState = false;
      } else {
        this.roomsState = true;
      }

      this.unit.floor = germanFormatter.parseNumber(this.unit.floor);

      if (isNaN(this.unit.floor) || !Number.isInteger(parseInt(this.unit.floor))) {
        isValid = false;
        this.floorState = false;
      } else {
        this.floorState = true;
      }

      return isValid;
    },
    onSubmit: function (event) {
      if (!this.validateForm()) {
        return;
      }

      if (this.$route.params.id !== undefined) {
        HTTP.put("/units/" + this.$route.params.id, this.unit).then(() => {
          this.$router.push({name: "ShowBuilding", params: {id: this.$route.params.buildingId}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Einheit erfolgreich bearbeitet');
          });
        });
      } else {
        HTTP.post("/units", this.unit).then(response => {
          let route = {name: "ShowUnit", params: {id: response.data.id}};
          if (event.submitter.id === "next-step-save") {
            route = {name: 'CreateRenting', params: {unitId: response.data.id}};
          }
          this.$router.push(route).then(() => {
            messageService.success('Speichern erfolgreich', 'Einheit erfolgreich erstellt');
          });
        });
      }
    }
  }
}
</script>
<style>

</style>