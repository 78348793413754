<template>
  <div class="wrapper">
    <b-card class="form-signin" no-body>
      <b-card-header style="background: #435c75; border-radius: 0 !important;">
        <img src="/immolert-icon-invers.png"
             style="max-width:190px; margin: auto; display: block; margin-bottom: 10px"/>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent="handleLogin">
          <b-form-group id="input-group-3" label="E-Mail Adresse:" label-for="inputEmail">
            <b-form-input type="email" id="inputEmail" v-model="user.username"
                          placeholder="E-Mail Adresse"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-4" label="Passwort:" label-for="inputPassword">
            <b-form-input type="password" id="inputPassword" v-model="user.password"
                          placeholder="Passwort"></b-form-input>
          </b-form-group>
          <b-alert variant="danger" v-if="message!=null" show>{{ this.message }}</b-alert>
          <b-button class="btn btn-lg btn-primary btn-block" type="submit">Einloggen</b-button>
          <br/>
          <router-link :to="{name:'Register'}" class="float-left" style="font-size: 12px">Jetzt registrieren
          </router-link>
          <router-link :to="{name:'ResetPassword'}" class="float-right" style="font-size: 12px">Passwort vergessen?
          </router-link>
          <br/>
        </b-form>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import User from '../../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          localStorage.removeItem("refreshToken");
          this.$store.dispatch('auth/login', this.user).then(
              () => {
                this.$emit('refreshTestAccountState');
                this.$router.push({name: "Start"});
              }).catch(
              error => {
                this.loading = false;

                if (error.response && error.response.status === 401) {
                  this.message = "Die eingegebenen Logindaten sind falsch";
                } else {
                  if (error.response.data.error !== undefined && error.response.data.error !== null && error.response.data.error === "invalid_grant") {
                    this.message = "Ihr Account ist deaktiviert, bitte melden Sie sich beim Support.";
                  } else {
                    this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                  }
                }

              }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  background-image: linear-gradient(#435c75, #6288ac);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  margin-top: 100px;
  border: 1px solid #3b5269;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
