<template>
  <b-container>
    <div class="content">
      <b-card header="Reparatur anlegen">
        <b-form @submit="save()" novalidate>
          <b-row>
            <b-col lg="6" sm="12">
              <b-form-group>
                <InfoLabel label="Gebäude auswählen"
                           tooltip="Wählen Sie das Gebäude aus, andem die Reparatur stattfand"/>
                <b-form-select :options="buildings" v-model="repair.buildingId" @change="loadUnits()"
                               :state="buildingState"/>
                <b-form-invalid-feedback>{{ validator.noSelectionMessage() }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group>
                <InfoLabel label="Einheit auswählen"
                           tooltip="Wählen Sie die Einheit aus, andem die Reparatur stattfand"/>
                <b-form-select :options="units" v-model="repair.unitId" :state="unitState"/>
                <b-form-invalid-feedback>{{ validator.noSelectionMessage() }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Datum der Rechnung" tooltip="Wann wurde die Rechnung für diese Reparatur erstellt"/>
                <b-form-datepicker v-model="repair.execution" :state="executedState" :value-as-date="true" locale="de"/>
                <b-form-invalid-feedback :state="amountState">Bitte ein korrektes Datum eintragen
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group v-if="this.loadingPicture">
                <InfoLabel label="Bild wird hochgeladen" tooltip="Das Belegbild wird aktuell hochgeladen"/>
                <div class="text-center">
                  <b-icon animation="cylon" icon="three-dots"></b-icon>
                </div>
              </b-form-group>
              <b-form-group v-if="this.pictureId == null && !this.loadingPicture">
                <InfoLabel label="Bild hochladen" tooltip="Laden Sie das Belegbild hier hoch"/>
                <b-form-file
                    placeholder="Datei hochladen (PDF, JPEG oder PNG)"
                    v-on:change="this.uploadImage"
                ></b-form-file>
              </b-form-group>
              <b-form-group v-if="this.pictureId != null && !this.loadingPicture">
                <InfoLabel label="Beleg bereits hochgeladen"
                           tooltip="Es wurde ein Beleg von Ihnen hinterlegt, Sie können diesen entfernen und einen neuen hochladen."/>
                <br/>
                <b-button
                    variant="secondary"
                    v-on:click="this.resetImage"
                >Neue Datei hochladen
                </b-button>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Kosten für die Reparatur"
                           tooltip="Tragen Sie hier die für die Reparatur entstandenen Kosten ein."/>
                <b-input-group append="€">
                  <b-form-input v-model="repair.amount" :state="amountState" placeholder="Bspw. 300"
                                lazy-formatter :formatter="formatter.formatNumber"/>
                </b-input-group>
                <b-form-invalid-feedback :state="amountState">{{
                    validator.noCorrectNumberMessage()
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <InfoLabel label="Lieferanten / Dienstleister auswählen"
                       tooltip="Welcher Dienstleister hat die Arbeiten durchgeführt?"/>
            <b-form-select :options="suppliers" v-model="repair.supplierId"></b-form-select>
          </b-form-group>
          <b-form-group>
            <InfoLabel label="Beschreibung der durchgeführten Arbeiten"
                       tooltip="Hier können Sie sich Notizen machen, wie zum Beispiel: Max Mustermann hatte eine defekte Heizung und Michael Meister musste diese erneuern."/>
            <b-form-textarea rows="5" :state="descriptionState" v-model="repair.description"
                             placeholder="Beispielsweise: Reparatur der Klingel & Freisprechanlage"/>
            <b-form-invalid-feedback :state="descriptionState">{{
                validator.toShortMessage()
              }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button type="submit" variant="primary" class="float-right">Reparatur speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import messageService from '@/services/message.service';
import validator from '@/services/validator.service';
import {germanFormatter} from '@/services/formatter';
import FileService from "@/services/file.service";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateRepair',
  components: {InfoLabel},
  data() {
    return {
      formatter: germanFormatter,
      descriptionState: null,
      loadingPicture: false,
      validator: validator,
      executedState: null,
      amountState: null,
      pictureId: null,
      suppliers: null,
      buildings: null,
      units: null,
      buildingId: this.$route.params.buildingId,
      repair: {
        buildingId: this.$route.params.buildingId === null || this.$route.params.buildingId === undefined ? null : this.$route.params.buildingId,
        supplierId: null,
        unitId: null,
      },
      buildingState: null,
      unitState: null
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      HTTP.get("/repairs/" + this.$route.params.id).then(response => {
        this.repair = response.data;
        this.pictureId = this.repair.receiptId;
        this.loadSuppliers();
        this.loadBuildings();
        this.loadUnits();
      });
    } else {
      this.loadSuppliers();
      this.loadBuildings();
      this.loadUnits();
    }

  },
  methods: {
    loadBuildings: function () {
      HTTP.get("/buildings?size=999").then(response => {
        this.buildings = [{
          value: null,
          text: "Bitte Auswahl treffen"
        }];

        response.data.content.forEach(item => {
          this.buildings.push({
            value: item.id,
            text: item.city + " " + item.street
          });
        });
      });
    },
    loadUnits: function () {
      if (this === null || this.repair.buildingId === undefined || this.repair.buildingId === null) {
        return;
      }

      HTTP.get("/units?buildingId=" + this.repair.buildingId + "&size=999").then(response => {
        this.units = [{
          value: null,
          text: "Bitte Auswahl treffen"
        }];
        response.data.content.forEach(item => {
          this.units.push({
            value: item.id,
            text: item.name
          });
        });
      });
    },
    uploadImage: function (item) {
      this.loadingPicture = true;
      FileService.upload(item.target.files[0]).then(resp => {
        this.pictureId = resp.data.id;
        this.loadingPicture = false;
        messageService.success('Speichern erfolgreich', 'Datei erfolgreich hochgeladen');
      }).catch(() => {
        this.pictureId = null;
        this.loadingPicture = false;
        messageService.error('Speichern fehlgeschlagen', 'Datei konnte nicht hochgeladen werden');
      });
    },
    resetImage: function () {
      this.pictureId = null;
      HTTP.delete('/files/' + this.pictureId);
    },
    validateForm: function () {
      this.amountState = !validator.correctNumberRequired(this.repair.amount);
      this.executedState = !validator.isEmptyAndRequired(this.repair.execution);
      this.descriptionState = !validator.isEmptyAndRequired(this.repair.description);
      this.buildingState = !validator.nullSelect(this.repair.buildingId);

      return this.amountState && this.executedState && this.descriptionState && this.buildingState;
    },
    loadSuppliers: function () {
      HTTP.get("/suppliers?size=999").then(response => {
        this.suppliers = [{
          value: null,
          text: 'Bitte Auswahl treffen'
        }];

        response.data.content.forEach(item => {
          this.suppliers.push({
            value: item.id,
            text: item.prename + " " + item.lastname
          });
        });
      });
    },
    save: function () {
      if (!this.validateForm()) {
        return;
      }

      this.repair.receiptId = this.pictureId;
      if (this.repair.id === null || this.repair.id === undefined) {
        HTTP.post("/repairs", this.repair).then(() => {
          this.$router.push({name: 'ShowBuildingTab', params: {id: this.repair.buildingId, activeTab: 'repairs'}})
              .then(() => messageService.success("Speichern erfolgreich", "Reparatur erfolgreich gespeichert."));
        });
      } else {
        HTTP.put("/repairs/" + this.repair.id, this.repair).then(() => {
          this.$router.push({name: 'ShowBuildingTab', params: {id: this.repair.buildingId, activeTab: 'repairs'}})
              .then(() => messageService.success("Speichern erfolgreich", "Reparatur erfolgreich bearbeitet."));
        });
      }
    }
  }
}
</script>
<style></style>