<template>
  <b-container>
    <div class="content">
      <b-card header="Details zur Reparatur">
        <b-row>
          <b-col lg="5" sm="12">
            <b-table :items="tableRows" :fields="fields" head-row-variant="none" sort-by="name"
                     thead-class="hide-header" sort-desc>
              <template #cell(value)="data">
                <router-link :to="data.item.route" v-if="data.item.route !== undefined && data.item.route !== null">
                  {{ data.item.value }}
                </router-link>
                <span v-if="data.item.route === undefined || data.item.route === null">{{ data.item.value }}</span>
              </template>
            </b-table>
          </b-col>
          <b-col lg="7" sm="12">
            <b>Beschreibung der durchgeführten Tätigkeit:</b> <br/>
            <hr>
            <div v-if="repair !== undefined && repair !== null">{{ repair.description }}</div>
          </b-col>
        </b-row>
      </b-card>
      <br>
      <b-card header="Inhalt des Belegs">
        <FilePreview :file-id="repair.receiptId" v-if="repair !== undefined && repair !== null"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";
import moment from "moment";
import FilePreview from "@/components/FilePreview";

export default {
  name: 'ShowRepair',
  components: {FilePreview},
  data() {
    return {
      repair: null,
      supplier: null,
      building: null,
      unit: null,
      base64: null,
      loading: true,
      fields: [{
        key: 'name',
        label: ''
      }, {
        key: 'value',
        label: ''
      }],
      tableRows: []
    }
  },
  created() {
    HTTP.get("/repairs/" + this.$route.params.id).then(response => {
      this.repair = response.data;

      this.tableRows.push({
        name: 'Endstandene Kosten',
        value: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(this.repair.amount)
      });
      this.tableRows.push({
        name: 'Durchgeführt am',
        value: moment(String(this.repair.execution)).format('DD.MM.YYYY')
      });

      HTTP.get("/buildings/" + this.repair.buildingId).then(response => {
        this.building = response.data
        this.tableRows.push({
          name: 'Gebäude',
          value: this.building.city + " " + this.building.street,
          route: {name: 'ShowBuilding', params: {id: this.building.id}}
        });
      });
      if (this.repair.supplierId !== null) {
        HTTP.get("/suppliers/" + this.repair.supplierId).then(response => {
          this.supplier = response.data
          this.tableRows.push({
            name: 'Dienstleister',
            value: this.supplier.prename + " " + this.supplier.lastname,
            route: {name: 'ShowSupplier', params: {id: this.supplier.id}}
          });
        });
      }

      if (this.repair.unitId !== null) {
        HTTP.get("/units/" + this.repair.unitId).then(response => {
          this.unit = response.data
          this.tableRows.push({
            name: 'Einheit',
            value: this.unit.name,
            route: {name: 'ShowUnit', params: {id: this.unit.id}}
          });
        });
      }
    });
  },
  methods: {}
}
</script>
<style scoped>

.hide-header {
  display: none;
}
</style>