export default class CreateUser {
    constructor(salutation, prename, lastname, email, clearPassword) {
        this.salutation = salutation;
        this.prename = prename;
        this.lastname = lastname;
        this.email = email;
        this.clearPassword = clearPassword;
        this.contactInformation = {};
    }
}
