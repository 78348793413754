<template>
  <div class="container">
    <div class="content">
      <b-row>
        <b-col>
          <div class="card">
            <div class="card-header">Mieterdatenblatt</div>
            <div class="card-body" v-if="this.renter != null">
              <b-row>
                <b-col>
                  {{ this.renter.salutation |germanSalutation }}<br>
                  {{ this.renter.prename }} {{ this.renter.lastname }}<br>
                  {{ this.renter.street }}<br>
                  {{ this.renter.zipCode }} {{ this.renter.city }}<br><br>
                </b-col>
                <b-col>
                    <span v-if="this.renter.created != null"><b>Erstellt am:</b> <br>{{
                        this.renter.created | germanDateTime
                      }}</span><br/>
                  <span v-if="this.renter.lastUpdate != null"><b>Letzte Änderung:</b> <br>{{
                      this.renter.lastUpdate
                    }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col>
          <ContactInfo :info="renter.contactInformation" v-if="renter !== null && renter.contactInformation !== null"/>
        </b-col>
      </b-row>
      <br>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Aktuelle Mietverhältnisse">
            <b-card-text v-if="this.rentings == null">
              <b-skeleton-table/>
            </b-card-text>
            <b-card-text v-if="this.rentings != null && this.rentings.totalElements > 0">
              <b-table striped hover :no-local-sorting="true" v-on:sort-changed="load" :items="rentings.content"
                       responsive
                       class="card-table"
                       :fields="fields">
                <template #cell(start)="data">
                  {{ data.item.start | germanDate }}
                </template>
                <template #cell(actions)="data">
                  <router-link :to="{name:'ShowRenting', params: {'id':data.item.id}}"
                               class="btn btn-primary btn-sm" v-b-tooltip.hover.left="'Mietverhältnis im Detail anzeigen'">
                    <b-icon icon="eye" font-scale="1"></b-icon>
                  </router-link>&nbsp;&nbsp;
                </template>
              </b-table>
              <PageableTable v-bind:pageable="this.rentings" v-bind:toRoute="'ShowRenter'"/>
            </b-card-text>
            <b-card-text class="text-center" v-if="this.rentings != null && this.rentings.totalElements === 0">
              <br/>
              <br/>
              Aktuell bestehen keine Mietverhältnisse mit diesem Mieter<br/>
              <br/>
            </b-card-text>
          </b-tab>
          <b-tab title="Archivierte Mietverhältnisse">
            <b-card-text v-if="this.archivedRentings == null">
              <b-skeleton-table/>
            </b-card-text>
            <b-card-text v-if="this.archivedRentings != null && this.archivedRentings.totalElements > 0">
              <b-table striped hover responsive
                       class="card-table"
                       :no-local-sorting="true"
                       v-on:sort-changed="load"
                       :items="archivedRentings.content"
                       :fields="archivedFields">
                <template #cell(end)="data">
                  {{ data.item.end | germanDate }}
                </template>
                <template #cell(duration)="data">
                  {{ data.item.duration }} Tage
                </template>
                <template #cell(actions)="data">
                  <router-link :to="{name:'ShowRenting', params: {'id':data.item.id}}"
                               class="btn btn-primary btn-sm" v-b-tooltip.hover.left="'Mietverhältnis im Detail anzeigen'">
                    <b-icon icon="eye" font-scale="1"></b-icon>
                  </router-link>&nbsp;&nbsp;
                </template>
              </b-table>
              <PageableTable v-bind:pageable="archivedRentings" v-bind:toRoute="'ShowRenter'"/>
            </b-card-text>
            <b-card-text class="text-center"
                         v-if="this.archivedRentings != null && this.archivedRentings.totalElements === 0"><br/>
              <br/>
              Aktuell bestehen keine archivierten Mietverhältnisse mit diesem Mieter<br/>
              <br/>
            </b-card-text>
          </b-tab>
          <b-tab title="Dokumente">
            <b-card-text>
              <DocumentFileList entity-type="renter" :entity-id="this.$route.params.id"/>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      <br>
      <Comment :entity-type="'renter'" :entity-id="this.$route.params.id"/>
    </div>
  </div>
</template>
<script>
import HTTP from "@/http";
import PageableTable from "@/components/PageableTable";
import Comment from "@/components/Comment";
import ContactInfo from "@/components/ContactInfo";
import DocumentFileList from "@/components/DocumentFileList";

export default {
  name: 'ShowRenter',
  components: {
    DocumentFileList,
    ContactInfo,
    Comment,
    PageableTable
  },
  data() {
    return {
      "renter": {
        contactInformation: null
      },
      "rentings": null,
      currentPage: this.$route.params.page,
      sort: null,
      archivedRentings: null,
      "fields": [
        {
          key: "unitName",
          label: "Einheit"
        },
        {
          key: "buildingName",
          label: "Gebäude"
        },
        {
          key: "start",
          label: "Bestehend seit",
          class: 'sm-col',
        },
        {
          key: "actions",
          label: "Aktionen",
          class: 'sm-col'
        }
      ],
      "archivedFields": [
        {
          key: "unitName",
          label: "Einheit"
        },
        {
          key: "buildingName",
          label: "Gebäude"
        },
        {
          key: "end",
          label: "Beendet seit",
          class: 'sm-col',
        },
        {
          key: "duration",
          label: "Dauer",
          class: 'sm-col',
        },
        {
          key: "actions",
          label: "Aktionen",
          class: 'sm-col'
        }
      ]
    }
  },
  created() {
    this.load();
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.page = to.params.page;
        this.load();
      }
    }
  },
  methods: {
    load: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }
      let sortStr = "";
      if (sort !== undefined) {
        sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/renters/" + this.$route.params.id).then(resp => {
        this.renter = resp.data;
      });
      HTTP.get("/detail-rentings?archived=false&renterId=" + this.$route.params.id + sortStr + "&page=" + currentPage).then(resp => this.rentings = resp.data);
      HTTP.get("/detail-rentings?archived=true&renterId=" + this.$route.params.id + sortStr + "&page=" + currentPage).then(resp => this.archivedRentings = resp.data);
    }
  }
}
</script>

<style>
</style>