<template>
  <div class="add-tag" :style="expanded ? 'width:100px':''">
    <button class="tag-btn" @click="expand" v-if="!expanded">+</button>
    <input type="text" v-if="expanded" ref="tagInput" placeholder="..." @keyup="saveTag" v-model="name" id="tag-input">
  </div>
</template>
<script>
import HTTP from "../../http";
import messageService from "../../services/message.service";

export default {
  name: 'AddTag',
  props: ['entityId', 'entityType'],
  data() {
    return {
      name: null,
      expanded: false
    }
  },
  methods: {
    async expand() {
      this.expanded = true;
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
      })
    },
    cleanInput() {
      this.expanded = false;
      this.name = '';
    },
    saveTag(e) {
      if (e.which === 13) {
        if (this.name.length < 2) {
          return;
        }

        let tag = {
          name: this.name.trim(),
          entityId: this.$props.entityId,
          entityType: this.$props.entityType
        };

        HTTP.post("/tags", tag).then(() => {
          messageService.success('Tag hinzugefügt','Der Tag wurde erfolgreich hinterlegt');

          this.cleanInput();
          this.$emit("tagSaved");
        }).catch(error => {
          if(error.response.status === 409) {
            messageService.hint('Tag nicht angelegt','Der eingetragene Tag existiert bereits.');
            this.cleanInput();
          } else if(error.response.status === 507) {
            messageService.error('Limit erreicht','Es können maximal 20 Tags pro Datensatz hinterlegt werden.');
          }
        });
      }
    }
  }
}
</script>
<style scoped>

.add-tag {
  border: 1px dashed #999999;
  color: #435c75;
  padding: 4px 10px 4px 10px;
  border-radius: 6px;
  margin-top: 4px;
  width: 40px;
  text-align: center;
  display: inline-block;
}

.tag-btn {
  background: none;
  border: 0;
  color: #999;
  font-weight: bold;
  font-size: 14px;
}

.tag-btn:hover {
  color: #000;
}

#tag-input {
  border: 0 !important;
  background: none;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

#tag-input:focus {
  border: 0 !important;
}
</style>