<template>
  <b-container>
    <div class="content">
      <b-card header="Support">
        <p>Sie brauchen Hilfe bei der Bedienung, haben Feedback oder Kritik wir freuen uns auf Ihre Nachricht. <br/>Gerne
          können Sie werktags zwischen
          10:00 - 12:00 & 13:00 - 17:00 sich bei uns telefonisch melden: 0 22 26 – 908 680 7.</p>
        <hr>
        <b-form novalidate @submit="send">
          <b-form-group label="Ihr Anliegen">
            <b-input type="text" v-model="subject" :state="subjectState" placeholder="Bitte tragen Sie hier den Betreff ein"/>
            <b-form-invalid-feedback>
              {{ validator.toShortMessage() }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Ihre Nachricht an uns">
            <b-form-textarea rows="15" v-model="message"
                             :state="messageState"
                             placeholder="Bitte tragen Sie hier in einer Nachricht ein, was Sie sich von uns wünschen."/>
            <b-form-invalid-feedback>
              {{ validator.toShortMessage() }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button type="submit" variant="primary" class="float-right">Jetzt absenden</b-button>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";
import messageService from "@/services/message.service";
import validator from "@/services/validator.service";

export default {
  name: 'Support',
  data() {
    return {
      subject: null,
      message: null,
      subjectState: null,
      messageState: null,
      validator : validator
    }
  },
  methods: {
    validateForm: function () {
      this.subjectState = !validator.isEmptyAndRequired(this.subject);
      this.messageState = !validator.isEmptyAndRequired(this.message);

      return this.subjectState && this.messageState;
    },
    send: function () {
      if (!this.validateForm()) {
        return;
      }

      HTTP.post("/messages", {subject: this.subject, message: this.message}).then(() => {
        messageService.success('Nachricht erfolreich versendet', 'Vielen Dank Ihre Nachricht ist bei uns eingetroffen, ein Mitarbeiter wird sich in Kürze bei Ihnen melden.');
      });
    }
  }
}
</script>
<style>

</style>