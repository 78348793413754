export class UnitTypes {
    static get() {
        return [
            {
                value: null,
                text: 'Bitte treffen Sie eine Auswahl'
            }, {
                value: 'PRIVATE',
                text: 'Privat'
            }, {
                value: 'BUSINESS',
                text: 'Gewerbe'
            }, {
                value: 'OTHER',
                text: 'Sonstiges'
            }
        ];
    }

    static germanType(type) {
        switch (type) {
            case 'PRIVATE':
                return 'Privat';
            case 'BUSINESS':
                return 'Gewerbe';
            case 'OTHER':
                return 'Sonstiges';
        }
    }
}

