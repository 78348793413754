export class BillingOfIncidentalsStates {
    static getStateLocalized(STATE) {
        switch (STATE) {
            case "IN_WORK":
                return "In Arbeit";
            case "SENT":
                return "Versendet";
            case "DUNNING":
                return "Überfällig";
            case "PAID":
                return "Bezahlt";
            case "ISSUES":
                return "Problemfall";
        }
    }

    static getStateBadgeVariant(STATE) {
        switch (STATE) {
            case "IN_WORK":
                return "primary";
            case "SENT":
                return "success";
            case "DUNNING":
                return "warning";
            case "PAID":
                return "success";
            case "ISSUES":
                return "danger";
        }
    }
}