<template>
  <div>
    <Loading v-if="loading"/>
    <b-button class="float-right" v-if="!loading" style="margin-bottom: 5px"  @click.prevent="download" variant="success" v-b-tooltip="'Klicken Sie hier um die Datei herunterzuladen'"><b-icon icon="download"/></b-button>
    <iframe :src="fileData" :id="id" class="dataFrame" v-if="!loading"/>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import HTTP from "@/http";

export default {
  components: {Loading},
  name: 'FilePreview',
  props: ['fileId'],
  data() {
    return {
      loading: true,
      fileData: null,
      id: this._uid
    }
  },
  created() {
    this.loadFile();
  },
  watch: {
    fileId: function (to, from) {
      if (from == null || to !== from) {
        this.loadFile();
      }
    }
  },
  methods: {
    download: function (){
      this.loading = true;
      HTTP.get("/files/" + this.fileId).then(response => {
        let file = response.data;
        HTTP.get("/files/download/" + file.id, {responseType: 'blob'})
            .then(response => {
              const blob = new Blob([response.data], {type: file.mimeType})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = file.originalName
              link.click()
              URL.revokeObjectURL(link.href)
            }).finally(() => this.loading = false);
      }).catch(() => this.loading = false)
    },
    loadFile: function () {
      if(this.fileId === undefined || this.fileId === null) {
        return;
      }

      HTTP.get("/files/data/" + this.fileId).then(resp => {
        this.fileData = resp.data;
      }).finally(()=>this.loading=false);
    }
  }
}
</script>
<style scoped>

.dataFrame {
  width: 100%;
  min-height: 400px;
}
</style>