<template>
  <b-card header="Bemerkungen / Notizen">
    <div v-if="comments == null || comments.totalElements == 0" class="text-center">
      Noch keine Bemerkungen / Notizen erstellt
    </div>

    <b-skeleton-table v-if="comments == null"/>
    <div v-if="comments != null && comments.totalElements > 0">
      <div v-for="comment in comments.content" :key="comment.id" class="card comment">
        <div class="card-header">
          <b-row>
            <b-col>
              <b-avatar variant="primary" size="2em" :text="comment.userShortage"></b-avatar>
              {{ comment.userString }}
            </b-col>
            <b-col class="text-right">
              {{ comment.created | germanDateTime }}
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          {{ comment.comment }}
        </div>
        <div class="card-footer" v-if="myComment(comment)">
          <b-button variant="danger" size="sm" class="float-right"
                    v-b-tooltip="'Kommentar löschen'"
                    @click.prevent="openDeleteComment(comment.id)">
            <b-icon icon="trash"/>
          </b-button>
          <b-button variant="primary" size="sm" class="float-right" style="margin-right: 3px"
                    @click.prevent="editComment(comment)"
                    v-b-tooltip="'Kommentar bearbeiten'">
            <b-icon icon="pencil"/>
          </b-button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <hr>
    <RouterlessPageable v-if="comments != null && comments.totalElements > 0" :pageable="comments"
                        v-on:change="loadComments"/>

    <br>
    <b-card header="Bemerkung / Notiz verfassen">
      <b-form @submit.prevent="saveComment">
        <b-form-textarea placeholder="Trage hier deinen Text ein..." :state="isValid" rows="5" id="newComment"
                         v-model="newComment"></b-form-textarea>
        <b-form-invalid-feedback>
          Bitte tragen Sie einen Kommentar ein
        </b-form-invalid-feedback>
        <br>
        <b-button type="submit" variant="primary" class="float-right">Notiz speichern</b-button>
      </b-form>
    </b-card>
    <b-modal id="delete-comment-modal" title="Kommentar löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen" @ok="deleteComment()">Möchten Sie den ausgewählten Kommentar wirklich löschen?
    </b-modal>

  </b-card>
</template>
<script>
import HTTP from "@/http";
import RouterlessPageable from "@/components/RouterlessPageable";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: 'Comment',
  components: {RouterlessPageable},
  props: ['entityId', 'entityType'],
  data() {
    return {
      comments: null,
      newComment: null,
      editId: null,
      jwt: null,
      deleteId: null,
      isValid: null
    }
  },
  created() {
    this.loadComments(0);
    if (localStorage.getItem("token")) {
      this.jwt = VueJwtDecode.decode(localStorage.getItem("token"));
    }
  },
  methods: {
    loadComments: function (page) {
      HTTP.get("/comments?sort=id,desc&entityId=" + this.entityId + "&entityType=" + this.entityType + "&page=" + page).then(response => this.comments = response.data);
    },
    validateForm: function () {
      if (this.newComment === undefined || this.newComment === null || this.newComment === "") {
        this.isValid = false;
        return false;
      }
      this.isValid = true;
      return true;
    },
    saveComment: function () {
      if (!this.validateForm()) {
        return;
      }

      if (this.editId != null) {
        HTTP.put("/comments/" + this.editId, {
          comment: this.newComment,
          referenceId: this.entityId,
          entity: this.entityType
        }).then(() => {
          this.loadComments(0);
          this.$bvToast.toast('Bemerkung / Notiz erfolgreich gespeichert', {
            title: 'Speichern erfolgreich',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: false
          });

          this.editId = null;
          this.newComment = null;
        });
      } else {
        HTTP.post("/comments", {
          comment: this.newComment,
          referenceId: this.entityId,
          entity: this.entityType
        }).then(() => {
          this.loadComments(0);
          this.$bvToast.toast('Bemerkung / Notiz erfolgreich gespeichert', {
            title: 'Speichern erfolgreich',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: false
          });
          this.newComment = null;
        });
      }

    },
    editComment: function (comment) {
      this.newComment = comment.comment;
      this.editId = comment.id;
      let commentField = this.$el.querySelector('#newComment');
      commentField.focus();
    },
    openDeleteComment: function (commentId) {
      this.deleteId = commentId;
      this.$bvModal.show("delete-comment-modal");
    },
    deleteComment: function () {
      HTTP.delete("/comments/" + this.deleteId).then(() => {
        this.loadComments(0);
        this.$bvToast.toast('Bemerkung / Notiz erfolgreich gelöscht', {
          title: 'Löschen erfolgreich',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: false
        });
      });
    },
    myComment: function (comment) {
      if (comment == null) {
        return false;
      }

      return this.jwt.userId == comment.userId;
    }
  }
}
</script>
<style scoped>
.comment {
  width: 50%;
  margin: 20px;
}

.comment:nth-child(even) {
  float: right;
  clear: both;
  margin-right: 0;
}

.comment:nth-child(odd) {
  float: left;
  clear: both;
  margin-left: 0;
}
</style>