<template>
  <div class="container">
    <div class="content">
      <div class="card">
        <div class="card-header">Gebäudeübersicht</div>
        <div class="card-body">
          <b-skeleton-table v-if="pageable == null"/>
          <NoResultsCreateSuggest v-if="pageable!=null &&  pageable.totalElements === 0"
                                  text="Noch keine Gebäude angelegt" :route="{name:'CreateBuilding'}"/>
          <div v-if="pageable!=null && pageable.totalElements > 0">
            <router-link tag="a" :to="{name:'CreateBuilding'}"
                         class="btn btn-primary float-right"
                         v-b-tooltip="'Hier klicken um ein neues Gebäude anzulegen'">
              Weiteres Gebäude anlegen
            </router-link>
            <br/><br/>
            <b-table striped hover :no-local-sorting="true"
                     class="card-table"
                     v-on:sort-changed="load"
                     :items="items"
                     :fields="fields">
              <template #cell(actions)="data" class="sm-col">
                <b-button :to="{name:'ShowBuilding', params: {'id':data.item.id}}"
                          size="sm" variant="primary" v-b-tooltip="'Klicken Sie hier um sich das Gebäude anzeigen zu lassen.'">
                  <b-icon icon="eye" font-scale="1"></b-icon>
                </b-button>&nbsp;&nbsp;
                <b-button :to="{name:'CreateBuilding', params: {'id':data.item.id}}"
                          size="sm" variant="warning"
                          v-b-tooltip="'Klicken Sie hier um das Gebäude zu editieren.'">
                  <b-icon icon="pencil" font-scale="1"></b-icon>
                </b-button>&nbsp;&nbsp;
                <b-button size="sm" variant="danger" @click.prevent="deleteModal(data.item.id)"
                          v-b-tooltip="'Klicken Sie hier um das Gebäude zu löschen.'">
                  <b-icon icon="trash" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
            <PageableTable v-bind:pageable="pageable" v-bind:toRoute="'Start'"/>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="delete-modal" ok-variant="danger" title="Gebäude löschen?" cancel-title="Abbrechen"
             @ok="deleteBuilding()">Möchten Sie das
      ausgewählte Gebäude wirklich löschen?
    </b-modal>
  </div>
</template>

<script>
import HTTP from "@/http";
import PageableTable from "@/components/PageableTable";
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import messageService from '@/services/message.service';

export default {
  name: 'ListBuildings',
  data() {
    return {
      "pageable": null,
      "items": [],
      "fields": [
        {
          key: "street",
          label: "Straße",
          sortable: true
        },
        {
          key: "city",
          label: "Stadt",
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      "page": this.$route.params.page,
      deleteId: null
    }
  },
  created() {
    this.load();
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.page = to.params.page;
        this.load();
      }
    }
  },
  methods: {
    deleteModal: function (id) {
      this.deleteId = id;
      this.$bvModal.show("delete-modal");
    },
    deleteBuilding: function () {
      HTTP.delete("/buildings/" + this.deleteId).then(() => {
        this.$bvToast.toast('Gebäude erfolgreich gelöscht', {
          title: 'Löschen erfolgreich',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: false
        })
        this.load();
      }).catch(error => {
        if(error.response.status === 412) {
          messageService.hint("Löschen fehlgeschlagen", "Das Gebäude kann nicht gelöscht werden, es sind Einheiten im Gebäude angelegt.");
        }
      });
    },
    load: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }
      let sortStr = "";
      if (sort !== undefined) {
        sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/buildings?page=" + currentPage + sortStr).then(resp => {
        this.pageable = resp.data;
        this.items = this.pageable.content;
      });
    }
  },
  components: {
    PageableTable, NoResultsCreateSuggest
  }
}
</script>

<style>

</style>