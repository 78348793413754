<template>
  <div class="wrapper" ref="signform">
    <b-card class="form-signin" style="padding: 0;" v-if="!done && !loading" no-body>
      <b-card-body style="padding: 0; margin: 0;">
        <b-row style="margin: -1px; padding: 0;">
          <b-col md="12" lg="4" style="padding:8px; background: #435c75;  border:1px solid #7296b9;">
            <img src="/immolert-icon-invers.png"
                 style="max-width:190px; margin: auto; margin-top:20px; margin-bottom:40px; display: block;"/>
            <hr/>
            <ul class="ok-list">
              <li>Mietverwaltung</li>
              <li>Nebenkostenabrechnungen</li>
              <li>Dokumentenverwaltung</li>
              <li>Gebäudeübersicht</li>
              <li>Gargen / Stellplätze</li>
              <li>Notizen & Bemerkungen</li>
              <li>Stammdatenverwaltung</li>
              <li>Mahnwesen</li>
              <li>14 Tage kostenlose Testversion</li>
            </ul>
          </b-col>
          <b-col>
            <b-alert variant="warning" v-model="showWarning" dismissible>
              Bitte füllen Sie alle Felder korrekt aus.
            </b-alert>
            <b-form  style="padding:8px; margin-right:8px; margin-top: 20px; border-radius: 0 !important;" @submit.prevent="create()" novalidate>
              <b-row>
                <b-col sm="3" cols="12">
                  <b-form-group id="input-group-salutation" label="Anrede" label-for="salutation">
                    <b-form-select v-model="user.salutation" :options="this.salutations" :state="salutationState"
                                   id="salutation">
                    </b-form-select>
                    <b-form-invalid-feedback>Bitte eine Anrede auswählen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="4" cols="12">
                  <b-form-group label="Ihr Vorname:" v-b-tooltip="'Tragen Sie hier Ihren Vornamen ein'">
                    <b-form-input type="text"
                                  :state="prenameState"
                                  v-model="user.prename"
                                  placeholder="Ihr Vorname"></b-form-input>
                    <b-form-invalid-feedback>Bitte einen korrekten Vornamen ein</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="4" cols="12">
                  <b-form-group label="Ihr Nachname:" v-b-tooltip="'Tragen Sie hier Ihren Nachnamen ein'">
                    <b-form-input type="text"
                                  :state="lastnameState"
                                  v-model="user.lastname"
                                  placeholder="Ihr Nachname"></b-form-input>
                    <b-form-invalid-feedback>Bitte einen korrekten Nachnamen ein</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="user.salutation === 'FIRM'">
                <b-col>
                  <b-form-group label="Firmenname:" v-b-tooltip="'Tragen Sie hier Ihren Firmennamen ein'">
                    <b-form-input type="text"
                                  :state="firmState"
                                  v-model="user.firm"
                                  placeholder="Ihr Firmenname"></b-form-input>
                    <b-form-invalid-feedback>Bitte einen korrekten Firmennamen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Ihre E-Mail Adresse:" v-b-tooltip="'Tragen Sie hier Ihre E-Mail Adresse ein'">
                <b-form-input type="email"
                              :state="emailState"
                              v-model="user.email"
                              placeholder="E-Mail Adresse"></b-form-input>
                <b-form-invalid-feedback>Bitte eine korrekte E-Mail Adresse eintragen</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Ihr Wunschpasswort:">
                <b-form-input type="password"
                              v-b-tooltip="'Tragen Sie hier Ihre sicheres Passwort mit mindestens 8 Zeichen ein'"
                              v-model="user.clearPassword"
                              :state="passwordState"
                              placeholder="Ein sicheres Passwort"></b-form-input>
                <b-form-invalid-feedback>Bitte ein Passwort mit mindestens 8 Zeichen verwenden</b-form-invalid-feedback>
              </b-form-group>
              <b-form-checkbox :state="agbState" v-model="agb" switch> Ich akzeptiere die <a
                  href="https://www.immolert.de/agb" target="_blank">AGB</a>
              </b-form-checkbox>
              <b-form-checkbox :state="gdprState" v-model="gdpr" switch> Ich akzeptiere die <a
                  href="https://www.immolert.de/datenschutzerklaerung" target="_blank">Datenschutzvereinbarungen</a>
              </b-form-checkbox>
              <b-form-checkbox v-model="user.newsletter" switch> Ich möchte den Newsletter erhalten
              </b-form-checkbox>
              <br>
              <b-alert :show="error" variant="danger">Es gibt bereits einen Account mit dieser E-Mail Adresse</b-alert>
              <hr>
              <b-row>

                <b-col>
                  <b-button variant="success" type="submit"
                            style="padding-left: 40px; padding-right: 40px; display: block; margin: auto">Jetzt
                    kostenlos testen
                  </b-button>
                </b-col>
              </b-row>
              <br>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card header="Registierung » abgeschlossen!" style="text-align: center !important;"
            class="form-signin" v-if="done">
      <br/><br/>
      <b-icon icon="check2-circle" variant="success" scale="5"></b-icon>
      <br/><br/><br/>
      Registierung abgeschlossen,
      <router-link :to="{name:'Login'}">jetzt einloggen.</router-link>
    </b-card>
    <b-card header="Registierung » Account wird erstellt" style="text-align: center !important;" class="form-signin"
            v-if="loading">
      <br/><br/>
      <b-icon icon="arrow-clockwise" animation="spin" variant="primary" scale="5"></b-icon>
      <br/><br/><br/>
      Ihr Account wird gerade erstellt, bitte haben Sie einen Augenblick Geduld.

    </b-card>
  </div>
</template>

<script>
import {Salutations} from "@/models/static/salutations";
import validator from '@/services/validator.service';
import HTTP from "@/http";
import zipCodeService from "@/services/zipcode.service";

export default {
  name: 'Register',
  data() {
    return {
      user: {
        salutation: null,
        contactInformation: {},
        paymentType: null, bankingData: {},
        city: null,
        newsletter: false,
      },
      showWarning: false,
      loading: false,
      message: '',
      salutations: Salutations.get(),
      selectedSalutation: null,
      emailState: null,
      passwordState: null,
      salutationState: null,
      prenameState: null,
      lastnameState: null,
      zipCodeState: null,
      cityState: null,
      streetState: null,
      agbState: null,
      gdprState: null,
      firmState: null,
      ibanState: null,
      bicState: null,
      gdpr: false,
      agb: false,
      done: false,
      error: false
    };
  },
  created() {
    let tag = document.createElement("script");
    tag.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=AW-694450850");
    document.head.appendChild(tag);
  },
  methods: {
    gtag: function () {
      if (window.dataLayer === undefined) {
        return;
      }
      window.dataLayer.push(arguments);
    },
    gtag_report_conversion: function (url) {
      this.gtag('js', new Date());

      this.gtag('config', 'AW-694450850');
      var callback = function () {
        if (typeof (url) != 'undefined') {
          window.location = url;
        }
      };
      this.gtag('event', 'conversion', {
        'send_to': 'AW-694450850/dBDXCPiO3_MBEKL1kcsC',
        'event_callback': callback
      });
      return false;
    },
    zipCodeAutocomplete: function () {
      let ac = zipCodeService.autocomplete(this.user.zipCode);
      if (ac === null || ac === undefined) {
        return;
      }
      ac.then(city => this.user.city = city);
    },
    validateForm: function () {
      this.passwordState = !validator.isEmptyAndRequired(this.user.clearPassword) && (this.user.clearPassword.length >= 8);
      this.emailState = !validator.isEmptyAndRequired(this.user.email) && validator.validateEmail(this.user.email);
      this.prenameState = !validator.isEmptyAndRequired(this.user.prename);
      this.lastnameState = !validator.isEmptyAndRequired(this.user.lastname);
      this.cityState = true;
      this.streetState = true;
      this.zipCodeState = true;
      this.salutationState = this.user.salutation !== null;
      this.gdprState = this.gdpr;
      this.agbState = this.agb;
      this.firmState = this.user.salutation !== "FIRM" || !validator.isEmptyAndRequired(this.user.firm);
      this.ibanState = true;
      this.bicState = true;

      return this.passwordState && this.emailState && this.agbState && this.gdprState && this.lastnameState && this.prenameState
          && this.cityState && this.streetState && this.zipCodeState && this.salutationState && this.firmState && this.bicState && this.ibanState;
    },
    create() {
      if (!this.validateForm()) {
        this.$refs.signform.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        this.showWarning = true;
        return;
      }

      this.loading = true;
      HTTP.post("/users", this.user)
          .then(() => {
            this.done = true;
            this.loading = false;
            this.gtag_report_conversion();
          }).catch(() => {
        this.error = true;
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
html,
body {
  min-height: 100%;
  color: #435c75;
}

.wrapper {
  background-image: linear-gradient(#435c75, #6288ac);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  overflow: auto;
}

body {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 40px;
  text-align: left;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ok-list {
  margin-top: 35px;
  margin-bottom: 35px;
  color: #fff;
  margin-left: 10px;
  list-style: none;
  position: relative;
  display: inline-block;
}

.ok-list li {
  position: relative;
}

.ok-list li:before {
  content : '✓';
  font-weight: bold;
  font-size: 20px;
  margin-top: -3px;
  margin-left: -20px;
  position: absolute;
  color: #63e863;
}
</style>
