<template>
  <div class="container">
    <div class="content">
      <b-row>
        <b-col lg="6" sm="12">
          <div class="card">
            <div class="card-header">
              Stammdaten des Lieferanten
              <b-badge variant="primary" class="float-right" v-b-tooltip="'Dies zeigt Ihnen den Typ des Dienstleisters an.'">{{ this.supplier.type | germanSupplierType }}</b-badge>
            </div>
            <div class="card-body">
              {{ this.supplier.salutation | germanSalutation }}<br/>
              <span v-if="this.supplier.firm">{{ this.supplier.firm }}<br/></span>
              {{ this.supplier.prename }} {{ this.supplier.lastname }}<br/>
              {{ this.supplier.street }}<br/>
              {{ this.supplier.zipCode }} {{ this.supplier.city }}<br/><br/>
            </div>
          </div>
        </b-col>
        <b-col lg="6" sm="12">
          <ContactInfo v-if="supplier.contactInformation != null" :info="this.supplier.contactInformation"/>
        </b-col>
      </b-row>
      <br>
      <Comment :entity-type="'supplier'" :entity-id="this.id"/>
    </div>
  </div>
</template>
<script>
import HTTP from "@/http";
import Comment from "@/components/Comment";
import ContactInfo from "@/components/ContactInfo";

export default {
  name: 'ShowSupplier',
  components: {ContactInfo, Comment},
  data() {
    return {
      id: this.$route.params.id,
      supplier: {
        contactInformation:null
      }
    }
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {
      HTTP.get("/suppliers/" + this.id).then(resp => this.supplier = resp.data);
    }
  }
}
</script>
<style></style>