<template>
  <div>
    <b><b-icon icon="tags"></b-icon>
    Tags</b><br>
    <Tag v-for="tag in tags" :name="tag.name"  v-bind:key="tag.id" :entity-id="tag.entityId" :entity-type="tag.entityType" :id="tag.id" @deleted="load"/>
    <AddTag :entity-id="entityId" :entity-type="entityType" @tagSaved="load"/>
  </div>
</template>
<script>
import AddTag from "./AddTag";
import Tag from "./Tag";
import HTTP from "../../http";

export default {
  name: "TagList",
  props: ['entityType', 'entityId'],
  components: {Tag, AddTag},
  data() {
    return {
      tags: []
    }
  },
  methods: {
    load() {
      HTTP.get("/tags?entityType=" + this.$props.entityType + "&size=100&entityId=" + this.$props.entityId)
          .then(response => {
            this.tags = response.data.content;
          });
    }
  },
  created() {
    this.load();
  }
}
</script>