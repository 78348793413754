<template>
  <div class="container">
    <div class="content">
      <b-card header="Mahnverfahren ansehen">
        <b-row>
          <b-col>
            <b>Mahnverfahren zur Einheit:</b> {{ this.unit.name }}<br/>
            <b>Gestartet am:</b> {{ this.monition.created | germanDateTime }}<br/>
            <b>Überfallig seit:</b> {{ this.monition.overdueSince | germanDateTime }}<br/>
            <br/>
            <b>Aktueller Status:</b><br/>
            <b-badge :variant="this.monition.state | monitionStateBadge">
              {{ this.monition.state | monitionStateGerman }}
            </b-badge>
          </b-col>
          <b-col>
            <b>Daten zum Mieter:</b> <br>
            {{ this.renter.salutation |germanSalutation }}<br>
            {{ this.renter.prename }} {{ this.renter.lastname }}<br>
            {{ this.renter.street }}<br>
            {{ this.renter.zipCode }} {{ this.renter.city }}<br><br>
          </b-col>
        </b-row>
        <div v-if="monition.state != 'PAID' && monition.state != 'CANCELED' && monition.state != 'FAILED'">
          <b-button class="float-right"
                    v-b-tooltip="'Das Mahnverfahren ist fehlgeschlagen, klicken Sie hier um den Status fehlgeschlagen zu setzen.'"
                    @click.prevent="action('MARK_AS_FAILED')"
                    variant="danger">Als fehlgeschlagen
            markieren
          </b-button>
          <b-button class="float-right"
                    v-b-tooltip="'Hier können Sie das Mahnverfahren abbrechen.'"
                    @click.prevent="action('CANCEL')">Vorgang abbrechen</b-button>

          <b-button class="float-right"
                    v-b-tooltip="'Das Mahnverfahren als bezahlt und abgeschlossen markieren.'"
                    @click.prevent="action('MARK_AS_PAID')" variant="success">Als bezahlt
            markieren
          </b-button>
          <b-button class="float-right" :to="{name:'ProcessMonition', params: {id: this.monition.id}}"
                    variant="primary"
                    v-b-tooltip="'Hier können Sie ein Schreiben aufsetzen um den Mieter an die Zahlung zu erinnern.'"
                    v-if="monition.state != 'SECOND_MONITION_SENT' && monition.state != 'LEGAL_DUNNING'">Schreiben
            aufsetzen
          </b-button>
        </div>
      </b-card>
      <br>
      <b-card header="Erstellte Dokumente ansehen">
        <div class="text-center"
             v-if="monition.paymentReminderFileId == null && monition.firstMonitionFileId == null && monition.secondMonitionFileId == null">
          Für dieses Mahnverfahren wurden keine Dokumente erstellt
        </div>
        <b-row v-if="monition.paymentReminderFileId != null">
          <b-col>
            <b>Zahlungserinnerung</b>
          </b-col>
          <b-col>
            <b>Erstellt am: </b> {{ monition.paymentReminderSent | germanDateTime }}
          </b-col>
          <b-col>
            <b-button variant="primary" class="float-right"
                      @click.prevent="download(monition.paymentReminderFileId)">
              <b-icon icon="file-text"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="monition.firstMonitionFileId != null">
          <b-col>
            <b>Erste Mahnung</b>
          </b-col>
          <b-col>
            <b>Erstellt am: </b> {{ monition.firstMonitionSent | germanDateTime }}
          </b-col>
          <b-col>
            <b-button variant="primary" class="float-right"
                      @click.prevent="download(monition.firstMonitionFileId)">
              <b-icon icon="file-text"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="monition.secondMonitionFileId != null">
          <b-col>
            <b>Zweite Mahnung</b>
          </b-col>
          <b-col>
            <b>Erstellt am: </b> {{ monition.secondMonitionSent | germanDateTime }}
          </b-col>
          <b-col>
            <b-button variant="primary" class="float-right"
                      @click.prevent="download(monition.secondMonitionFileId)">
              <b-icon icon="file-text"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <br>
      <Comment :entity-id="this.monition.id" :entity-type="'Monition'"/>
    </div>
  </div>
</template>
<script>
import HTTP from "@/http";
import Comment from "@/components/Comment";

export default {
  name: 'ShowMonition',
  components: {Comment},
  data() {
    return {
      monition: {},
      unit: {},
      renter: {},
    }
  },
  created() {
    this.loadMonition().then(() => {
      this.loadUnitAndRenter();
    })
  },
  methods: {
    loadMonition: function () {
      return HTTP.get("/monitions/" + this.$route.params.id).then(response => this.monition = response.data);
    },
    loadUnitAndRenter: function () {
      HTTP.get("/units/" + this.monition.unitId).then(response => {
        this.unit = response.data;
        this.loadRenter();
      });
    },
    loadRenter: function () {
      HTTP.get("/renters/" + this.unit.currentRenterId).then(response => {
        this.renter = response.data;
      });
    },
    download: function (id) {
      HTTP.get("/pdfs/download/" + id, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "file.pdf"
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    action: function (event) {
      HTTP.post("/process-monition/" + this.monition.id + "/" + event).then(() => {
        this.loadMonition();
        this.$bvToast.toast('Aktion wurde ausgeführt', {
          title: 'Mahnverfahren abgeschlossen',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: false
        });
      })
    }
  }
}
</script>
<style scoped>
.btn {
  margin-right: 5px !important;
}
</style>