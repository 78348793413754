export default class CreateUpdateGarage {

  constructor(buildingId, type, monthlyLease, vehicles, name) {
    this.buildingId = buildingId;
    this.type = type;
    this.monthlyLease = monthlyLease;
    this.vehicles = vehicles;
    this.name = name;
  }
}
