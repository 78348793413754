<template>
  <label>
    {{label}}
    <b-icon icon="question-square" class="info-click"
            v-b-tooltip.hover.click="tooltip"/>
  </label>
</template>
<script>
export default {
  props: ['tooltip', 'label'],
  name : 'InfoLabel'
}
</script>