export class BillingPointKeys {
    static get(exclude) {
        let values = [
            {
                value: null,
                text: 'Bitte Auswahl treffen'
            },
            {
                value: 'BY_USAGE',
                text: 'Nach Verbrauch'
            },
            {
                value: 'BY_PERSON',
                text: 'Nach Personen'
            },
            {
                value: 'BY_SQUAREMETERS',
                text: 'Nach Quadratmeter'
            },
            {
                value: 'BY_UNIT',
                text: 'Nach Einheit'
            }
        ];

        if (exclude !== undefined && exclude !== null) {
            let newVals = [];
            for (let i = 0; i < values.length; i++) {
                if (values[i].value !== exclude) {
                    newVals.push(values[i]);
                }
            }
            return newVals;
        }
        return values;
    }

    static germanKey(type) {
        let types = this.get();
        for (let i = 0; i < types.length; i++) {
            if (types[i].value == type) {
                return types[i].text;
            }
        }
        return type;
    }
}