<template>
  <div class="container">
    <div class="content">
      <b-card header="Zähler anlegen">
        <b-form @submit.prevent="createCounter">
          <b-row>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Zählertyp" tooltip="Wählen Sie hier aus um welchen Typ des Zählers es sich handelt"/>
                <b-form-select :options="counterTypes" type="text" id="counterType" v-model="selectedCounterType"
                               required
                               placeholder=""></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Zählernummer / Bezeichnung" tooltip="Tragen Sie hier die auf dem Zähler aufgedruckte Zählernummer ein"/>
                <b-form-input type="text" v-model="counter.counterId" placeholder="XXXXXXX"
                              required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="this.counter.id == null" lg="4" sm="12">
              <b-form-group v-if="this.loadingPicture">
                <InfoLabel label="Bild wird hochgeladen" tooltip="Bild wird aktuell hochgeladen."/>
                <div class="text-center">
                  <b-icon animation="cylon" icon="three-dots"></b-icon>
                </div>
              </b-form-group>
              <b-form-group v-if="this.pictureId == null && !this.loadingPicture">
                <InfoLabel label="Bild vom Zählerstand" tooltip="Laden Sie hier ein Bild des Zählerstands hoch"/>
                <b-form-file
                    id="input-6"
                    placeholder="Datei hochladen (PDF, JPEG oder PNG)"
                    v-on:change="this.uploadImage"
                ></b-form-file>
              </b-form-group>
              <b-form-group v-if="this.pictureId != null && !this.loadingPicture">
                <InfoLabel label="Bild bereits hochgeladen" tooltip="Das Bild wurde bereits hochgeladen, um ein neues Bild hoch zu laden klicken Sie einfach auf Neue Datei hochladen"/>
                <br/>
                <b-button
                    variant="secondary"
                    v-on:click="this.resetImage"
                >Neue Datei hochladen
                </b-button>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group id="currentValueGroup">
                <InfoLabel label="Aktueller Stand" tooltip="Tragen Sie hier den aktuell auf dem Zähler stehenden Wert ein"/>
                <b-form-input type="number" id="currentValue" v-model="counter.currentValue" placeholder="100000"
                              v-if="this.counter.id == null"
                              required></b-form-input>
                <b-form-input type="number" id="currentValue" v-model="counter.currentValue" placeholder="100000"
                              disabled v-if="this.counter.id != null"
                              required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="float-right" variant="primary">Zähler speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>
import {CounterTypes} from "@/models/static/counter-types";
import messageService from '@/services/message.service';
import FileService from "@/services/file.service";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateCounter',
  components: {InfoLabel},
  data() {
    return {
      'unitId': this.$route.params.unitId,
      'selectedCounterType': null,
      loadingPicture: false,
      pictureId: null,
      'counterTypes': CounterTypes.get(),
      'counter': {}
    }
  },
  created() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      HTTP.get("/counters/" + this.$route.params.id).then(resp => {
        this.counter = resp.data;
        this.selectedCounterType = this.counter.counterType;
      });
    }
  },
  methods: {
    uploadImage: function (item) {
      console.log(item.target.files);
      this.loadingPicture = true;
      FileService.upload(item.target.files[0]).then(resp => {
        this.pictureId = resp.data.id;
        this.loadingPicture = false;
        messageService.success('Speichern erfolgreich', 'Datei erfolgreich hochgeladen');
      }).catch(() => {
        this.pictureId = null;
        this.loadingPicture = false;
        messageService.error('Speichern fehlgeschlagen', 'Datei konnte nicht hochgeladen werden');
      });
    },
    resetImage: function () {
      this.pictureId = null;
      HTTP.delete('/files/' + this.pictureId);
    },
    createCounter: function () {
      this.counter.counterType = this.selectedCounterType;
      this.counter.unitId = this.unitId;
      if (this.pictureId == null) {
        messageService.hint('Kein Bild hochgeladen', 'Es wird ein Bild für den Zählerstand benötigt');
        return;
      }

      if (this.counter.id == null) {
        this.counter.values = [];
        this.counter.values.push({
          value: this.counter.currentValue,
          pictureId: this.pictureId,
          date: new Date()
        });

        HTTP.post("/counters", this.counter).then(resp => {
          let counterValue = {
            value: this.counter.currentValue,
            pictureId: this.pictureId,
            date: new Date(),
            counterId: resp.data.id
          };

          HTTP.post("/counter-values", counterValue).then(() => {
            this.$router.push({name: 'ShowUnit', params: {id: this.unitId}}).then(() => {
              messageService.success('Speichern erfolgreich', 'Zähler erfolgreich gespeichert');
            });
          })
        });
      } else {
        HTTP.put("/counters/" + this.counter.id, this.counter).then(() => {
          this.$router.push({name: 'ShowUnit', params: {id: this.unitId}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Zähler erfolgreich angepasst');
          });
        });
      }
    }
  }
}
</script>
<style></style>