<template>
  <div class="container">
    <div class="content">
      <b-alert variant="warning" v-if="id != null && !copy" show dismissible>
        Achtung die bestehenden Kosten zu ändern ist nicht empfehlenswert! Sollten diese schon bei
        Nebenkostenabrechnungen angegeben worden sein
        kann dies deren Berechnung verfälschen. Deaktivieren sie stattdessen die Kosten und kopieren Sie diese.
      </b-alert>
      <b-alert variant="secondary" v-if="copy" dismissible show>
        Die eingetragenen Daten wurden kopiert
      </b-alert>
      <b-card header="Verbrauchskosten anlegen">
        <b-form @submit.prevent="save" novalidate>
          <b-row>
            <b-col>
              <b-form-group>
                <InfoLabel label="Beschriftung der Kosten" tooltip="Diese Beschriftung erscheint auch bei der Nebenkostenabrechnung"/>
                <b-form-input v-model="buildingUsageCost.name" placeholder="Bspw.: Wasserverbrauch"
                              :state="nameState" required/>
                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <InfoLabel label="Zählertyp" tooltip="Der Zählertyp bestimmt aus welche Verbauchswerte zur Berechnung herangezogen werden"/>
                <b-form-select :options="counterTypes"
                               v-model="buildingUsageCost.counterType"
                               :state="counterTypeState"
                               required></b-form-select>
                <b-form-invalid-feedback>
                  Bitte einen Zählertyp auswählen
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <InfoLabel label="Kosten pro Einheit" tooltip="Dies ist der Betrag pro Einheit für das gesamte Gebäude"/>
                <b-form-input v-model="buildingUsageCost.pricePerUnit" lazy-formatter :formatter="formatter.formatNumber" type="text"
                              :state="pricePerUnitState"
                              placeholder="Bspw.: 0,32" required/>
                <b-form-invalid-feedback>
                  Bitte tragen Sie eine Zahl ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label=""><br>
                <b-form-checkbox v-model="buildingUsageCost.active" name="check-button" switch>
                  aktiviert
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label=""><br>
                <b-form-checkbox v-model="buildingUsageCost.allocatable" name="check-button" switch>
                  umlegbar
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="float-right" variant="primary">Speichern</b-button>
        </b-form>
      </b-card>
    </div>

  </div>
</template>
<script>
import {CounterTypes} from "@/models/static/counter-types";
import messageService from '@/services/message.service';
import {germanFormatter} from '@/services/formatter';
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateBuildingUsageCost',
  components: {InfoLabel},
  data() {
    return {
      id: this.$route.params.id,
      copy: this.$route.params.copy ? true : false,
      formatter: germanFormatter,
      buildingUsageCost: {
        buildingId: this.$route.params.buildingId,
        counterType: null,
        allocatable: true,
        active: true,
        pricePerUnit: null,
        name: null
      },
      nameState : null,
      counterTypeState : null,
      pricePerUnitState : null,
      counterTypes: CounterTypes.get()
    }
  },
  created() {
    if (this.id == null) {
      return;
    }
    HTTP.get("/building-usage-costs/" + this.id).then(response => {
      this.buildingUsageCost = response.data
      this.buildingUsageCost.pricePerUnit = this.formatter.formatNumber(this.buildingUsageCost.pricePerUnit);
    });
  },
  methods: {
    validateForm() {
      let isValid = true;

      if (this.buildingUsageCost.name == null || this.buildingUsageCost.name.length < 3) {
        isValid = false;
        this.nameState = false;
      } else {
        this.nameState = true;
      }

      if (this.buildingUsageCost.counterType == null) {
        isValid = false;
        this.counterTypeState = false;
      } else {
        this.counterTypeState = true;
      }

      this.buildingUsageCost.pricePerUnit = germanFormatter.parseNumber(this.buildingUsageCost.pricePerUnit);

      if (isNaN(this.buildingUsageCost.pricePerUnit) || this.buildingUsageCost.pricePerUnit == null) {
        isValid = false;
        this.pricePerUnitState = false;
      } else {
        this.pricePerUnitState = true;
      }

      return isValid;
    },
    save: function () {
      if(!this.validateForm()) {
        return;
      }

      this.buildingUsageCost.pricePerUnit = this.formatter.parseNumber(this.buildingUsageCost.pricePerUnit);
      if (this.copy || this.id == null) {
        HTTP.post("/building-usage-costs", this.buildingUsageCost).then(() => {
          this.$router.push({name: "ShowBuildingTab", params: {id: this.buildingUsageCost.buildingId, activeTab: 'building-usage-cost'}}).then(() => {
            messageService.success('Speichern erfolgreich','Kosten erfolgreich erstellt');
          });
        });
      } else {
        HTTP.put("/building-usage-costs/" + this.id, this.buildingUsageCost).then(() => {
          this.$router.push({name: "ShowBuildingTab", params: {id: this.buildingUsageCost.buildingId, activeTab: 'building-usage-cost'}}).then(() => {
            messageService.success('Speichern erfolgreich','Kosten erfolgreich bearbeitet');
          });
        });
      }
    }
  }
}
</script>
<style>

</style>