<template>
  <div class="container">
    <div class="content">
      <b-form @submit.prevent="save" novalidate>
        <b-row>
          <b-col lg="8">
            <b-card header="Stammdaten des Lieferanten">
              <b-row>
                <b-col lg="2" sm="12">
                  <b-form-group>
                    <InfoLabel label="Anrede" tooltip="Wählen Sie die korrekte Anrede aus"/>
                    <b-form-select :options="salutations" :state="salutationState"
                                   v-model="supplier.salutation"></b-form-select>
                    <b-form-invalid-feedback>Bitte eine Anrede wählen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="5" sm="12">
                  <b-form-group>
                    <InfoLabel label="Vorname" tooltip="Tragen Sie den Vornamen des Lieferanten / Dienstleisters  ein"/>
                    <b-form-input type="text" placeholder="Hier den Vornamen eintragen" required
                                  v-model="supplier.prename" :state="prenameState"/>
                    <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="5" sm="12">
                  <b-form-group>
                    <InfoLabel label="Nachname" tooltip="Tragen Sie den Nachnamen des Lieferanten / Dienstleisters ein"/>

                    <b-form-input type="text" placeholder="Hier den Nachnamen eintragen" required
                                  v-model="supplier.lastname" :state="lastnameState"/>
                    <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group v-if="supplier.salutation === 'FIRM'">
                <InfoLabel label="Firma" tooltip="Tragen Sie den Firmennamen des Lieferanten / Dienstleisters ein"/>
                <b-form-input type="text" v-model="supplier.firm"/>
              </b-form-group>
              <b-form-group>
                <InfoLabel label="Straße und Hausnummer" tooltip="Tragen Sie die Anschrift des Lieferanten / Dienstleisters ein"/>

                <b-form-input type="text" placeholder="Hier die Straße eintragen"
                              :state="streetState"
                              required v-model="supplier.street"/>
                <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col lg="3" sm="12">
                  <b-form-group>
                    <InfoLabel label="Postleitzahl" tooltip="Tragen Sie die Postleitzahl des Dienstleisters ein"/>
                    <b-form-input type="text" placeholder="Postleitzahl"
                                  :state="zipCodeState"
                                  v-model="supplier.zipCode"
                                  @input="zipCodeAutocomplete"
                                  required />
                    <b-form-invalid-feedback>{{ this.validator.notValidZipCodeMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="9" sm="12">
                  <b-form-group>
                    <InfoLabel label="Ort" tooltip="Tragen Sie den Ort des Lieferanten / Dienstleisters ein"/>
                    <b-form-input type="text" :state="cityState" placeholder="Hier den Ort eintragen" required
                                  v-model="supplier.city"/>
                    <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card header="Kontaktinformationen des Lieferanten">
              <b-form-group>
                <InfoLabel label="Art des Lieferanten" tooltip="Tragen Sie die Art des Lieferanten / Dienstleisters ein"/>
                <b-form-select :options="supplierTypes" :state="typeState" v-model="supplier.type"/>
                <b-form-invalid-feedback>{{ this.validator.noSelectionMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <InfoLabel label="E-Mail Adresse" tooltip="Tragen Sie die E-Mail Adresse des Lieferanten / Dienstleisters ein"/>
                <b-input-group append="@">
                  <b-form-input type="email" placeholder="info@example.de" :state="emailState"
                                v-model="supplier.contactInformation.email"/>
                </b-input-group>
                <b-form-invalid-feedback :state="emailState">{{
                    this.validator.notValidEmailMessage()
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <InfoLabel label="Telefonnummer" tooltip="Tragen Sie die Telefonnummer des Lieferanten / Dienstleisters ein"/>

                <b-input-group append="☎">
                  <b-form-input type="text" :state="telephoneState" placeholder="0049 00000 000000"
                                v-model="supplier.contactInformation.telephone"/>
                </b-input-group>
                <b-form-invalid-feedback :state="telephoneState">{{
                    this.validator.toShortMessage()
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <InfoLabel label="Mobilfunknummer" tooltip="Tragen Sie die Mobilfunknummer des Lieferanten / Dienstleisters ein"/>
                <b-input-group append="📱">
                  <b-form-input type="text" :state="mobilephoneState"
                                v-model="supplier.contactInformation.mobilephone" placeholder="0049 00000 000000"/>
                </b-input-group>
                <b-form-invalid-feedback :state="mobilephoneState">{{
                    this.validator.toShortMessage()
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-card>
          <b-button type="submit" variant="primary" class="float-right">Lieferant speichern</b-button>
        </b-card>
      </b-form>
    </div>
  </div>
</template>
<script>
import {SupplierTypes} from "@/models/static/supplier-types";
import zipCodeService from "@/services/zipcode.service";
import messageService from '@/services/message.service';
import {Salutations} from "@/models/static/salutations";
import validator from '@/services/validator.service';
import InfoLabel from "@/components/InfoLabel";

import HTTP from "@/http";

export default {
  name: 'CreateSupplier',
  components: {InfoLabel},
  data() {
    return {
      salutations: Salutations.get(),
      supplierTypes: SupplierTypes.get(),
      supplier: {
        type: null,
        salutation: null,
        contactInformation: {},
        city: null
      },
      salutationState: null,
      prenameState: null,
      lastnameState: null,
      streetState: null,
      cityState: null,
      typeState: null,
      telephoneState: null,
      emailState: null,
      mobilephoneState: null,
      zipCodeState: null,
      validator: validator
    }
  },
  created() {
    if (this.$route.params.id === null || this.$route.params.id === undefined) {
      return;
    }
    HTTP.get("/suppliers/" + this.$route.params.id).then(response => {
      this.supplier = response.data
      if (this.supplier.contactInformation == null) {
        this.supplier.contactInformation = {};
      }
    });
  },
  methods: {
    zipCodeAutocomplete : function () {
      zipCodeService.autocomplete(this.supplier.zipCode).then(city => this.supplier.city = city);
    },
    validateForm: function () {
      this.lastnameState = !validator.isEmptyAndRequired(this.supplier.lastname);
      this.prenameState = !validator.isEmptyAndRequired(this.supplier.prename);
      this.streetState = !validator.isEmptyAndRequired(this.supplier.street);
      this.salutationState = !validator.nullSelect(this.supplier.salutation);
      this.cityState = !validator.isEmptyAndRequired(this.supplier.city);
      this.zipCodeState = !validator.zipCode(this.supplier.zipCode);
      this.telephoneState = !validator.isEmptyAndNotRequired(this.supplier.contactInformation.telephone);
      this.mobilephoneState = !validator.isEmptyAndNotRequired(this.supplier.contactInformation.mobilephone);
      this.emailState = validator.validateEmail(this.supplier.contactInformation.email);
      this.typeState = !validator.nullSelect(this.supplier.type);

      return this.salutationState
          && this.prenameState
          && this.lastnameState
          && this.cityState
          && this.streetState
          && this.telephoneState
          && this.mobilephoneState
          && this.emailState
          && this.typeState
          && this.zipCodeState;
    },
    save: function () {
      if (!this.validateForm()) {
        return;
      }

      if (this.supplier.id !== undefined && this.supplier.id !== null) {
        HTTP.put("/suppliers/" + this.supplier.id, this.supplier).then(() => {
          this.$router.push({name: 'ListSuppliers'}).then(() => {
            messageService.success('Lieferant gespeichert', 'Das Speichern des Lieferanten war erfolgreich');
          });
        });
      } else {
        HTTP.post("/suppliers", this.supplier).then(() => {
          this.$router.push({name: 'ListSuppliers'}).then(() => {
            messageService.success('Lieferant gespeichert', 'Das Speichern des Lieferanten war erfolgreich');
          });
        });
      }
    }
  }
}
</script>
<style>

</style>