<template>
  <div>
    <b-skeleton-table v-if="repairs == null"/>
    <div v-if="repairs !=null && repairs.totalElements === 0">
      <NoResultsCreateSuggest text="Noch keine Reparaturen angelegt"
                              :route="{name:'CreateRepair', params: {buildingId:this.buildingId}}"/>
    </div>
    <div v-if="repairs!=null && repairs.totalElements > 0">
      <b-button :to="{name:'CreateRepair', params: {buildingId:this.buildingId}}"
                v-b-tooltip="'Hier klicken um eine Reparatur anzulegen.'"
                variant="primary"
                class="float-right">Weitere
        Reparatur anlegen
      </b-button>
      <b-table striped
               ref="table"
               :responsive="true"
               class="card-table"
               :items="repairs.content"
               :fields="fields"
               :no-local-sorting="true"
               v-on:sort-changed="loadRepairs">
        <template #cell(cost)="data">
          {{ data.item.cost | currency }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount | currency }}
        </template>
        <template #cell(unit)="data">
          <span v-if="data.item.unit !== undefined && data.item.unit !== null">
          {{ data.item.unit.name }}
          </span>
          <span v-if="data.item.unit === undefined || data.item.unit === null">
          <b-badge variant="secondary">Keine Einheit</b-badge>
          </span>
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" variant="primary"
                    v-b-tooltip.hover.left="'Klicken Sie hier um die Reparatur anzusehen.'"
                    :to="{name: 'ShowRepair', params: {id:data.item.id}}">
            <b-icon icon="eye"/>
          </b-button>&nbsp;
          <b-button size="sm" variant="warning"
                    v-b-tooltip.hover.left="'Klicken Sie hier um die Reparatur zu bearbeiten.'"
                    :to="{name: 'EditRepair', params: {id:data.item.id}}">
            <b-icon icon="pencil"/>
          </b-button>&nbsp;
          <b-button size="sm" variant="danger"
                    v-b-tooltip.hover.left="'Klicken Sie hier um die Reparatur zu löschen.'"
                    @click="deleteModal(data.item.id)">
            <b-icon icon="trash"/>
          </b-button>
        </template>
      </b-table>
      <RouterlessPageable :pageable="repairs" @change="loadRepairsPage"/>
    </div>
    <DeleteModal ref="delmod"
                 modal-text="Reparatur wirklich löschen"
                 modal-title="Reparatur löschen?"
                 v-model="deleteId"
                 rest-url="repairs"
                 id="delete-mdl"
                 @deleted="loadRepairs"/>
  </div>
</template>
<script>
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import RouterlessPageable from "@/components/RouterlessPageable";
import DeleteModal from "@/components/DeleteModal";

import HTTP from "@/http";

export default {
  name: 'ListRepair',
  components: {RouterlessPageable, DeleteModal, NoResultsCreateSuggest},
  props: ['buildingId', 'unitId'],
  data() {
    return {
      deleteId: null,
      repairs: null,
      sortStr: '',
      fields: [
        {
          key: 'execution',
          label: 'Datum',
          sortable: true
        },
        {
          key: 'unit',
          label: 'Einheit',
          sortable: false
        },
        {
          key: 'amount',
          label: 'Betrag',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      page: 0
    }
  },
  created() {
    this.loadRepairs();
  },
  methods: {
    deleteModal: function (id) {
      this.deleteId = id;
      this.$refs.delmod.openModal();
    },
    loadRepairsPage: function (page) {
      this.page = page;
      this.loadRepairs();
    },
    loadRepairs: function (sort) {

      if (sort !== undefined && sort !== null) {
        this.sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      let filter = '';
      if (this.buildingId !== undefined && this.buildingId !== null) {
        filter += "&buildingId=" + this.buildingId;
      }

      if (this.unitId !== undefined && this.unitId !== null) {
        filter += "&unitId=" + this.unitId;
      }

      HTTP.get("/repairs?page=" + this.page + filter + this.sortStr).then(response => {
        this.repairs = response.data;
        for (let i = 0; i < this.repairs.content.length; i++) {
          if (this.repairs.content[i].unitId === null || this.repairs.content[i].unitId === undefined) {
            continue;
          }

          HTTP.get("/units/" + this.repairs.content[i].unitId).then(response => {
            this.repairs.content[i].unit = response.data;
            this.$refs.table.refresh();
          });
        }
      })
    }
  }
}
</script>
<style>

</style>