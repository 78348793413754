<template>
  <div class="container">
    <div class="content">
      <b-form @submit.prevent="onSubmit" @reset.prevent="reset()" novalidate>
        <b-row>
          <b-col>
            <b-card header="Adressdaten">
              <i>In diesem Bereich tragen Sie die grundlegenden Informationen zum Gebäude ein.</i>
              <hr>
              <b-form-group>
                <InfoLabel
                    tooltip="Hier tragen Sie idealerweise die Straße des Gebäudes ein, diese wird in Kombination mit der Stadt als Anzeigename verwendet."
                    label="Geben Sie die Straße & Hausnummer ein"/>
                <b-form-input
                    v-model="building.street"
                    :state="streetValid"
                    type="text"
                    required
                    placeholder="Straße & Hausnummer eingeben"
                ></b-form-input>
                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col lg="4">
                  <b-form-group>
                    <InfoLabel tooltip="Hier tragen Sie die Postleitzahl des Gebäudes ein." label="PLZ"/>
                    <b-input v-model="building.zipCode"
                             placeholder="PLZ eingeben"
                             :state="zipCodeState"
                             @input="autocompleteZip"
                             type="text"/>
                    <b-form-invalid-feedback>
                      Die eingebene Postleitzahl ist nicht korrekt
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="8">
                  <b-form-group>
                    <InfoLabel
                        tooltip="Hier tragen Sie den Ort des Gebäudes ein, dieser wird in Kombination mit der Straße als Anzeigename verwendet."
                        label="Ort des Gebäudes"/>
                    <b-form-input
                        v-model="building.city"
                        :state="cityValid"
                        required
                        placeholder="Stadt eingeben"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Bitte tragen Sie mindestens 3 Zeichen ein
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <InfoLabel
                    tooltip="Aktuell können wir nur Deutschland abbilden."
                    label="Land des Gebäudes"/>

                <b-form-input
                    id="input-3"
                    v-model="building.country"
                    disabled
                ></b-form-input>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col lg="6" sm="12">
            <b-card header="Gebäudedaten">
              <i>Diese Daten werden für die Nebenkostenabrechnung benötigt</i>
              <hr>
              <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group>
                    <InfoLabel
                      tooltip="Tragen Sie hier die gesamte Nutzfläche des Gebäudes ein, also alle Wohnflächen addiert."
                      label="Nutzfläche"/>
                    <b-input-group append="m²">
                      <b-form-input type="text"
                                    v-model="building.livingSquareMeters"
                                    :state="livingSquareMetersState"
                                    placeholder="Bspw.: 2500"/>
                    </b-input-group>
                    <b-form-invalid-feedback
                        :state="livingSquareMetersState">
                      Bitte einen Zahlenwert eintragen
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                    <InfoLabel
                        tooltip="Tragen Sie hier die gesamte Nebenfläche des Gebäudes ein, also alle Flure etc. addiert."
                        label="Nebenfläche"/>
                    <b-input-group append="m²">
                      <b-form-input type="text"
                                    v-model="building.secondaryAreaSquareMeters"
                                    :state="secondaryAreaSquareMetersState"
                                    placeholder="Bspw.: 200"/>
                    </b-input-group>
                    <b-form-invalid-feedback
                        :state="secondaryAreaSquareMetersState">
                      Bitte einen Zahlenwert eintragen
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                    <InfoLabel
                        tooltip="Tragen Sie hier die Anzahl der Wohneinheitem des Gebäudes ein."
                        label="Anzahl Einheiten"/>

                    <b-form-input type="text"
                                  v-model="building.totalUnits"
                                  :state="totalUnitsState"
                                  placeholder="Bspw.: 15"/>
                    <b-form-invalid-feedback>
                      Bitte einen Zahlenwert eintragen
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group>
                    <InfoLabel
                        tooltip="Tragen Sie hier die Anzahl der im Haus wohnenden Personen ein."
                        label="Anzahl Personen"/>
                    <b-form-input type="text"
                                  v-model="building.persons"
                                  :state="personsState"
                                  placeholder="Bspw.: 42"/>
                    <b-form-invalid-feedback>
                      Bitte einen Zahlenwert eintragen
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                    <InfoLabel
                        tooltip="Tragen Sie hier die Anzahl der Stockwerke des Gebäudes ein."
                        label="Anzahl Stockwerke"/>
                    <b-form-input type="text"
                                  v-model="building.floors"
                                  :state="floorsState"
                                  placeholder="Bspw.: 3"/>
                    <b-form-invalid-feedback>
                      Bitte einen Zahlenwert eintragen
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                    <InfoLabel
                        tooltip="Tragen Sie hier die Gesamtanzahl der Miteigentumsanteile des Gebäudes ein."
                        label="Miteigentumsanteile"/>

                    <b-form-input type="text"
                                  v-model="building.coOwnerships"
                                  :state="coOwnershipsState"
                                  placeholder="Bspw.: 1000"/>
                    <b-form-invalid-feedback>
                      Bitte einen Zahlenwert eintragen
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <i>Sie können die eingetragenen Werte bei Fehleingabe jederzeit ändern</i>
              <b-button type="submit" class="float-right" variant="primary"
                        id="submit-unit"
                        style="margin-left: 4px"
                        v-if="this.$route.params.id === undefined"
                        v-b-tooltip.bottom="'Mit Klick auf diesen Button wird das Gebäude angelegt, direkt danach legen Sie die erste  Einheit an.'">
                Gebäude speichern und Einheit anlegen
              </b-button>
              <b-button type="submit" id="submit-only" class="float-right" variant="secondary"
                        v-b-tooltip.bottom="'Mit Klick auf diesen Button wird das Gebäude angelegt, danach können Sie Einheiten und Kosten hinterlegen.'">
                Gebäude speichern
              </b-button>&nbsp;
            </b-card>
          </b-col>
        </b-row>
      </b-form>

    </div>
  </div>
</template>
<script>

import CreateUpdateBuilding from "@/models/api/create-update-building";
import messageService from '@/services/message.service';
import zipCodeService from '@/services/zipcode.service';
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateBuilding',
  data() {
    return {
      streetValid: null,
      cityValid: null,
      building: new CreateUpdateBuilding("", "", "Deutschland"),
      id: this.$route.params.id,
      totalUnitsState: null,
      livingSquareMetersState: null,
      secondaryAreaSquareMetersState: null,
      personsState: null,
      floorsState: null,
      coOwnershipsState: null,
      zipCodeState: null
    }
  },
  created() {
    if (this.id === null || this.id === undefined) {
      return;
    }
    HTTP.get("/buildings/" + this.id).then(response => {
      this.building = response.data;
    });
  },
  methods: {
    validateForm() {
      let isValid = true;

      if (this.building.street == null || this.building.street.length < 3) {
        isValid = false;
        this.streetValid = false;
      } else {
        this.streetValid = true;
      }

      if (this.building.city == null || this.building.city.length < 3) {
        isValid = false;
        this.cityValid = false;
      } else {
        this.cityValid = true;
      }

      if (isNaN(this.building.livingSquareMeters) && this.building.livingSquareMeters !== "" && this.building.livingSquareMeters !== undefined) {
        isValid = false;
        this.livingSquareMetersState = false;
      } else {
        this.livingSquareMetersState = true;
      }

      if (isNaN(this.building.secondaryAreaSquareMeters) && this.building.secondaryAreaSquareMeters !== "" && this.building.secondaryAreaSquareMeters !== undefined) {
        isValid = false;
        this.secondaryAreaSquareMetersState = false;
      } else {
        this.secondaryAreaSquareMetersState = true;
      }
      console.log(this.building.street);
      if (isNaN(this.building.totalUnits) && this.building.totalUnits !== "" && this.building.totalUnits !== undefined) {
        isValid = false;
        this.totalUnitsState = false;
      } else {
        this.totalUnitsState = true;
      }

      if (isNaN(this.building.persons) && this.building.persons !== "" && this.building.persons !== undefined) {
        isValid = false;
        this.personsState = false;
      } else {
        this.personsState = true;
      }

      if (isNaN(this.building.floors) && this.building.floors !== "" && this.building.floors !== undefined) {
        isValid = false;
        this.floorsState = false;
      } else {
        this.floorsState = true;
      }

      if (isNaN(this.building.coOwnerships) && this.building.coOwnerships !== "" && this.building.coOwnerships !== undefined) {
        isValid = false;
        this.coOwnershipsState = false;
      } else {
        this.coOwnershipsState = true;
      }

      if (this.building.zipCode !== null && this.building.zipCode !== ""
          && this.building.zipCode !== undefined
          && (this.building.zipCode.length > 5
              || this.building.zipCode.length < 4)
          && isNaN(this.building.zipCode)) {
        isValid = false;
        this.zipCodeState = false;
      } else {
        this.zipCodeState = true;
      }

      return isValid;
    },
    autocompleteZip () {
      zipCodeService.autocomplete(this.building.zipCode).then(city => {
         this.building.city = city;
      });
    },
    onSubmit(event) {
      console.log(event.submitter.id);
      if (!this.validateForm()) {
        return;
      }

      if (this.id !== null && this.id !== undefined) {
        HTTP.put("/buildings/" + this.id, this.building).then(() => {
          this.$router.push({name: "ListBuildings"}).then(() => {
            messageService.success('Speichern erfolgreich', 'Gebäude erfolgreich bearbeitet');
          });
        }).catch(() => {
          messageService.error('Fehlgeschlagen', 'Leider ist ein Fehler aufgetreten');
        });
        return;
      }
      HTTP.post("/buildings", this.building).then(response => {
        let route = {name: "ShowBuilding", params: {id: response.data.id}};
        let message = 'Das Gebäude wurde erfolgreich erstellt. Sie sehen nun die Details des erstellten Gebäudes.';
        if (event.submitter.id === "submit-unit") {
          message = 'Das Gebäude wurde erfolgreich erstellt. Nun können Sie die Daten zur Einheit eintragen.';
          route = {name: "CreateUnit", params: {buildingId: response.data.id}};
        }
        this.$router.push(route).then(() => {
          messageService.success('Speichern erfolgreich', message);
        });
      }).catch(() => {
        messageService.error('Fehlgeschlagen', 'Leider ist ein Fehler aufgetreten');
      });
    },
    reset() {
      if (confirm("Wirklich das Formular leeren?")) {
        this.building = new CreateUpdateBuilding("", "", "Deutschland");
      }
    }
  },
  components: {InfoLabel}
}
</script>
<style>

</style>