<template>
  <div class="container">
    <div class="content">
      <b-card header="Mieterdaten erfassen">
        <b-form @submit.prevent="onSubmitRenter" novalidate>
          <b-row>
            <b-col sm="12" lg="2">
              <b-form-group id="input-group-salutation">
                <InfoLabel label="Anrede" tooltip="Wählen Sie die korrekte Anrede aus"/>
                <b-form-select
                    id="salutation"
                    v-model="salutationValue"
                    required
                    placeholder="---"
                    :state="salutationState"
                    :options="salutations"
                >
                </b-form-select>
                <b-form-invalid-feedback>
                  Bitte wählen Sie eine Anrede aus
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-group id="input-group-prename">
                <InfoLabel label="Vorname" tooltip="Tragen Sie den Vornamen Ihres Mieters ein"/>
                <b-form-input
                    id="prename"
                    v-model="renter.prename"
                    required
                    placeholder="Bspw.: Max"
                    :state="prenameState"
                >
                </b-form-input>

                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-group id="input-group-lastname">
                <InfoLabel label="Nachname" tooltip="Tragen Sie den Nachnamen Ihres Mieters ein"/>
                <b-form-input
                    id="lastname"
                    v-model="renter.lastname"
                    :state="lastnameState"
                    required
                    placeholder="Bspw.: Mustermann"
                >
                </b-form-input>

                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="input-group-street">
                <InfoLabel label="Straße und Hausnummer" tooltip="Tragen Sie die Straße Ihres Mieters ein"/>
                <b-form-input
                    id="street"
                    v-model="renter.street"
                    :state="streetState"
                    placeholder="Bspw.: Musterstraße"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="4">
              <b-form-group id="input-group-zipCode">
                <InfoLabel label="Postleitzahl" tooltip="Tragen Sie die Postleitzahl Ihres Mieters ein"/>
                <b-form-input
                    id="street"
                    v-model="renter.zipCode"
                    :state="zipCodeState"
                    @input="zipCodeAutocomplete"
                    required
                    placeholder="Bspw.: 00000"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  Die eingetragene PLZ ist falsch
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="8" sm="12">
              <b-form-group id="input-group-city">
                <InfoLabel label="Stadt" tooltip="Tragen Sie die Stadt Ihres Mieters ein"/>
                <b-form-input
                    id="city"
                    v-model="renter.city"
                    :state="cityState"
                    required
                    placeholder="Bspw.: Musterstadt"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  Bitte tragen Sie mindestens 3 Zeichen ein.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="4">
              <b-form-group id="input-group-phone">
                <InfoLabel label="Telefonnummer" tooltip="Hier können Sie die Telefonnummer eintragen (optional)"/>
                <b-input-group append="☎">
                  <b-form-input
                      id="phone"
                      :state="phoneState"
                      v-model="renter.contactInformation.telephone"
                      placeholder="Bspw.: 0"
                  />
                </b-input-group>
                <b-form-invalid-feedback
                    :state="phoneState">
                  Die eingegebene Telefonnummer
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-group id="input-group-mobile">
                <InfoLabel label="Mobilfunknummer" tooltip="Hier können Sie die Mobilfunknummer eintragen (optional)"/>
                <b-input-group append="📱">
                  <b-form-input
                      id="mobile"
                      :state="mobileState"
                      v-model="renter.contactInformation.mobilephone"
                      placeholder="Bspw.: 0"
                  />
                </b-input-group>
                <b-form-invalid-feedback
                    :state="mobileState">
                  Die eingegebene Mobilfunknummer
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-group id="input-group-email">
                <InfoLabel label="E-Mail Adresse" tooltip="Hier können Sie die E-Mail Adresse eintragen (optional)"/>
                <b-input-group append="@">
                  <b-form-input
                      id="mobile"
                      type="email"
                      :state="emailState"
                      v-model="renter.contactInformation.email"
                      placeholder="Bspw.: info@example.de"
                  />
                </b-input-group>
                <b-form-invalid-feedback
                    :state="emailState">
                  Die eingegebene E-Mail Adresse ist nicht korrekt
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="float-right" variant="primary">Mieter speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>
import CreateUpdateRenter from "@/models/api/create-update-renter";
import ValidatorService from '@/services/validator.service';
import {Salutations} from "@/models/static/salutations";
import messageService from '@/services/message.service';
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";
import zipCodeService from '@/services/zipcode.service';

export default {
  name: "CreateRenter",
  components: {InfoLabel},
  data() {
    return {
      salutationValue: null,
      salutations: Salutations.get(),
      renter: new CreateUpdateRenter('', '', '', '', '', '', '', '', ''),
      salutationState: null,
      prenameState: null,
      lastnameState: null,
      cityState: null,
      zipCodeState: null,
      streetState: null,
      phoneState: null,
      mobileState: null,
      emailState: null,
    }
  },
  created() {
    if (this.$route.params.id != null) {
      HTTP.get("/renters/" + this.$route.params.id).then(response => {
        this.renter = response.data;
        if (this.renter.contactInformation === null || this.renter.contactInformation === undefined) {
          this.renter.contactInformation = {};
        }
        this.salutationValue = this.renter.salutation;
      });
    }
  },
  methods: {
    zipCodeAutocomplete : function () {
      zipCodeService.autocomplete(this.renter.zipCode).then(city => this.renter.city = city);
    },
    validateForm: function () {
      this.salutationState = !ValidatorService.nullSelect(this.salutationValue);
      this.prenameState = !ValidatorService.isEmptyAndRequired(this.renter.prename);
      this.lastnameState = !ValidatorService.isEmptyAndRequired(this.renter.lastname);
      this.streetState = !ValidatorService.isEmptyAndRequired(this.renter.street);
      this.cityState = !ValidatorService.isEmptyAndRequired(this.renter.city);
      this.zipCodeState = !ValidatorService.zipCode(this.renter.zipCode);
      this.phoneState = !ValidatorService.isEmptyAndNotRequired(this.renter.contactInformation.telephone);
      this.mobileState = !ValidatorService.isEmptyAndNotRequired(this.renter.contactInformation.mobilephone);
      this.emailState = !(ValidatorService.isEmptyAndNotRequired(this.renter.contactInformation.email)
          && !ValidatorService.validateEmail(this.renter.contactInformation.email));

      return this.salutationState
          && this.prenameState
          && this.streetState
          && this.cityState
          && this.zipCodeState
          && this.phoneState
          && this.mobileState
          && this.emailState
          && this.lastnameState;
    },
    onSubmitRenter: function () {
      if (!this.validateForm()) {
        return;
      }

      this.renter.salutation = this.salutationValue;
      if (this.renter.id == null) {
        return HTTP.post("/renters", this.renter).then(() => {
          this.$router.push({name: 'ListRenter'}).then(() => {
            messageService.success('Speichern erfolgreich', 'Mieter erfolgreich erstellt');
          }).catch(() => {
            messageService.error('Fehler', 'Der Mieter konnte nicht erstellt werden, bitte überprüfen Sie Ihre Eingabe');
          });
        });
      } else {
        return HTTP.put("/renters/" + this.renter.id, this.renter).then(() => {
          this.$router.push({name: 'ListRenter'}).then(() => {
            messageService.success('Speichern erfolgreich', 'Mieter erfolgreich bearbeitet');
          }).catch(() => {
            messageService.error('Fehler', 'Der Mieter konnte nicht erstellt werden, bitte überprüfen Sie Ihre Eingabe');
          });
        });
      }
    },
  }
}
</script>
<style>

</style>