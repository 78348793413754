<template>
  <b-modal :id="id" :title="title" ok-variant="danger" :ok-title="okTitle" :cancel-title="cancelTitle"
           @ok="deleteOk">{{ text }}
  </b-modal>
</template>
<script>
import HTTP from "@/http";

export default {
  name: 'BadModal',
  props: [
    'restUrl', 'modalTitle', 'modalText', 'value', 'okTitle', 'cancelTitle', 'toastMessage', 'toastTitle'
  ],
  data() {
    return {
      open: false,
      url: this.restUrl,
      title: this.modalTitle,
      text: this.modalText,
      id: this._uid.toString()
    }
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.id);
    },
    deleteOk() {
      HTTP.post(this.url).then(() => {
        this.$bvToast.toast(this.$props.toastMessage, {
          title: this.$props.toastTitle,
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: false
        });
        this.$emit('modalDone');
      });
    }
  }
}
</script>
<style>

</style>