<template>
  <div class="wrapper">
    <b-card class="form-signin" no-body>
      <b-card-header style="background: #435c75; border-radius: 0 !important;"><img src="/immolert-icon-invers.png"
                                                                                    style="max-width:190px; margin: auto; display: block; margin-bottom: 10px"/>
        <p style="font-size: 14px; text-align: center; color: #fff">
          Tragen Sie Ihre E-Mail Adresse ein, danach erhalten Sie weitere Instruktionen per E-Mail.</p></b-card-header>
      <b-card-body>
        <b-form @submit.prevent="resetPassword" v-if="!sent && !loading && !error">
          <b-form-group id="input-group-3" label="E-Mail Adresse:" label-for="inputEmail">
            <b-form-input type="email" id="inputEmail" v-model="email" :state="emailState"
                          placeholder="E-Mail Adresse"></b-form-input>
            <b-form-invalid-feedback>
              Die eingegebene E-Mail Adresse ist nicht korrekt
            </b-form-invalid-feedback>
          </b-form-group>
          <b-alert variant="danger" v-if="message!=null" show>{{ this.message }}</b-alert>
          <b-button class="btn btn-lg btn-primary btn-block" type="submit">Neues Passwort anfordern</b-button>
        </b-form>
        <Loading v-if="loading"/>
        <b-alert variant="success" :show="sent && !loading">
          Ihnen wurde eine E-Mail mit dem Resetlink für Ihr Passwort zugesendet.
        </b-alert>
        <b-alert :show="error" variant="danger">
          Leider ist bei der Anfrage ein Fehler aufgetreten, bitte versuchen Sie es später erneut.
        </b-alert>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import validatorService from '@/services/validator.service';
import HTTP from "@/http";
import Loading from "@/components/Loading";

export default {
  name: 'ResetPassword',
  components: {Loading},
  data() {
    return {
      email: null,
      loading: false,
      message: null,
      emailState: null,
      sent: false,
      error: false
    };
  },
  methods: {
    validateForm() {
      this.emailState = validatorService.validateEmail(this.email);
      return this.emailState;
    },
    resetPassword() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;
      HTTP.post("/users/reset-password", {email: this.email}).then(() => {
        this.sent = true;
      }).catch(() => {
        this.error = true;
        console.log("error");
      }).finally(() => this.loading = false);
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  background-image: linear-gradient(#435c75, #6288ac);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  margin-top: 100px;
  border: 1px solid #3b5269;

}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
