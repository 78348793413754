<template>
  <div class="wrapper">
    <b-card class="form-signin" no-body>
      <b-card-header style="background: #435c75; border-radius: 0 !important;"><img src="/immolert-icon-invers.png"
                                                                                    style="max-width:190px; margin: auto; display: block; margin-bottom: 10px"/>
      </b-card-header>
      <b-card-body class="card-body">
        <b-form @submit.prevent="setPassword" v-if="!sent && !loading && !error">
          <b-form-group id="input-group-3" label="Ihr neues Passwort:" label-for="inputEmail">
            <b-form-input type="password" id="inputEmail" v-model="password" :state="passwordState"
                          placeholder="Ein sicheres Passwort"></b-form-input>
            <b-form-invalid-feedback>
              {{ invalidMessage }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Wiederholen Sie das Passwort:" label-for="inputEmail">
            <b-form-input type="password" id="inputEmail" v-model="confirmation" :state="confirmationState"
                          placeholder="Ein sicheres Passwort"></b-form-input>
            <b-form-invalid-feedback>
              {{ invalidMessage }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-alert variant="danger" :show="message!=null">{{ this.message }}</b-alert>
          <b-button class="btn btn-lg btn-primary btn-block" type="submit">Passwort speichern</b-button>
        </b-form>
        <div v-if="loading">
          <br/>
          <Loading/>
          <br/>
        </div>
        <b-alert variant="success" :show="sent && !loading" style="margin-top: 10px">
          Ihr Passwort wurde erfolgreich geändert.<br/>
          <b-button :to="{name:'Login'}" variant="primary" block>Jetzt einloggen</b-button>
          <br/>
        </b-alert>
        <b-alert variant="danger" :show="error" style="margin-top: 10px">
          Das Passwort konnte nicht geändert werden, bitte lassen Sie sich einen neuen Link zusenden.
        </b-alert>
      </b-card-body>
    </b-card>
  </div>

</template>
<script>
import Loading from "@/components/Loading";
import validatorService from '@/services/validator.service';
import HTTP from "@/http";

export default {
  name: 'ObtainPassword',
  components: {Loading},
  data() {
    return {
      token: this.$route.params.token,
      password: null,
      confirmation: null,
      passwordState: null,
      confirmationState: null,
      loading: false,
      sent: false,
      message: null,
      error: false,
      invalidMessage: 'Das eingetragene Passwort ist zu kurz'
    }
  },
  methods: {
    validateForm() {
      this.passwordState = validatorService.isValidPassword(this.password);
      this.confirmationState = validatorService.isValidPassword(this.confirmation);
      if (this.password !== this.confirmation) {
        this.confirmationState = false;
        this.passwordState = false;
        this.invalidMessage = "Die eingetragenen Passwörter stimmen nicht überein";
        return false;
      }
      this.invalidMessage = 'Das eingetragene Passwort ist zu kurz';
      return this.passwordState && this.confirmationState;
    },
    setPassword() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;

      let postObject = {
        token: this.token,
        password: this.password,
        passwordConfirmation: this.confirmation
      };

      HTTP.post("/users/set-password", postObject)
          .then(() => this.sent = true)
          .catch(error => {
            if (error.response.status === 417) {
              this.message = "Der Passwort zurücksetzen Link ist invalide, bitte beantragen Sie einen neuen";
            }

            if (error.response.status === 412) {
              this.message = "Die beiden Passwörter stimmen nicht überein";
            }

            if (error.response.status === 404) {
              this.message = "Diesen Link gibt es nicht";
            }

            if (error.response.status === 500) {
              this.error = true;
            }
          }).finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  background-image: linear-gradient(#435c75, #6288ac);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  margin-top: 100px;
  border: 1px solid #3b5269;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
