<template>
  <b-card header="Angaben zum Mietverhältnis">
    <NoResultsCreateSuggest v-if="this.renting == null" text="Noch kein Mietverhältnis angelegt"
                            :route="{name:'CreateRenting', params: {unitId:this.unit.id}}"/>
    <b-row v-if="this.renting != null && this.renters.length > 0">
      <b-col sm="12" lg="6">
        <b-row>
          <b-col lg="6" sm="12" v-for="renter in renters" v-bind:key="renter.id">
            <b>
              <b-icon icon="person-circle"></b-icon>
              Mieterdaten</b>
            <router-link :to="{name:'CreateRenter', params: {id:renter.id}}">
              <b-icon icon="pencil"></b-icon>
            </router-link>
            <br>
            {{ renter.salutation | germanSalutation }}<br>
            {{ renter.prename }} {{ renter.lastname }}<br>
            {{ renter.street }}<br>
            {{ renter.zipCode }} {{ renter.city }}<br>
            <span v-if="renter.phone != null && renter.phone != ''"><br>Telefon: {{ renter.phone }}</span>
            <span v-if="renter.mobile != null && renter.mobile != ''"><br>Mobiltelefon: {{
                renter.mobile
              }}</span>
            <span v-if="renter.email != null && renter.email  != ''"><br>E-Mail Adresse: {{
                renter.email
              }}</span>
          </b-col>
        </b-row>

        <hr>
        <b>
          <b-icon icon="cash"></b-icon>
          Aktuelle Miete</b><br/>
        <i class="small">Ändern Sie hier die Miete, sowie den Nebenkostenabschlag.</i>
        <b-row>
          <b-col sm="12" lg="4">
            <b-form-group label="Monatsmiete">
              <b-input-group append="€">
                <b-input type="text" v-model="renting.lease"
                         :formatter="germanFormatter.formatNumber"
                         lazy-formatter
                         @change="updateLease"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12" lg="4">
            <b-form-group label="Nebenkostenabschlag">
              <b-input-group append="€">
                <b-input type="text" v-model="renting.incidentalRate"
                         :formatter="germanFormatter.formatNumber"
                         lazy-formatter
                         @change="updateIncidentalRate"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12" lg="4">
            <b-form-group label="Kaution">
              <b-input-group append="€">
                <b-input type="text" v-model="renting.surety" disabled/>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b>
          <b-icon icon="key"></b-icon>
          Ausgehändigte Schlüssel</b><br>
        <i class="small">Ändern Sie die Anzahl der ausgehändigten Schlüssel ganz einfach indem Sie die Werte
          ändern.</i>
        <br>
        <br>
        <b-row>
          <b-col>
            <b-form-group label="Türschlüssel">
              <b-form-spinbutton id="sb-inline" v-model="renting.unitKeyCount" @change="updateUnitKeyCount"
                                 inline></b-form-spinbutton>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Briefkastenschlüssel">
              <b-form-spinbutton id="sb-inline" v-model="renting.letterBoxKeyCount"
                                 @change="updateLetterboxKeyCount" inline></b-form-spinbutton>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Personen">
              <b-form-spinbutton id="sb-inline" v-model="renting.persons"
                                 @change="updatePersons" inline></b-form-spinbutton>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="6">
        <b>
          <b-icon icon="calendar-date"></b-icon>
          Vertragsdaten</b><br>
        <i class="small">Hier sehen Sie den Mietbeginn, das Mietende (falls vorhanden). Sowie das Datum der
          letzten Nebenkostenabrechnung.</i>
        <br>
        <br>
        <b-row>
          <b-col sm="12" lg="6">Start des Mietverhältnisses:</b-col>
          <b-col sm="12" lg="6">
            {{ this.renting.start|germanDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="6">
            Ende des Mietverhältnisses:
          </b-col>
          <b-col sm="12" lg="6">
            {{ this.renting.end|germanDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="6">
            Letzte Nebenkostenabrechnung:
          </b-col>
          <b-col sm="12" lg="6">
            {{ this.renting.lastBillingOfIncidentals|germanDate }}
          </b-col>
        </b-row>
        <hr>
        <b>
          <b-icon icon="file-text"></b-icon>
          Dokumente</b><br>
        <i class="small">Schauen sie sich bequem die Dokumente zum jeweiligen Mietverhältnis an &amp; bearbeiten
          Sie diese ggf.</i>
        <br>
        <br>
        <Loading v-if="downloading"/>
        <div v-if="!downloading">
          <span v-if="this.renting.contractFileId == null && this.renting.handoverCertificateFileId == null">Keine Dokumente hinterlegt</span>
          <a href="#" v-if="this.renting.contractFileId != null" :id="this.renting.contractFileId"
             @click.prevent="download">Mietvertrag herunterladen
          </a>
          <br/>
          <a href="#" v-if="this.renting.handoverCertificateFileId != null" :id="this.renting.handoverCertificateFileId"
             @click.prevent="download">Übergabeprotokoll
            herunterladen
          </a>
        </div>

        <hr>
        <b>
          <b-icon icon="shop-window"></b-icon>
          Garagen / Stellplätze</b><br>
        <i class="small">Hier sehen Sie die mit vermietete Garage / den Stellplatz</i><br><br>
        <div v-if="this.renting.garageId == null">
          Keine Garage vermietet
          <b-select :options="garages" v-model="selectedGarage">
          </b-select>
          <br/>
          <br/>
          <b-button class="float-right" :variant="selectedGarage === null ? 'secondary':'success'"
                    @click="selectGarage(selectedGarage)" :disabled="selectedGarage === null">
            Garage dem Mietverhältnis zuordnen
          </b-button>
        </div>
        <div v-if="this.renting.garageId != null && this.garage != null">
          <b-row>
            <b-col>
              <b-form-group label="Bezeichnung">
                <b-input-group>
                  <b-input type="text" :value="this.garage.name" disabled/>
                  <b-input-group-append>
                    <b-input-group-text>
                      <b-icon-info/>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mögliche Fahrzeuge">
                <b-input-group>
                  <b-input type="text" :value="this.garage.vehicles" disabled/>
                  <b-input-group-append>
                    <b-input-group-text>
                      <b-icon-truck/>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="mtl. Preis">
                <b-input-group>
                  <b-input type="text" :value="this.garage.monthlyLease" disabled/>
                  <b-input-group-append>
                    <b-input-group-text>€</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button class="float-right" @click="dropGarage" variant="warning">
            Garage vom Mietverhältnis lösen
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import HTTP from "@/http";
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import Loading from "@/components/Loading";
import {germanFormatter} from "../services/formatter";

export default {
  props: ['rentingValue', 'unit'],
  components: {Loading, NoResultsCreateSuggest},
  data() {
    return {
      renters: [],
      downloading: false,
      garages: [
        {
          value: null,
          text: 'Garage auswählen und hinzufügen'
        }
      ],
      garage: null,
      selectedGarage: null
    }
  },
  emits: ['update:rentingValue'],
  computed: {
    germanFormatter() {
      return germanFormatter
    },
    renting: {
      get() {
        return this.rentingValue
      },
      set(value) {
        this.$emit('update:rentingValue', value)
      }
    }
  },
  mounted() {
    HTTP.get("/garages?buildingId=" + this.unit.buildingId + "&noRenting=true&size=100").then(response => {
      const garageList = response.data.content;
      for (let i = 0; i < garageList.length; i++) {
        this.garages.push({
          value: garageList[i].id,
          text: garageList[i].name
        });
      }
    });

    if (this.renting != null) {
      this.loadGarage();
      this.renting.renterIds.forEach(renter => {
        HTTP.get("/renters/" + renter).then(response => {
          this.renters.push(response.data);
        })
      })

    }
  },
  methods: {
    loadGarage: function () {
      if (this.renting.garageId) {
        HTTP.get("/garages/" + this.renting.garageId).then(response => this.garage = response.data);
      }
    },
    dropGarage: function () {
      this.renting.garageId = null;
      this.updateRenting();
    },
    selectGarage: function (item) {
      if (item != null) {
        this.renting.garageId = item;
        this.updateRenting();
        this.loadGarage();
      }
    },
    updateUnitKeyCount: function (count) {
      this.renting.unitKeyCount = count;
      this.updateRenting();
    },
    updateLetterboxKeyCount: function (count) {
      this.renting.letterBoxKeyCount = count;
      this.updateRenting();
    },
    updateLease: function (lease) {
      this.renting.lease = Number.parseFloat(lease.replaceAll(",", "."));
      this.updateRenting();
    },
    updatePersons: function (persons) {
      this.renting.persons = persons;
      this.updateRenting();
    },
    updateIncidentalRate: function (incidentalRate) {
      this.renting.incidentalRate = Number.parseFloat(incidentalRate.replaceAll(",", "."));
      this.updateRenting();
    },
    updateRenting: function () {
      if (this.renting !== undefined && this.renting !== null) {
        let update = {...this.renting};
        if (update.incidentalRate) {
          update.incidentalRate = Number.parseFloat(update.incidentalRate.toString().replaceAll(",", "."));
        }

        if (update.lease) {
          update.lease = Number.parseFloat(update.lease.toString().replaceAll(",", "."));
        }
        HTTP.put("/rentings/" + this.renting.id, update).then(() => {
          this.$bvToast.toast('Die Änderung am Mietverhältnis wurde erfolgreich gespeichert', {
            title: 'Mietverhältnis angepasst',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: false
          });
        }).catch(() => {
          this.$bvToast.toast('Die Änderung am Mietverhältnis konnten nicht gespeichert werden', {
            title: 'Mietverhältnis nicht gespeichert',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          });
        });
      }
    },
    download: function (item) {
      this.downloading = true;
      HTTP.get("/files/" + item.target.id).then(response => {
        let file = response.data;
        HTTP.get("/files/download/" + file.id, {responseType: 'blob'})
            .then(response => {
              const blob = new Blob([response.data], {type: file.mimeType})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = file.originalName
              link.click()
              URL.revokeObjectURL(link.href)
            }).finally(() => this.downloading = false);
      }).catch(() => this.downloading = false)

    }
  }
}
</script>
<style>

</style>
