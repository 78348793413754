<template>
  <div class="container">
    <div class="content">
      <b-card header="Noch keine Mahnverfahren eingeleitet"
              v-if="(monitions != null && monitions.totalElements == 0) && this.unitId != null">
        <NoResultsCreateSuggest
            text="Es wurden keine Manverfahren für diese Einheit gefunden"
            :route="{name: 'CreateMonition', params: {unitId: this.unitId}}"/>
      </b-card>
      <b-card header="Alle Mahnverfahren">
        <b-row v-if="monitions != null && monitions.totalElements > 0">
          <b-col v-if="unitId == null">
            <b-form-group>
              <InfoLabel  label="Wählen Sie ein Gebäude aus" tooltip="Klicken Sie hier um Mahnungen nach Gebäude zu filtern"/>
              <b-select v-model="selectedBuilding"
                        :options="buildings"
                        @change="changeBuilding"/>
            </b-form-group>
          </b-col>
          <b-col v-if="unitId == null">
            <b-form-group>
              <InfoLabel label="Wählen Sie eine Einheit aus" tooltip="Klicken Sie hier um Mahnungen nach Einheit zu filtern"/>
              <b-select v-model="selectedUnit"
                        :options="units"
                        :disabled="selectedBuilding === null"
                        @change="loadMonitions"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="">

              <br/>
              <b-button class="float-right" variant="primary"
                        v-b-tooltip="'Klicken Sie hier um ein neues Mahnverfahren einzuleiten, Gebäude und Einheit können Sie nach Klick auf den Butten auswählen'"
                        :to="{name: 'CreateMonition', params: {unitId:this.selectedUnit}}">
                Weiteres Mahnverfahren anlegen
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-skeleton-table v-if="monitions == null"/>
        <div v-if="monitions != null && monitions.totalElements > 0">
          <b-table ref="table" class="card-table" striped hover :no-local-sorting="true"
                   v-on:sort-changed="loadMonitions" responsive="sm"
                   :items="monitions.content"
                   :fields="fields">
            <template #cell(actions)="data">
              <b-button variant="primary" size="sm" v-b-tooltip="'Mahnung anzeigen'"
                        :to="{name:'ShowMonition', params: {id: data.item.id}}">
                <b-icon icon="eye"></b-icon>
              </b-button>&nbsp;
              <b-button variant="success" v-b-tooltip="'Mahnung als bezahlt markieren'" size="sm"
                        @click="markAsPaid(data.item.id)"
                        v-if="data.item.state !== 'PAID' && data.item.state !== 'FAILED'&& data.item.state !== 'CANCELED'">
                <b-icon icon="cash"></b-icon>
              </b-button>
            </template>
            <template #cell(overdueSince)="data">
              {{ data.item.overdueSince | germanDate }}
            </template>
            <template #cell(state)="data">
              <b-badge :variant="data.item.state | monitionStateBadge">{{ data.item.state | monitionStateGerman }}
              </b-badge>
            </template>
          </b-table>
          <PageableTable :pageable="monitions" v-if="unitId != null" :to-route="'ListMonitions'"
                         :routing-params="{unitId: this.unitId}"></PageableTable>
          <PageableTable :pageable="monitions" v-if="unitId == null" :to-route="'ListAllMonitions'"></PageableTable>
        </div>
        <NoResultsCreateSuggest
            v-if="monitions !== null &&  monitions.totalElements === 0"
            text="Es wurden keine Manverfahren für dieses Gebäude gefunden"
            :route="{name: 'CreateMonition', params: {buildingId: this.selectedBuilding}}"/>

      </b-card>
    </div>
  </div>
</template>
<script>
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import PageableTable from "@/components/PageableTable";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'ListMonitions',
  components: {InfoLabel, PageableTable, NoResultsCreateSuggest},
  data() {
    return {
      unitId: this.$route.params.unitId,
      monitions: null,
      page: this.$route.params.page,
      sortStr: '',
      buildingQuery: '',
      buildings: null,
      selectedBuilding: null,
      selectedUnit: null,
      units: [{
        value: null,
        text: 'Zuerst ein Gebäude auswählen'
      }],
      fields: this.getFields(this.$route.params.unitId)
    }
  },
  created() {
    this.buildings = [];
    this.buildings.push({value: null, text: "Alle Gebäude anzeigen"});
    HTTP.get("/buildings?size=1000").then(buildingPage => {
      buildingPage.data.content.forEach(bu => {
        this.buildings.push({value: bu.id, text: bu.street + " " + bu.city});
      });
    });
    this.loadMonitions();
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.page = to.params.page;
        this.loadMonitions();
      }
    }
  },
  methods: {
    markAsPaid: function (id) {
      for (let i = 0; i < this.monitions.content.length; i++) {
        if (this.monitions.content[i].id === id) {
          HTTP.post("/process-monition/" + id + "/MARK_AS_PAID").then(() => {
            HTTP.get("/monitions/" + id).then(response => this.monitions.content[i].state = response.data.state);
          });
        }
      }

    },
    getFields: function (unitid) {
      if (unitid !== undefined && unitid !== null) {
        return [
          {
            key: "overdueSince",
            sortable: true,
            label: "Überfällig seit"
          },
          {
            key: "state",
            sortable: true,
            label: "Mahnstufe"
          },
          {
            key: "actions",
            label: "Aktionen",
            class: 'sm-col'
          }
        ]
      }
      return [
        {
          key: "building",
          label: 'Gebäude'
        },
        {
          key: "unit",
          label: 'Einheit'
        },
        {
          key: "overdueSince",
          sortable: true,
          label: "Überfällig seit"
        },
        {
          key: "state",
          sortable: true,
          label: "Mahnstufe"
        },
        {
          key: "actions",
          label: "Aktionen",
          class: 'sm-col'
        }
      ];
    },
    changeBuilding: function () {
      this.loadMonitions();
      this.selectedUnit = null;
      if (this.selectedBuilding === null) {
        this.units = [{
          value: null,
          text: 'Zuerst ein Gebäude auswählen'
        }];
        return;
      }

      HTTP.get("/units?size=999&buildingId=" + this.selectedBuilding).then(resp => {
        this.units = [{
          value: null,
          text: 'Bitte eine Auswahl treffen'
        }];

        resp.data.content.forEach(item => {
          this.units.push({
            text: item.name,
            value: item.id
          });
        });
      });
    },
    loadMonitions: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort !== null && sort !== undefined) {
        this.sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      if (this.unitId !== undefined && this.unitId != null) {
        HTTP.get("/monitions?unitId=" + this.unitId + this.sortStr + "&page=" + currentPage).then(response => {
          this.monitions = response.data;
        });
      } else {
        if (this.selectedBuilding != null) {
          this.buildingQuery = '&buildingId=' + this.selectedBuilding;
        } else if (this.selectedBuilding == null) {
          this.buildingQuery = '';
        }

        if (this.selectedUnit != null) {
          this.buildingQuery += '&unitId=' + this.selectedUnit;
        }

        HTTP.get("/monitions?page=" + currentPage + this.buildingQuery + this.sortStr).then(response => {
          this.monitions = response.data;
          for (let i = 0; i < this.monitions.content.length; i++) {
            HTTP.get("/buildings/" + this.monitions.content[i].buildingId).then(resp => {
              this.monitions.content[i].building = resp.data.street + " " + resp.data.city;
              this.$refs.table.refresh();
            });
            HTTP.get("/units/" + this.monitions.content[i].unitId).then(resp => {
              this.monitions.content[i].unit = resp.data.name;
              this.$refs.table.refresh();
            });
          }
        });
      }
    }
  }
}
</script>
<style>

</style>