<template>
  <div>
    <div v-if="!hints">
      <b-button size="sm" @click="showHint()"><b-icon icon="question-circle-fill"></b-icon> Hinweise anzeigen</b-button>
    </div>
    <p v-if="hints" style="font-size: 14px">
      <slot></slot>
    </p>
  </div>
</template>
<script>
export default {
  name: 'Infobox',
  data () {
    return {
      hints: false
    }
  },
  methods: {
    showHint() {
      this.hints = true;
    }
  }
}
</script>
<style scoped>
p {
  font-size: 14px
}

.hide {
  display: none;
}
</style>