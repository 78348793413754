<template>
  <div class="container">
    <div class="content">
      <b-card header="Lieferanten">
        <b-skeleton-table v-if="suppliers == null"/>
        <NoResultsCreateSuggest v-if="suppliers != null && suppliers.totalElements === 0"
                                :route="{name: 'CreateSupplier'}" text="Es wurde noch kein Lieferant angelegt"/>
        <b-button :to="{name: 'CreateSupplier'}" v-if="suppliers != null && suppliers.totalElements > 0"
                  variant="primary" class="float-right"
                  v-b-tooltip="'Klicken Sie hier um einen weiteren Lieferanten anzulegen'">Weiteren Lieferant anlegen
        </b-button>
        <br/>
        <b-table :no-local-sorting="true"
                 v-on:sort-changed="load"
                 striped hover
                 responsive
                 class="card-table"
                 :items="suppliers.content"
                 :fields="fields"
                 v-if="suppliers != null && suppliers.totalElements > 0">
          <template #cell(actions)="data">
            <b-button size="sm" :to="{name:'ShowSupplier', params: {id:data.item.id}}"
                      v-b-tooltip.leftbottom="'Klicken Sie hier um den Lieferanten anzuzeigen'"
                      variant="primary">
              <b-icon icon="eye"></b-icon>
            </b-button>&nbsp;&nbsp;
            <b-button size="sm" :to="{name:'CreateSupplier', params: {id:data.item.id}}"
                      v-b-tooltip.leftbottom="'Klicken Sie hier um den Lieferanten zu bearbeiten'" variant="warning">
              <b-icon icon="pencil"></b-icon>
            </b-button>&nbsp;&nbsp;
            <b-button size="sm" @click.prevent="deleteModal(data.item.id)"
                      v-b-tooltip.leftbottom="'Klicken Sie hier um den Lieferanten zu löschen'" variant="danger">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>

          <template #cell(type)="data">
            {{ data.item.type | germanSupplierType }}
          </template>
        </b-table>
        <PageableTable v-if="suppliers != null && suppliers.totalElements > 0" :pageable="suppliers"/>
      </b-card>
      <b-modal id="delete-modal" ok-variant="danger" title="Lieferanten löschen?" @ok="deleteSupplier()">Möchten Sie den
        ausgewählten Lieferanten wirklich löschen?
      </b-modal>
    </div>
  </div>
</template>
<script>
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import messageService from '@/services/message.service';
import PageableTable from "@/components/PageableTable";
import HTTP from "@/http";

export default {
  name: 'ListSuppliers',
  components: {PageableTable, NoResultsCreateSuggest},
  data() {
    return {
      suppliers: null,
      deleteId: null,
      fields: [
        {
          key: 'firm',
          label: 'Firma',
          sortable: true
        },
        {
          key: 'prename',
          label: 'Vorname',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Nachname',
          sortable: true
        },
        {
          key: 'type',
          label: 'Typ',
          sortable: true
        }, {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      sortStr: ''
    }
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.page = to.params.page;
        this.load();
      }
    }
  },
  created() {
    this.load();
  },
  methods: {
    load: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort !== undefined) {
        this.sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/suppliers?page=" + currentPage + this.sortStr).then(response => this.suppliers = response.data);
    },
    deleteModal: function (id) {
      this.deleteId = id;
      this.$bvModal.show("delete-modal");
    },
    deleteSupplier: function () {
      HTTP.delete('/suppliers/' + this.deleteId).then(() => {
        this.load();
        messageService.success('Löschen erfolgreich', 'Der Lieferant wurde erfolgreich gelöscht');
      });
    }
  }
}
</script>

<style>
</style>