export default class CreateUpdateRenting {
    constructor(unitId, renterId, start, end, buildingId, lease, incidentalRate, contractFileId, handoverCertificateFileId, letterBoxKeyCount, unitKeyCount, renterIds) {
        this.unitId = unitId;
        this.renterId = renterId;
        this.start = start;
        this.end = end;
        this.buildingId = buildingId;
        this.lease = lease;
        this.incidentalRate = incidentalRate;
        this.contractFileId = contractFileId;
        this.handoverCertificateFileId = handoverCertificateFileId;
        this.letterBoxKeyCount = letterBoxKeyCount;
        this.unitKeyCount = unitKeyCount;
        this.renterIds= renterIds
    }
}
