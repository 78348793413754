<template>
  <div class="text-center">
    Daten werden abgerufen, einen Moment bitte<br/>
    <b-icon animation="cylon" icon="three-dots"></b-icon>
  </div>
</template>
<script>
export default {
  name: 'Loading'
}
</script>
<style>

</style>