<template>
  <div>
    <b-skeleton-table v-if="files == null"/>
    <NoResultsCreateSuggest text="Es wurden noch keine Dokumente angelegt"
                            :route="{name: 'CreateDocumentFile', params: {'entityId': entityId, 'entityType': entityType, 'returnTo': stringRoute}}"
                            v-if="files != null && files.totalElements === 0"/>
    <b-button v-if="files != null && files.totalElements > 0"
              :to="{name: 'CreateDocumentFile', params: {'entityId': entityId, 'entityType': entityType, 'returnTo': stringRoute}}"
              class="float-right" variant="primary">
      Weiteres Dokument anlegen
    </b-button>
    <b-table v-if="files != null && files.totalElements > 0"
             :no-local-sorting="true"
             v-on:sort-changed="loadFiles"
             striped
             :class="tblClass"
             :items="files.content"
             responsive
             :fields="fields">
      <template #cell(created)="data">
        {{ data.item.created | germanDate }}
      </template>
      <template #cell(actions)="data">
        <b-button :to="{name:'ShowDocumentFile', params: {'id':data.item.id}}"
                  size="sm" variant="primary"
                  v-b-tooltip.leftbottom="'Klicken Sie hier um sich das Dokument anzeigen zu lassen.'">
          <b-icon icon="eye" font-scale="1"></b-icon>
        </b-button>&nbsp;&nbsp;
        <b-button size="sm" variant="danger" @click.prevent="deleteDocumentFileModal(data.item.id)"
                  v-b-tooltip.leftbottom="'Klicken Sie hier um das Dokument zu löschen.'">
          <b-icon icon="trash" font-scale="1"></b-icon>
        </b-button>
      </template>
    </b-table>
    <RouterlessPageable v-if="files != null && files.totalElements > 0" :pageable="files"
                        v-on:change="loadFilesPaged"/>

    <DeleteModal ref="deldfmodal" modal-text="Dokument wirklich löschen" modal-title="Dokument löschen?"
                 v-model="deleteId"
                 rest-url="document-files" @deleted="loadFiles"/>
  </div>
</template>
<script>
import RouterlessPageable from "@/components/RouterlessPageable";
import DeleteModal from "@/components/DeleteModal";
import HTTP from "@/http";
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";

export default {
  name: 'DocumentFileList',
  props: ['entityId', 'entityType', 'tableClass', 'returnTo'],
  components: {NoResultsCreateSuggest, RouterlessPageable, DeleteModal},
  created() {
    this.loadFiles();
  },
  data() {

    return {
      files: null,
      sortStr: '',
      deleteId: null,
      tblClass: this.$props.tableClass === undefined || this.$props.tableClass === null ? "" : this.$props.tableClass,
      stringRoute: this.$props.returnTo === undefined ? encodeURIComponent(this.$route.fullPath) : this.getRoute(this.$props.returnTo),
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "created",
          label: "Datum",
          sortable: true
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
          class: 'sm-col'
        }
      ],
      page: 0
    }
  },
  methods: {
    getRoute(returnTo) {
      return this.$router.match(returnTo).fullPath;
    },
    deleteDocumentFileModal: function (id) {
      this.deleteId = id;
      this.$refs.deldfmodal.openModal();
    },
    loadFilesPaged: function (page) {
      this.page = page - 1;
      this.loadFiles();
    },
    loadFiles: function (sort) {
      if (sort !== undefined && sort !== null) {
        this.sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      HTTP.get("/document-files?entityId=" + this.entityId + "&entityType=" + this.entityType + "&page=" + this.page + this.sortStr)
          .then(response => this.files = response.data);

    }
  }
}
</script>
<style scoped>

</style>