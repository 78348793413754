export class Salutations {
  static get() {
      return [
          {value: null, text: "Bitte wählen"},
          {value: 'MR', text: 'Herr'},
          {value: 'MRS', text: 'Frau'},
          {value: 'DIVERS', text: 'Divers'},
          {value: 'FIRM', text: 'Firma'}
      ];
  }

  static germanSalutation(KEY) {
      switch (KEY) {
          case "MR":
              return "Herr";
          case "MRS":
              return "Frau";
          case "DIVERS":
              return "Divers";
          case "FIRM":
              return "Firma";
      }
  }
}