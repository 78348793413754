<template>
  <div class="container">
    <div class="content">
      <b-row v-if="!isCompanyUser">
        <b-col>
          <b-card class="text-center" header="Stammdaten">
            Nutzereinstellungen sind nur für Haupaccounts verfügbar
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="isCompanyUser">
        <b-col sm="12" lg="6">
          <b-card header="Stammdaten" v-if="loading">
            <Loading/>
          </b-card>
          <b-card header="Stammdaten" v-if="!loading">
            <b-form @submit.prevent="updateMasterData" class="text-left" novalidate>
              <b-form-group id="input-group-salutation" label="Anrede" label-for="salutation">
                <b-form-select v-model="selected" :options="this.salutations" :state="salutationState" id="salutation">
                </b-form-select>
                <b-form-invalid-feedback>{{ this.validator.noSelectionMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-if="this.selected === 'FIRM'" id="input-group-firm" label="Name der Firma:"
                            label-for="firm">
                <b-form-input type="text" id="firm" v-model="user.firm" required
                              placeholder="Firma"></b-form-input>
              </b-form-group>
              <b-form-group v-if="this.selected === 'FIRM'" id="input-group-tax" label="Ust. Ident.:" label-for="tax">
                <b-form-input type="text" id="tax" v-model="user.ustId"
                              placeholder="Ust Ident"></b-form-input>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group id="input-group-prename" label="Dein Vorname:" label-for="prename">
                    <b-form-input type="text" id="prename"
                                  :state="prenameState"
                                  v-model="user.prename"
                                  required
                                  placeholder="Vorname"></b-form-input>
                    <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-lastname" label="Dein Nachname:" label-for="lastname">
                    <b-form-input type="text" id="lastname" v-model="user.lastname" required
                                  placeholder="Nachname" :state="lastnameState"></b-form-input>

                    <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="input-group-street" label="Straße und Hausnummer:" label-for="street">
                <b-form-input type="text" id="street" v-model="user.street" required
                              placeholder="Strasse und Hausnummer" :state="streetState"></b-form-input>

                <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col cols="3">
                  <b-form-group id="input-group-zipCode" label="PLZ:" label-for="zipCode">
                    <b-form-input type="text" id="zipCode" v-model="user.zipCode" required
                                  :state="zipCodeState"
                                  placeholder="PLZ"></b-form-input>
                    <b-form-invalid-feedback>{{ this.validator.notValidZipCodeMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-city" label="Stadt:" label-for="city">
                    <b-form-input type="text" id="city"
                                  v-model="user.city" required
                                  :state="cityState"
                                  placeholder="Stadt"></b-form-input>
                    <b-form-invalid-feedback>{{ this.validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="input-group-phone" label="Telefonnummer:" label-for="phone">
                    <b-input-group append="☎">
                      <b-form-input type="text" id="phone" v-model="user.contactInformation.telephone" required
                                    placeholder="Telefonnummer"
                                    :state="telephoneState"></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="telephoneState">{{
                        this.validator.toShortMessage()
                      }}
                    </b-form-invalid-feedback>

                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-mobile" label="Mobilfunk:" label-for="mobile">
                    <b-input-group append="📱">
                      <b-form-input type="text" id="mobile"
                                    :state="mobilephoneState"
                                    v-model="user.contactInformation.mobilephone"
                                    placeholder="Mobilfunknummer"></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback
                        :state="mobilephoneState">{{ this.validator.toShortMessage() }}
                    </b-form-invalid-feedback>

                  </b-form-group>
                </b-col>
              </b-row>
              <button class="btn btn-success float-right" type="submit">Änderungen
                speichern
              </button>
            </b-form>
          </b-card>
        </b-col>
        <b-col sm="12" lg="6">
          <b-card header="Ihre Rechnungen" v-if="loading">
            <Loading/>
          </b-card>
          <b-card header="Ihre Rechnungen" v-if="!loading">
            <b-table :items="invoices" :fields="invoiceFields" v-if="invoices.length > 0">
              <template #cell(status)="data">
                <b-badge :variant=" data.item.status | invoiceBadge">{{
                    data.item.status | invoiceStatusGerman
                  }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button variant="primary" @click="loadInvoice(data.item.id)">
                  <b-icon icon="eye" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
            <div class="text-center" v-else>
              <b-icon icon="question-square" font-scale="3"/>
              <br/><br/>Noch keine Rechnungen vorhanden
            </div>
          </b-card>
          <b-card header="Ihr Account" v-if="loading && !user.testAccount">
            <Loading/>
          </b-card>
          <b-card header="Ihr Account" v-if="!loading && !user.testAccount">
            <b-form-group label="Ihre Zahlweise anpassen:">
              <b-row style="padding-top: 15px">
                <b-col>
                  <b-form-radio v-model="user.paymentType" value="INVOICE"> Überweisung
                  </b-form-radio>
                </b-col>
                <b-col>
                  <b-form-radio v-model="user.paymentType" value="SEPA"> SEPA Lastschrift
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-group>
            <hr v-if="user.paymentType === 'SEPA'"/>
            <b-form v-if="user.paymentType === 'SEPA'">
              <b-row>
                <b-col>
                  <b-form-group label="Ihre IBAN:">
                    <b-form-input type="text"
                                  v-b-tooltip="'Tragen Sie hier Ihre IBAN ein'"
                                  v-model="bankingData.iban"
                                  :state="ibanState"
                                  placeholder="Eine gültige IBAN"></b-form-input>
                    <b-form-invalid-feedback>Bitte ein korrekte IBAN eintragen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Ihre BIC:">
                    <b-form-input type="text"
                                  v-b-tooltip="'Tragen Sie hier Ihre BIC ein'"
                                  v-model="bankingData.bic"
                                  :state="bicState"
                                  placeholder="Eine gültige BIC"></b-form-input>
                    <b-form-invalid-feedback>Bitte ein korrekte BIC eintragen</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <b-button class="fa-pull-right" @click="changeBankingData()" variant="success">Zahlungsweise ändern
            </b-button>
            <div class="clearfix"></div>
          </b-card>
          <b-card header="Kündigung" v-if="!user.testAccount">
            <div class="text-center" v-if="user.end === null">
              <b-button class="btn-danger btn btn-block" @click="cancelDialog()">Zum nächsten möglichen Zeitpunkt
                kündigen
              </b-button>
            </div>
            <div class="text-center" v-if="user.end !== null">
              <b-alert class="alert-danger block" show>
                Immolert wurde zum {{ user.end | germanDate }} gekündigt.
                <br/>Nach diesem Datum haben Sie keinen Zugriff mehr auf Ihren Account!<br/>
                Datensicherungen müssen vorher durch Sie erfolgen.
              </b-alert>
            </div>
          </b-card>
          <b-card header="Sie nutzen einen kostenfreien Testaccount" v-if="user.testAccount">
            <b-alert class="alert-danger block text-center" show>
              Ihr kostenloser Immolert Account wird zum {{ user.end | germanDate }} deaktiviert.
              <br/>Nach diesem Datum haben Sie keinen Zugriff mehr auf Ihren Account!<br/>
              Datensicherungen müssen vorher durch Sie erfolgen.
            </b-alert>
            <b-button block variant="success" @click="$router.push({name:'Book'})">Jetzt für nur 59,99€ pro Jahr
              abonnieren
            </b-button>
          </b-card>
          <b-card header="Passwort ändern" v-if="!loading">
            <b-button type="submit" block variant="success" @click="resetPwEmail()">Passwort wiederherstellen E-Mail
              zusenden
            </b-button>
          </b-card>
          <b-card header="Passwort ändern" v-if="loading">
            <Loading/>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <bad-modal ref="cancelmodal" modal-text="Möchten Sie immolert wirklich Kündigen?"
               toast-title="Erfolgreich"
               toast-message="Ihr Abonnement wurde erfolgreich gekündigt"
               modal-title="Immolert kündigen?" ok-title="Ja verbindlich Kündigen" cancel-title="Abbrechen"
               rest-url="users/cancel" @modalDone="cancel()"/>
  </div>
</template>
<script>
import messageService from '@/services/message.service';
import {Salutations} from "@/models/static/salutations";
import validator from '@/services/validator.service';
import AuthService from '@/services/auth.service';
import CreateUser from "@/models/api/create-user";
import VueJwtDecode from 'vue-jwt-decode'
import HTTP from "@/http";
import Loading from "@/components/Loading";
import BadModal from "../../components/BadModal";
import {use} from "vee-validate/dist/vee-validate.minimal.esm";

export default {
  name: 'UserSetting',
  components: {BadModal, Loading},
  data() {
    return {
      loading: true,
      user: new CreateUser('', null, null, null, null),
      selected: null,
      subaccounts: null,
      oldpw: null,
      newpw: null,
      newpwRepeat: null,
      ibanState: null,
      bicState: null,
      bankingData: {},
      isCompanyUser: false,
      salutations: Salutations.get(),
      invoiceFields: [
        {
          key: 'invoiceNumber',
          label: 'Rechnungsnummer'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ],
      invoices: [],
      subAccountFields: [
        {
          key: 'prename',
          label: 'Vorname'
        },
        {
          key: 'lastname',
          label: 'Nachname'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ],
      page: this.$route.params.page,
      jwt: VueJwtDecode.decode(localStorage.getItem("token")),
      deleteSubUserId: null,
      salutationState: null,
      prenameState: null,
      lastnameState: null,
      cityState: null,
      zipCodeState: null,
      telephoneState: null,
      mobilephoneState: null,
      streetState: null,
      countryState: null,
      validator: validator
    }
  },
  created() {
    this.isCompanyUser = this.jwt.companyId === undefined || this.jwt.companyId === null;

    HTTP.get("/users/banking-data/" + this.jwt.userId).then(response => {
      this.bankingData = response.data;
    });
    HTTP.get("/user-invoices/?userId=" + this.jwt.userId).then(response => {
      this.invoices = response.data.content;
    });

    HTTP.get("/users/" + this.jwt.userId).then(response => {
      this.user = response.data;
      if (this.user.contactInformation == null) {
        this.user.contactInformation = {};
      }

      if (this.user.country == null) {
        this.user.country = "DE";
      }

      this.selected = this.user.salutation;
      this.loading = false;
    });
  },
  methods: {
    use() {
      return use
    },
    loadInvoice: function (id) {
      HTTP.get("/user-invoices/data/" + id, {responseType: 'blob'}).then(response => {
        const blob = new Blob([response.data], {type: "application/pdf"})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "Rechnung.pdf"
        link.click()
        URL.revokeObjectURL(link.href)
      });
    },
    changeBankingData: function () {
      if (!this.validateForm()) {
        return false;
      }

      if (this.user.paymentType === "INVOICE") {
        this.bankingData = {iban: "", bic: ""};
      }
      this.updateMasterData();
      HTTP.put("/users/banking-data/" + this.jwt.userId, this.bankingData).then(() => {
        messageService.success("Bankdaten hinterlegt", "Ihre Zahlungsinformationen wurden angepasst.");

      });
    },
    resetPwEmail: function () {
      this.loading = true;
      HTTP.post("/users/reset-password", {email: this.user.email}).then(() => {
        this.sent = true;
        messageService.success("Email gesendet", "Ihnen wurde eine E-Mail mit den Instruktionen gesendet");
      }).finally(() => this.loading = false);
    },
    validateForm: function (sepacheck) {
      this.salutationState = !validator.nullSelect(this.selected);
      this.prenameState = !validator.isEmptyAndRequired(this.user.prename);
      this.lastnameState = !validator.isEmptyAndRequired(this.user.lastname);
      this.streetState = !validator.isEmptyAndRequired(this.user.street);
      this.cityState = !validator.isEmptyAndRequired(this.user.city);
      this.zipCodeState = !validator.zipCode(this.user.zipCode);
      this.mobilephoneState = !validator.isEmptyAndNotRequired(this.user.contactInformation.mobilephone);
      this.telephoneState = !validator.isEmptyAndNotRequired(this.user.contactInformation.telephone);

      this.ibanState = validator.isValidIBANNumber(this.bankingData.iban) || this.user.paymentType !== "SEPA" || sepacheck;
      this.bicState = validator.isValidBicNumber(this.bankingData.bic) || this.user.paymentType !== "SEPA" || sepacheck;

      return this.salutationState
          && this.prenameState
          && this.lastnameState
          && this.streetState
          && this.cityState
          && this.zipCodeState
          && this.mobilephoneState
          && this.ibanState
          && this.bicState
          && this.telephoneState;
    },
    updateMasterData: function () {
      if (!this.validateForm(true)) {
        messageService.hint('Daten nicht gespeichert', 'Ihre Eingaben sind fehlerhaft');
        return;
      }

      this.loading = true;
      this.user.salutation = this.selected;
      if (this.user.salutation !== "Firma") {
        this.user.firm = null;
        this.user.ustId = null;
      }
      delete this.user.id;
      delete this.user.email;
      delete this.user.lastUpdate;
      delete this.user.active;
      delete this.user.overdue;
      delete this.user.end;
      delete this.user.companyId;
      delete this.user.roles;
      delete this.user.created;

      HTTP.put("/users/" + this.jwt.userId, this.user).then(() => {
        messageService.success('Ändern erfolgreich', 'Deine Daten wurde erfolgreich geändert');
      }).finally(() => {
        this.loading = false
      });
    },
    changePassword: function () {
      AuthService.changePassword(this.oldpw, this.newpw, this.newpwRepeat)
          .then(() => {
            messageService.success('Ändern erfolgreich', 'Passwort wurde erfolgreich geändert');
          })
          .catch(err => {
            console.log(err);
            messageService.error('Ändern fehlgeschlagen', 'Passwort konnte nicht geändert werden');
          });
    },
    cancelDialog: function () {
      this.$refs.cancelmodal.openModal();
    },
    cancel: function () {
      HTTP.get("/users/" + this.jwt.userId).then(response => {
        this.user = response.data;
        if (this.user.contactInformation == null) {
          this.user.contactInformation = {};
        }

        if (this.user.country == null) {
          this.user.country = "DE";
        }

        this.selected = this.user.salutation;
        this.loading = false;
      });
    }
  }
}
</script>
<style>

</style>