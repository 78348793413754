<template>
  <div>
    <b-card header="Kontaktinformationen" v-if="this.hasData()">
      <span v-if="contactInformation.telephone !== null && contactInformation.telephone!== ''"><b-icon
          icon="telephone"></b-icon>
      Telefonnummer: <a :href="telStr(this.contactInformation.telephone)">{{
            this.contactInformation.telephone
          }}</a><br/></span>
      <span v-if="contactInformation.mobilephone !== null && contactInformation.mobilephone!== ''"><b-icon
          icon="phone"></b-icon>
        Mobilfunk: <a :href="telStr(this.contactInformation.mobilephone)">{{
            this.contactInformation.mobilephone
          }}</a><br/></span>
      <span v-if="contactInformation.email && contactInformation.email!== ''"><b-icon icon="envelope"></b-icon>
      E-Mail Adresse: <a :href="mailStr(this.contactInformation.email)">{{
            this.contactInformation.email
          }}</a><br/></span>
      <span v-if="contactInformation.fax !== null && contactInformation.fax !== ''"><b-icon icon="printer"></b-icon>
      Fax: {{ this.contactInformation.fax }}</span>
    </b-card>
    <b-card header="Kontaktinformationen" v-if="!hasData()" class="text-center">
      Es wurden keine Kontaktinformationen gefunden
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'ContactInfo',
  props: ['info'],
  data() {
    return {
      contactInformation: this.info
    }
  },
  methods: {
    hasData: function () {
      return !(this.contactInformation === undefined)
          && !(this.contactInformation === null)
          && (this.contactInformation.telephone != null
              || this.contactInformation.mobilephone != null
              || this.contactInformation.email != null
              || this.contactInformation.fax != null);
    },
    telStr: function (number) {
      return "tel://" + number;
    },
    mailStr: function (email) {
      return "mailto:" + email;
    }
  }
}
</script>
<style>

</style>