<template>
  <div class="container">
    <div class="content" v-if="unit !== null">
      <b-card header="Archiviertes Mietverhältnis">
        <router-link :to="{name:'ShowUnit', params: {id:unit.id}}" tag="button" class="btn btn-primary">Zurück zur
          Einheit
        </router-link>
        <b-button @click.prevent="deleteRentingModal" variant="danger" class="float-right">Mietverhältnis löschen
        </b-button>
      </b-card>
      <br>
      <Renting :unit="unit" :renting-value.sync="this.renting" :renter="this.renter"/>
    </div>

    <b-modal id="delete-renting-modal" title="Mietverhältnis löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen" @ok="deleteRenting()">Möchten Sie das Mietverhältnis wirklich löschen?
    </b-modal>
  </div>
</template>
<script>
import messageService from '@/services/message.service';
import Renting from "@/components/Renting";
import HTTP from "@/http";

export default {
  name: 'ShowRenting',
  components: {Renting},
  data() {
    return {
      renting: null,
      renter: null,
      unit: null,
      prevRoute: null
    }
  },
  created() {
    HTTP.get('/rentings/' + this.$route.params.id).then(resp => {
      this.renting = resp.data;
      HTTP.get('/renters/' + this.renting.renterId).then(resp => this.renter = resp.data);
      HTTP.get('/units/' + this.renting.unitId).then(resp => this.unit = resp.data);
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    deleteRentingModal: function () {
      this.$bvModal.show("delete-renting-modal");
    },
    deleteRenting: function () {
      HTTP.delete("/rentings/" + this.$route.params.id).then(() => {
        this.$router.push(this.prevRoute).then(() => {
          messageService.success('Löschen erfolgreich', 'Mietverhältnis erfolgreich gelöscht');
        });
      }).catch(()=>{
        messageService.hint('Nicht gelöscht','Das Mietverhältnis konnte nicht gelöscht werden, weil noch Nebenkostenabrechnungen existieren');
      });
    }
  }
}
</script>
<style>

</style>