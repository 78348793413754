<template>
  <span class="tag" v-if="name !== undefined">
    {{ name }} <button class="tag-rm" @click="remove">x</button>
  </span>
</template>
<script>
import HTTP from "../../http";

export default {
  name: 'Tag',
  props: ['id', 'name', 'entityId', 'entityType'],
  data() {
    return {}
  },
  methods: {
    remove() {
      HTTP.delete("/tags/" + this.$props.id).then(() => {
        this.$emit("deleted");
      });
    }
  }
}
</script>
<style scoped>
.tag {
  background: #435c75;
  color: #ffffff;
  padding: 4px 4px 4px 10px;
  border-radius: 6px;
  margin-right: 4px;
  margin-top: 4px;
  display: inline-block;
}

.tag-rm {
  background: none;
  border: 0;
  color: #999;
  font-weight: bold;
  font-size: 14px;
  padding-left: 4px;
}

.tag-rm:hover {
  color: #fff;
}
</style>