<template>
  <div class="mt-3" v-if="pageable != null">
    <b-pagination v-model="currentPage"
                  :total-rows="this.pageable.totalElements"
                  :per-page="this.pageable.pageSize"
                  align="center"></b-pagination>
  </div>
</template>
<script>
export default {
  name: 'RouterlessPageable',
  props: ['pageable'],
  data() {
    return {
      currentPage: this.pageable.page + 1
    }
  },
  watch: {
    currentPage: function (to, from) {
      console.log(from);
      console.log(to);
      if (from == null || to !== from) {
        this.$emit('change', to)
      }
    }
  },
  methods: {}
}
</script>
<style>

</style>