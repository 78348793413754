<template>
  <div class="container">
    <div class="content">
      <b-card header="Schreiben erstellen" v-if="!loading">
        <b-form @submit.prevent="createPdf">
          <b-row>
            <b-col lg="6" sm="12">
              <b-form-group label="Versender Anrede">
                <b-form-select :options="salutations"
                               :state="selectedSalutationState"
                               v-model="selectedSalutation"></b-form-select>
                <b-form-invalid-feedback>{{ validator.noSelectionMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group label="Versender Vorname">
                    <b-form-input type="text" v-model="user.prename" :state="prenameState"/>
                    <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group label="Versender Nachname">
                    <b-form-input type="text" v-model="user.lastname" :state="lastnameState"/>
                    <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Versender Straße">
                <b-form-input type="text" v-model="user.street" :state="streetState"/>
                <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col lg="3" sm="12">
                  <b-form-group label="Versender PLZ">
                    <b-form-input type="text" v-model="user.zipCode" :state="zipCodeState"/>
                    <b-form-invalid-feedback>{{ validator.notValidZipCodeMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="12" lg="9">
                  <b-form-group label="Versender Ort">
                    <b-form-input type="text" v-model="user.city" :state="cityState"/>
                    <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group label="Mieter Anrede">
                <b-form-select :options="salutations" v-model="selectedRenterSalutation"
                               :state="renterSalutationState"></b-form-select>
                <b-form-invalid-feedback>{{ validator.noSelectionMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group label="Mieter Vorname">
                    <b-form-input type="text" v-model="renter.prename" :state="renterPrenameState"/>
                    <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group label="Mieter Nachname">
                    <b-form-input type="text" v-model="renter.lastname" :state="renterLastnameState"/>
                    <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Mieter Straße">
                <b-form-input type="text" v-model="renter.street" :state="renterStreetState"/>
                <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col lg="3" sm="12">
                  <b-form-group label="Mieter PLZ">
                    <b-form-input type="text" v-model="renter.zipCode" :state="renterZipCodeState"/>
                    <b-form-invalid-feedback>{{ validator.notValidZipCodeMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="9" sm="12">
                  <b-form-group label="Mieter Ort">
                    <b-form-input type="text" v-model="renter.city" :state="renterCityState"/>
                    <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr/>
          <b-form-group label="Betreff">
            <b-form-input type="text" v-model="subject" :state="subjectState"/>
            <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
          </b-form-group>
          <hr/>
          <b-form-group label="Anschreiben">
            <b-form-textarea rows="5" type="text" v-model="text" :state="textState"/>
            <b-form-invalid-feedback>{{ validator.toShortMessage() }}</b-form-invalid-feedback>
          </b-form-group>
          <b-button type="submit" variant="primary" class="float-right">PDF Dokument erstellen</b-button>
        </b-form>
      </b-card>
      <b-card header="Datei wird erstellt" v-if="loading">
        <div class="text-center">
          <b-icon animation="cylon" icon="three-dots"></b-icon>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import messageService from '@/services/message.service';
import {Salutations} from "@/models/static/salutations";
import validator from '@/services/validator.service';
import VueJwtDecode from "vue-jwt-decode";
import HTTP from "@/http";
import {germanFormatter} from "@/services/formatter";
import moment from "moment";


export default {
  name: 'ProcessMonition',
  data() {
    return {
      validator: validator,
      monition: {},
      user: {},
      salutations: Salutations.get(),
      selectedSalutation: null,
      selectedSalutationState: null,
      selectedRenterSalutation: null,
      renting: {},
      renter: {},
      unit: {},
      loading: false,
      jwt: VueJwtDecode.decode(localStorage.getItem("token")),
      subject: "Freundliche Zahlungserinnerung",
      subject1: "1. Mahnung",
      subject2: "Letzte Mahnung",
      text: "laut unserem Mietvertrag vom (Datum Mietstart) für das oben genannte Mietobjekt, haben Sie sich zur Zahlung einer monatlichen Miete in Höhe von (Betrag) zum (Zahltag) verpflichtet.\n\n" +
          "Leider ist bis heute noch keine Zahlung eingegangen. Bitte begleichen Sie den offenen Mietrückstand bis spätestens (Datum).\n",
      text1: "laut unserem Mietvertrag vom (Datum Mietstart) für das oben genannte Mietobjekt, haben Sie sich zur Zahlung einer monatlichen Miete in Höhe von (Betrag) zum (Zahltag) verpflichtet.\n\n" +
          "Leider ist bis heute noch keine Zahlung eingegangen. Bitte begleichen Sie den offenen Mietrückstand unverzüglich.\n",
      text2: "laut unserem Mietvertrag vom (Datum Mietstart) für das oben genannte Mietobjekt, haben Sie sich zur Zahlung einer monatlichen Miete in Höhe von (Betrag) zum (Zahltag) verpflichtet.\n\n" +
          "Leider ist bis heute noch keine Zahlung eingegangen. Bitte begleichen Sie den offenen Mietrückstand unverzüglich. Andernfalls werden rechtliche Schritte gegen Sie eingeleitet.\n",
      prenameState: null,
      lastnameState: null,
      cityState: null,
      streetState: null,
      zipCodeState: null,
      salutationState: null,
      renterPrenameState: null,
      renterLastnameState: null,
      renterCityState: null,
      renterStreetState: null,
      renterZipCodeState: null,
      renterSalutationState: null,
      subjectState: null,
      textState: null,
    }
  },
  created() {
    this.load();

  },
  methods: {
    validateForm: function () {
      this.prenameState = !validator.isEmptyAndRequired(this.user.prename);
      this.lastnameState = !validator.isEmptyAndRequired(this.user.lastname);
      this.streetState = !validator.isEmptyAndRequired(this.user.street);
      this.zipCodeState = !validator.zipCode(this.user.zipCode);
      this.cityState = !validator.isEmptyAndRequired(this.user.city);
      this.selectedSalutationState = !validator.nullSelect(this.selectedSalutation);

      this.renterSalutationState = !validator.nullSelect(this.selectedRenterSalutation);
      this.renterPrenameState = !validator.isEmptyAndRequired(this.renter.prename);
      this.renterLastnameState = !validator.isEmptyAndRequired(this.renter.lastname);
      this.renterStreetState = !validator.isEmptyAndRequired(this.renter.street);
      this.renterZipCodeState = !validator.zipCode(this.renter.zipCode);
      this.renterCityState = !validator.isEmptyAndRequired(this.renter.city);

      this.textState = !validator.isEmptyAndRequired(this.text);
      this.subjectState = !validator.isEmptyAndRequired(this.subject);

      return this.prenameState
          && this.selectedSalutationState
          && this.renterSalutationState
          && this.lastnameState
          && this.cityState
          && this.zipCodeState
          && this.streetState
          && this.renterPrenameState
          && this.renterLastnameState
          && this.renterStreetState
          && this.renterZipCodeState
          && this.renterSalutationState
          && this.subjectState
          && this.textState;
    },
    load: function () {
      HTTP.get("/monitions/" + this.$route.params.id).then(response => {
        this.monition = response.data;
        switch (this.monition.state) {
          case "PAYMENT_REMINDER_SENT":
            this.text = this.text1;
            this.subject = this.subject1;
            break;
          case "FIRST_MONITION_SENT":
            this.text = this.text2;
            this.subject = this.subject2;
            break;
        }
        HTTP.get("/units/" + this.monition.unitId).then(response => {
          this.unit = response.data;
          if (this.unit.currentRentingId !== null) {
            HTTP.get("/rentings/" + this.unit.currentRentingId).then(response => {
              this.renting = response.data;
              this.replaceValuesInText();
            });
          }

          if (this.unit.currentRenterId !== null) {
            HTTP.get("/renters/" + this.unit.currentRenterId).then(response => {
              this.renter = response.data;
              this.selectedRenterSalutation = this.renter.salutation;
            });
          }
        });
      });

      HTTP.get("/users/" + this.jwt.userId).then(response => {
        this.user = response.data;
        this.selectedSalutation = this.user.salutation;
      });
    },
    replaceValuesInText: function () {
      const date = germanFormatter.formatDate(this.renting.start);
      const in7Days = germanFormatter.formatDate(moment().add('Days',7));

      this.text = this.text.replace("(Datum Mietstart)", date);
      this.text = this.text.replace("(Datum)", in7Days);
      this.text1 = this.text1.replace("(Datum Mietstart)", date);
      this.text1 = this.text1.replace("(Datum)", in7Days);
      this.text2 = this.text2.replace("(Datum Mietstart)", date);
      this.text2 = this.text2.replace("(Datum)", in7Days);

      if(!this.renting.lease) {
        return;
      }

      const incidentalRate = this.renting.incidentalRate;
      const rent = germanFormatter.formatNumber(incidentalRate ? this.renting.lease + incidentalRate : this.renting.lease) + " €";

      this.text = this.text.replace("(Betrag)", rent);
      this.text1 = this.text1.replace("(Betrag)", rent);
      this.text2 = this.text2.replace("(Betrag)", rent);
    },
    createPdf: function () {
      console.log(this.validateForm());
      if (this.validateForm() === false) {
        return;
      }

      let event = "SEND_PAYMENT_REMINDER";
      switch (this.monition.state) {
        case "OPEN":
          event = "SEND_PAYMENT_REMINDER";
          break;
        case "PAYMENT_REMINDER_SENT":
          event = "SEND_FIRST_MONITION";
          break;
        case "FIRST_MONITION_SENT":
          event = "SEND_SECOND_MONITION";
          break;
      }

      let createPdf = {
        templateId: 'pdf/letter.ftl',
        model: {
          "debtorSalutation": Salutations.germanSalutation(this.selectedRenterSalutation),
          "debtorPrename": this.renter.prename,
          "debtorLastname": this.renter.lastname,
          "debtorStreet": this.renter.street,
          "debtorZipCode": this.renter.zipCode,
          "debtorCity": this.renter.city,
          "senderPrename": this.user.prename,
          "senderLastname": this.user.lastname,
          "senderStreet": this.user.street,
          "senderZipCode": this.user.zipCode,
          "senderCity": this.user.city,
          "subject": this.subject,
          "text": this.text,
          "title": "Zahlungserinnerung"
        }
      };

      this.loading = true;
      HTTP.post("/pdfs", createPdf).then(response => {

        HTTP.post("/process-monition/" + this.monition.id + "/" + response.data.id + "/" + event).then(() => {
          this.$router.push({name: 'ShowMonition', params: {id: this.monition.id}}).then(() => {
            messageService.success('Speichern erfolgreich', 'PDF Dokument wurde erfolreich erstellt');
          })
        }).catch(() => {
          messageService.error('Speichern fehlgeschlagen', 'PDF Dokument konnte nicht erstellt werden');
          HTTP.delete("/pdfs/" + response.data.id);
        });
      }).catch(() => {
        messageService.error('Speichern fehlgeschlagen', 'PDF Dokument konnte nicht erstellt werden, es ist ein Fehler aufgetreten');
      });
    }
  }
}
</script>
<style>

</style>