export default class CreateUpdateRenter {
    constructor(salutation, prename, lastname, street, zipCode, city, phone, mobile, email) {
        this.salutation = salutation;
        this.prename = prename;
        this.lastname = lastname;
        this.street = street;
        this.zipCode = zipCode;
        this.city = city;
        this.phone = phone;
        this.mobile = mobile;
        this.email = email;
        this.contactInformation = {};
    }
}