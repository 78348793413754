<template>
  <div class="container">
    <div class="content">
      <b-card header="Neues Mahnverfahren starten">
        <b-form @submit.prevent="save">
          <b-row>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Überfällig seit"
                           tooltip="Tragen Sie hier ein seit wann der Mieter mit seiner Zahlung im Verzug ist."/>
                <b-form-datepicker id="overdueSince"
                                   v-model="date"
                                   :state="dateState"
                                   :value-as-date="true"></b-form-datepicker>

                <b-form-invalid-feedback>
                  Bitte tragen Sie ein korrektes Datum ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Gebäude" tooltip="Wählen Sie das Gebäude aus um welches es sich handelt."/>
                <b-form-select id="building"
                               v-model="selectedBuilding"
                               :options="buildings"
                               :state="buildingState"
                               @change="loadUnits"></b-form-select>
                <b-form-invalid-feedback>
                  Bitte wählen Sie ein Gebäude aus
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Einheit" tooltip="Wählen Sie die Einheit aus um die es sich handelt."/>
                <b-form-select id="unit"
                               v-model="selectedUnit"
                               :options="units"
                               :state="unitState"
                               @change="selectUnit"
                               :disabled="selectedBuilding === null || units === null || noUnits"></b-form-select>
                <b-form-invalid-feedback>
                  Bitte wählen Sie eine Einheit aus
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" variant="primary" class="float-right">Mahnverfahren starten</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>
import validatorService from '@/services/validator.service';
import messageService from '@/services/message.service';
import router from "@/config/router";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateMonition',
  components: {InfoLabel},
  data() {
    return {
      date: new Date(),
      unit: {},
      noUnits: false,
      buildings: null,
      selectedBuilding: null,
      units: [{
        value: null,
        text: 'Zuerst ein Gebäude auswählen'
      }],
      dateState: null,
      buildingState: null,
      unitState: null,
      selectedUnit: null
    }
  },
  created() {

    HTTP.get("/buildings?size=999").then(resp => {
      this.buildings = [{
        value: null,
        text: 'Bitte eine Auswahl treffen'
      }];
      resp.data.content.forEach(item => {
        this.buildings.push({
          text: item.city + " " + item.street,
          value: item.id
        });
      });
    });
    if (this.$route.params.unitId === undefined) {
      return;
    }
    HTTP.get("/units/" + this.$route.params.unitId).then(resp => {
      this.unit = resp.data;
      this.selectedUnit = this.unit.id;
      this.selectedBuilding = this.unit.buildingId;
      this.loadUnits();
    });
  },
  methods: {
    validateForm() {
      let isValid = true;
      if (validatorService.nullSelect(this.date)) {
        isValid = false;
        this.dateState = false;
      } else {
        this.dateState = true;
      }

      if (validatorService.nullSelect(this.selectedBuilding)) {
        isValid = false;
        this.buildingState = false;
      } else {
        this.buildingState = true;
      }

      if (validatorService.nullSelect(this.selectedUnit)) {
        isValid = false;
        this.unitState = false;
      } else {
        this.unitState = true;
      }

      return isValid;
    },
    selectUnit: function () {
      HTTP.get("/units/" + this.selectedUnit).then(resp => this.unit = resp.data);
    },
    loadUnits: function () {
      if (this.$route.params.unitId === undefined) {
        this.selectedUnit = null;
      }
      if (this.selectedBuilding === null) {
        this.units = [{
          value: null,
          text: 'Zuerst ein Gebäude auswählen'
        }];
        return;
      }

      HTTP.get("/units?size=999&buildingId=" + this.selectedBuilding).then(resp => {
        if (resp.data.content.length === 0) {
          this.units = [{
            value: null,
            text: 'Bitte zuerst eine Einheit im Gebäude anlegen'
          }];
          this.noUnits = true;
          return;
        }

        this.noUnits = false;
        this.units = [{
          value: null,
          text: 'Bitte eine Auswahl treffen'
        }];

        resp.data.content.forEach(item => {
          this.units.push({
            text: item.name,
            value: item.id
          });
        });
      });
    },
    save: function () {
      if (!this.validateForm()) {
        return;
      }

      HTTP.post("/monitions", {
        unitId: this.unit.id,
        overdueSince: this.date
      }).then(monition => {
        router.push({name: 'ShowMonition', params: {id: monition.data.id}}).then(() => {
          messageService.success('Speichern erfolgreich', 'Das Mahnverfahren wurde gestartet');
        });
      }).catch(() => {
        messageService.error('Fehler beim Speichern', 'Das Mahnverfahren konnte nicht gespeichert werden');
      });
    }
  }
}
</script>
<style>

</style>