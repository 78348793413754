<template>
  <b-container>
    <div class="content" v-if="(unitId === undefined || unitId === null) && unit == null">
      <b-card header="Gebäude und Einheit auswählen">
        <b-row>
          <b-col lg="3" sm="12">
            <b-select :options="buildings" v-model="selectedBuilding" @change="loadUnits"/>
          </b-col>
          <b-col lg="3" sm="12">
            <b-select :options="units" v-model="selectedUnit" :disabled="units == null" @change="loadRentings"/>
          </b-col>
          <b-col lg="3" sm="12">
            <b-select :options="rentings" v-model="selectedRenting" :disabled="rentings == null"/>
          </b-col>
          <b-col sm="12" lg="3">
            <b-button variant="primary" @click="selectUnit" :disabled="this.selectedRenting == null"
                      v-b-tooltip="'Sobald eine Einheit ausgewählt wurde können Sie mit diesem Button die Abrechnung starten'">
              Mietverhältnis auswählen
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="content" v-if="noRenting">
      <b-alert show variant="danger">
        Achtung, es ist kein Mietverhälnis eingetragen!<br/>
        <b>Ein Anschreiben, sowie eine korrekte Abrechnung kann nicht erfolgen</b>
      </b-alert>
      <div class="text-center">
        <b-button :to="{name:'ShowUnit', params:{id:this.unitId}}"
                  v-b-tooltip="'Klicken Sie hier um zur Einheit zu gelangen und eine Mietverhälnis anzulegen'"
                  variant="primary">Zur Einheit & Mietverhältnis
          anlegen
        </b-button>
      </div>
    </div>
    <div class="content" v-if="!noRenting && ((unitId !== undefined && unitId != null) || unit != null)">
      <div v-if="building !== undefined && building !== null && unit !== null" style="padding-bottom: 6px">
        <b-tag variant="success" disabled>
          <b-icon icon="house-door-fill"></b-icon>
          <b-icon icon="door-closed-fill"></b-icon>
          {{ this.building.city }} {{ this.building.street }} - {{ this.unit.name }}
        </b-tag>
        <b-tag v-for="renter in renters" v-bind:key="renter.id" variant="primary" disabled>
          <b-icon icon="person-fill"></b-icon>
          {{ renter.prename }}
          {{ renter.lastname }}
        </b-tag>
      </div>
      <b-alert variant="info" :show="!isFinished()" dismissible>Bevor Sie anfangen die Nebenkostenrechnung zu erstellen
        sollten Sie
        sicherstellen, dass alle Gebäudekosten und Quadratmeterzahlen, Einheiten, Personenzahlen korrekt hinterlegt
        sind.
        Andernfalls entsteht unnötiger manueller Rechenaufwand
      </b-alert>
      <b-alert :show="isFinished()" variant="success">
        Diese Abrechnung wurde bereits versendet und kann nicht mehr bearbeitet werden
      </b-alert>
      <b-form @submit.prevent="save()" novalidate>
        <b-row>
          <b-col lg="4" sm="12">
            <b-card header="Optionen">
              <b-form-group>
                <b-form-checkbox v-model="usageOther" name="check-button" :disabled="isFinished()" switch>
                  Abweichender Nutzungszeitraum
                  <InfoIcon
                      tooltip="Sollte der Nutzungszeitraum abweichen, kann durch diese Einstellung ein anderer Zeitraum gewählt werden."/>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="increaseIncidentalRate"
                                 name="check-button" :disabled="isFinished()" switch>
                  Vorrauszahlung anpassen
                  <InfoIcon
                      tooltip="Wenn Sie die Vorrauszahlung automatisch anpassen wollen aktivieren Sie diesen Schieber. Es wird ein enstprechender Satz in die Abrechnung eingefügt, zusätzlich ändert sich die Einstellung des Mietverhältnisses automatisch nach dem die Abrechnung auf versendet gestellt wurde."/>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="addCoverLetter" @change="coverLetterChange" name="check-button"
                                 :disabled="isFinished()" switch>
                  Standart Anschreiben beifügen
                  <InfoIcon tooltip="Es wird ein automatisch erstelltes Anschreiben vor Ihrer Abrechnung angefügt."/>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="customLetter" @change="customLetterChange" name="check-button"
                                 :disabled="isFinished()" switch>
                  Individuelles Anschreiben beifügen
                  <InfoIcon
                      tooltip="Es wird ein von Ihnen individuell erstelltes Anschreiben vor Ihrer Abrechnung angefügt."/>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="customComment" name="check-button" :disabled="isFinished()" switch>
                  Individuellen Kommentar anfügen
                  <InfoIcon tooltip="Am Ende der Abrechnung wird ein von Ihnen eingetragener Kommentar eingepflegt."/>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="rentingEnded" name="check-button" :disabled="isFinished()" switch>
                  Mietverhältnis ist beendet
                  <InfoIcon tooltip="Es wird ein Vermerk beigefügt, dass das Mietverhältnis beendet wurde."/>
                </b-form-checkbox>
              </b-form-group>
              <hr>
              Personen
              <b-row>
                <b-col>
                  <b-form-group label="Im Mietverhältnis">
                    <b-form-input type="number" v-model="personsUnit" :disabled="isFinished()"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Im Gebäude">
                    <b-form-input type="number" v-model="personsBuilding" :disabled="isFinished()"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-form-checkbox v-model="customPaid" name="check-button"
                                     :disabled="isFinished()" switch>
                      Abweichende Abschlagszahlung
                      <InfoIcon
                          tooltip="Sollte sich die Abschlagszahlung innerhalb des Jahres öfters geändert haben können Sie hier die vom Programm verwendete Abschlagszahlung für den gewählten Zeitraum abändern."/>
                    </b-form-checkbox>
                  </b-form-group>
                  <label v-if="customPaid">
                    Gezahlter Abschlag
                    <InfoIcon
                        tooltip="Hier können Sie den durch den Mieter gezahlten Gesamtabschlag im Abrechnungszeitraum einstellen. Standartmäßig wird dieser aus dem im Mietverhältnis hinterlegtem Abschlag berechnet"/>
                  </label>
                  <b-form-group v-if="customPaid">
                    <b-input-group append="€">
                      <b-form-input type="text" :state="paidIncidentalsValid" v-model="paidIncidentals"
                                    placeholder="Bspw.: 23,40"/>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col lg="8" sm="12">
            <b-card header="Datumsangaben">
              <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group label="Start des Abrechnungszeitraums">
                    <b-form-datepicker v-model="billingStart" :value-as-date="true"
                                       :disabled="isFinished()"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group label="Ende des Abrechnungszeitraums">
                    <b-form-datepicker v-model="billingEnd" :value-as-date="true"
                                       :disabled="isFinished()"></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="usageOther">
                <b-col lg="6" sm="12">
                  <b-form-group label="Start des Nutzungszeitraum">
                    <b-form-datepicker v-model="usingStart" :value-as-date="true"
                                       :disabled="isFinished()"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group label="Ende des Nutzungszeitraum">
                    <b-form-datepicker v-model="usingEnd" :value-as-date="true"
                                       :disabled="isFinished()"></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Ende des Mietverhältnisses" v-if="rentingEnded">
                <b-form-datepicker v-model="rentingEndDate" :value-as-date="true" :disabled="isFinished()"/>
              </b-form-group>
            </b-card>
            <b-card header="Ihr Anschreiben" v-if="customLetter">
              <textarea v-model="customLetterContent" class="form-control w-100" style="height: 300px"
                        placeholder="Bitte tragen Sie hier Ihr Anschreiben ein"></textarea>
            </b-card>
            <b-card header="Ihr Kommentar" v-if="customComment">
              <textarea v-model="customCommentContent" class="form-control w-100" style="height: 300px"
                        placeholder="Bitte tragen Sie hier Ihren Kommentar ein"></textarea>
            </b-card>
          </b-col>
        </b-row>

        <br>
        <b-card header="Kosten hinterlegen">
          <Infobox>
            <b-alert variant="warning" v-if="notCorrectRange()" show>Der Abrechnungszeitraum sollte immer genau 365 Tage
              betragen um ein korrektes Ergebnis zu erhalten.
            </b-alert>
            <b>Hinweis:</b> Achten Sie darauf, dass die eingetragenen Kosten sich auf den jeweiligen Zeitraum beziehen
            und das diese Werte plausibel sind.
            Sollten Sie einen Abrechnungszeitraum von einem Jahr eingetragen haben, müssen Sie auch die Kosten für ein
            Jahr eintragen.
            <br/><br/>
            Die geladenen Verbauchskosten sind umso genauer je näher der Ablesezeitraum am Verbrauchszeitraum ist.
            Wenn Sie Allgemeinstrom abrechnen tragen Sie diesen als Gebäudekosten ein (hier sollte der vom Versorger
            abgerechnete Wert eingetragen werden)
            mit Verteilerschlüssel Quadratmeter oder Personen.
          </Infobox>
          <b-button class="float-right" variant="success"
                    v-b-tooltip="'Fügen Sie einen Kostenpunkt hinzu, welcher nicht aus dem Gebäude oder Verbräuchen errechnet wird.'"
                    id="addCost" @click="addCost()" style="margin-left:5px" :disabled="isFinished()">
            Kostenpunkt hinzufügen
          </b-button>
          <b-button class="float-right" variant="primary" style="margin-left:5px" v-b-tooltip="'Klicken Sie hier um die Verbräuche anhand der eingetragenen Zählerstände zu laden, ' +
           'die anteiligen Kosten werden automatisch berechnet'" @click="loadUsages()" :disabled="isFinished()">
            Verbräuche laden
          </b-button>&nbsp;
          <b-button class="float-right" variant="primary" v-b-tooltip="'Klicken Sie hier um die Kosten des Gebäudes in die Übersicht zu laden, ' +
           'die anteiligen Kosten werden automatisch berechnet'" @click="buildingCostModal"
                    :disabled="isFinished()">Gebäudekosten
            laden
          </b-button>&nbsp;
          <br>
          <br>
          <br>
          <div class="clearfix"></div>
          <CostRow v-for="(item,i) in costs" :key="i" :model-value.sync="costs[i]" v-on:remove="removeCost(i)"
                   :no-delete="isFinished()"/>
          <div v-if="costs != null && costs.length === 0" class="text-center"
               style="margin-top: 50px; margin-bottom: 50px">
            Noch keine Kosten angelegt
          </div>
          <div v-if="!loadingPreview">
            <b-button variant="success" class="float-right"
                      v-b-tooltip.bottom="'Klicken Sie hier um die Abrechnung zu speichern, diese kann danach weiter bearbeitet werden. Sobald die Abrechnung als Versendet markiert wurde ist eine Bearbeitung nicht mehr möglich.'"
                      @click="save()"
                      style="margin-left:5px"
                      :disabled="isFinished()">Abrechnung speichern
            </b-button>
            <b-button variant="warning"
                      v-b-tooltip.bottom="'Klicken Sie hier um sich ein PDF Dokument mit Ihren Eingaben erstellen zu lassen.'"
                      class="float-right"
                      @click="preview()"
                      v-if="!isFinished()">Vorschau
            </b-button>
            <b-button variant="warning"
                      v-b-tooltip.bottom="'Klicken Sie hier um sich ein PDF Dokument mit Ihren Eingaben erstellen zu lassen.'"
                      class="float-right" @click="preview()" v-if="isFinished()">PDF anzeigen
            </b-button>
          </div>
          <Loading v-if="loadingPreview"/>
        </b-card>
      </b-form>
      <Comment :entity-id="this.id" entity-type="BillingOfIncidentals"
               v-if="this.id !== undefined && this.id !== null"/>
      <b-modal id="approveBuildingCost" title="Kosten überschreiben"
               cancel-title="Behalten & anhängen"
               @ok="loadBuildingCostAndCalculate(true)"
               @cancel="loadBuildingCostAndCalculate(false)"
               ok-title="Überschreiben" ok-variant="danger">
        Möchten Sie die bereits hinzugefügten Kosten überschreiben oder als neue Kosten anfügen?
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import {Salutations} from "@/models/static/salutations";
import messageService from '@/services/message.service';
import CostRow from "@/components/incidental/CostRow";
import {germanFormatter} from "@/services/formatter";
import Comment from "@/components/Comment";
import VueJwtDecode from "vue-jwt-decode";
import HTTP from "@/http";
import Loading from "@/components/Loading";
import InfoIcon from "@/components/InfoIcon";
import Infobox from "@/components/Infobox.vue";

export default {
  name: 'CreateIncidental',
  components: {Infobox, InfoIcon, Loading, Comment, CostRow},
  data() {
    return {
      id: this.$route.params.id,
      usageOther: false,
      increaseIncidentalRate: false,
      user: {salutation: null},
      unit: null,
      addCoverLetter: true,
      customLetter: false,
      customComment: false,
      costs: [],
      renter: {
        salutation: null
      },
      renters: [],
      rentings: null,
      selectedRenting: null,
      billingStart: new Date(),
      billingEnd: new Date(),
      usingStart: new Date(),
      usingEnd: new Date(),
      renterId: null,
      unitId: this.$route.params.unitId,
      building: null,
      salutations: Salutations.get(),
      jwt: VueJwtDecode.decode(localStorage.getItem("token")),
      boi: null,
      buildings: null,
      selectedBuilding: null,
      selectedUnit: null,
      units: null,
      state: null,
      noRenting: false,
      loadingPreview: false,
      rentingEnded: false,
      rentingEndDate: new Date(),
      rentingId: this.$route.params.rentingId === undefined ? null : this.$route.params.rentingId,
      personsUnit: null,
      personsBuilding: null,
      customLetterContent: null,
      customCommentContent: null,
      paidIncidentals: null,
      paidIncidentalsValid: null,
      incidentalRate: null,
      customPaid: false
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      HTTP.get("/billing-of-incidentals/" + this.$route.params.id).then(response => {
        this.boi = response.data;
        this.costs = this.boi.billingPoints;
        this.rentingId = this.boi.rentingId;
        this.unitId = this.boi.unitId;
        this.billingStart = new Date(this.boi.billingStart);
        this.billingEnd = new Date(this.boi.billingEnd);
        this.increaseIncidentalRate = this.boi.increaseIncidentalRate
        this.usingStart = this.boi.usingStart;
        this.usingEnd = this.boi.usingEnd;
        this.rentingEnded = this.boi.rentingEnded;
        this.rentingEndDate = new Date(this.boi.rentingEndDate);
        this.addCoverLetter = this.boi.addCoverLetter;
        this.personsUnit = this.boi.personsUnit;
        this.personsBuilding = this.boi.personsBuilding;
        this.customLetterContent = this.boi.customLetterContent;
        this.customCommentContent = this.boi.customCommentContent;
        this.customLetter = this.boi.customLetter;
        this.customComment = this.boi.customComment;
        this.paidIncidentals = this.boi.paidIncidentals;
        this.customPaid = this.boi.customPaidIncidentals;
        if (this.usingStart !== null) {
          this.usageOther = true;
        }
      }).then(() => {
        this.loadSecondaries(false);
      });
    } else {
      let date = new Date();
      let lastYear = date.getFullYear() - 1;

      this.billingStart = new Date(lastYear + "-01-01");
      this.billingEnd = new Date(lastYear + "-12-31");
      this.loadSecondaries(true);
    }
  },
  methods: {
    customLetterChange: function (val) {
      if(val === true) {
        this.addCoverLetter = false;
      }
    },
    coverLetterChange: function (val) {
      if(val === true) {
        this.customLetter = false;
      }
    },
    isFinished: function () {
      return !(this.boi == null || this.boi.state == null || this.boi.state === "IN_WORK");
    },
    removeCost: function (i) {
      this.costs.splice(i, 1);
    },
    addCost: function () {
      this.costs.push({
        billingPointKey: null,
        nameState: null,
        totalCostState: null,
        billingPointKeyState: null,
        costState: null,
        name: '',
        cost: null,
        totalCost: null,
        valid: 0
      });
    },
    selectUnit: function () {
      if (this.selectedUnit != null) {
        this.$router.push({
          name: 'CreateIncidental',
          params: {unitId: this.selectedUnit, rentingId: this.selectedRenting}
        });
        this.unitId = this.selectedUnit;
        this.rentingId = this.selectedRenting;

        this.loadSecondaries();
      }
    },
    notCorrectRange: function () {
      if (this.billingStart == null || this.billingEnd == null) {
        return true;
      }


      let timeDiff = this.billingEnd.getTime() - this.billingStart.getTime();
      let dayDiff = timeDiff / (1000 * 3600 * 24);
      return parseInt(dayDiff) !== 365;
    },
    loadRentings: function () {
      HTTP.get("/rentings?size=999&unitId=" + this.selectedUnit).then(response => {
        this.rentings = [{
          value: null,
          text: "Bitte Mietverhältnis wählen"
        }];

        if (response.data.content.length === 0) {
          this.rentings = [{
            value: null,
            text: "Es wurde noch kein Mietverhältnis angelegt"
          }];

          return;
        }

        response.data.content.forEach(item => {
          this.rentings.push({
            value: item.id,
            text: item.renterPrename + " " + item.renterLastname
          });
        });
      });
    },
    loadUnits: function () {
      if (this.selectedBuilding == null) {
        this.units = null;
        return;
      }

      this.selectedUnit = null;

      HTTP.get("/units?size=999&buildingId=" + this.selectedBuilding).then(response => {
        this.units = [{
          text: "Bitte Einheit auswählen",
          value: null
        }];

        if (response.data.content.length === 0) {
          this.units = [{
            value: null,
            text: "Es wurde noch keine Einheit angelegt"
          }];

          return;
        }
        response.data.content.forEach(item => {
          this.units.push({
            value: item.id,
            text: item.name
          });
        });
      });
    },
    loadSecondaries: function (newincidental) {
      HTTP.get("/users/" + this.jwt.userId).then(response => this.user = response.data);
      if (this.unitId === undefined || this.unitId === null) {
        HTTP.get("/buildings?size=999").then(response => {
          this.buildings = [{
            value: null,
            text: "Bitte Gebäude auswählen"
          }];

          if (response.data.content.length === 0) {
            this.buildings = [{
              value: null,
              text: "Es wurde noch kein Gebäude angelegt"
            }];

            return;
          }

          response.data.content.forEach(item => {
            this.buildings.push({
              value: item.id,
              text: item.city + " " + item.street
            });
          });
        });
        return;
      }

      HTTP.get("/units/" + this.unitId).then(response => {
        this.unit = response.data;
        this.rentingId = this.rentingId === null ? this.unit.currentRentingId : this.rentingId;
        if (this.rentingId == null) {
          this.noRenting = true;
        }

        if (this.rentingId !== null) {
          HTTP.get("/rentings/" + this.rentingId).then(response => {
            this.rentingEnded = response.data.end != null;
            if (this.rentingEnded) {
              this.rentingEndDate = response.data.end;
            }

            if (this.personsUnit == null) {
              this.personsUnit = response.data.persons;
            }
            this.incidentalRate = response.data.incidentalRate;
            if (newincidental && !isNaN(Number(this.incidentalRate))) {
              this.paidIncidentals = Number(this.incidentalRate) * 12;
            }
            response.data.renterIds.forEach(renterId => {
              HTTP.get("/renters/" + renterId).then(response => this.renters.push(response.data));
            })
          });
        }
        HTTP.get("/buildings/" + this.unit.buildingId).then(response => {
          this.building = response.data

          if (this.personsBuilding == null) {
            this.personsBuilding = this.building.persons;
          }
        });
      });
    },
    loadUsages: function () {
      HTTP.get("/counters?active=true&size=3000&unitId=" + this.unit.id).then(response => {
        let counters = response.data;

        counters.content.forEach(counter => {
          let calcRequest = {
            unitId: this.unit.id,
            counterId: counter.id,
            billingPointKey: 'BY_USAGE',
            usageStart: this.usageOther ? this.usingStart : this.billingStart,
            usageEnd: this.usageOther ? this.usingEnd : this.billingEnd
          };
          HTTP.post("/calculate/usage", calcRequest).then(response => {
            if (response.data.cost === 0) {
              messageService.hint('Berechnung fehlgeschlagen', 'Es wurden nicht genügend Zählerstände im Abrechnungszeitraum erfasst');
            }
            this.costs.push(Object.assign(response.data, {valid: 0}));
          }).catch(() => {
            messageService.error('Berechnung fehlgeschlagen', 'Für einen oder mehrere Zähler/Verbrauchskosten sind keine Werte vorhanden');
          });
        });
      });
    },
    buildingCostModal: function () {
      if (this.costs.length > 0) {
        this.$bvModal.show('approveBuildingCost');
      } else {
        this.loadBuildingCostAndCalculate(true);
      }
    },
    loadBuildingCostAndCalculate: function (override = false) {
      HTTP.get("/building-costs?active=true&size=3000&buildingId=" + this.unit.buildingId).then(response => {
        let buildingCosts = response.data;
        if (buildingCosts.content.length === 0) {
          messageService.hint('Keine Kosten gefunden', 'Es wurden noch keine Gebäudekosten hinterlegt');
        }

        buildingCosts.content.forEach(cost => {
          let calcRequest = {
            unitId: this.unit.id,
            buildingCostId: cost.id,
            billingPointKey: cost.billingPointKey,
            start: this.billingStart,
            end: this.billingEnd,
            usageStart: this.usageOther ? this.usingStart : this.billingStart,
            usageEnd: this.usageOther ? this.usingEnd : this.billingEnd,
            personsBuilding: this.personsBuilding,
            personsUnit: this.personsUnit
          };

          HTTP.post("/calculate", calcRequest).then(response => {
            if (!override) {
              let insert = Object.assign(response.data, {valid: 0});
              this.costs.push(insert);
            } else {
              let index = -1;
              for (let i = 0; i < this.costs.length; i++) {
                if (this.costs[i].name === response.data.name) {
                  index = i;
                }
              }

              if (index !== -1) {
                this.costs.splice(index, 1);
              }
              let insert = Object.assign(response.data, {valid: 0});
              this.costs.push(insert);
              this.validateForm();
            }
          });
        });
      });
    },
    getModel: function () {

      return {
        rentingId: this.rentingId,
        unitId: this.unitId,
        ante: 0,
        rentingEnded: this.rentingEnded,
        rentingEndDate: this.rentingEndDate,
        billingPoints: this.costs,
        billingStart: this.billingStart,
        billingEnd: this.billingEnd,
        usingStart: this.usageOther === false ? null : this.usingStart,
        usingEnd: this.usageOther === false ? null : this.usingEnd,
        increaseIncidentalRate: this.increaseIncidentalRate,
        addCoverLetter: this.addCoverLetter,
        personsUnit: this.personsUnit,
        personsBuilding: this.personsBuilding,
        customLetter: this.customLetter,
        customComment: this.customComment,
        customCommentContent: this.customCommentContent,
        customLetterContent: this.customLetterContent,
        paidIncidentals: this.paidIncidentals,
        customPaidIncidentals: this.customPaid
      };
    },
    preview: function () {
      if (!this.validateForm()) {
        messageService.hint('Anzeigen fehlgeschlagen', 'Vorschau konnte nicht erstellt werden, bitte prüfen Sie Ihre Kostenpunkte');
        return;
      }

      if (this.costs.length === 0) {
        messageService.hint('Anzeigen fehlgeschlagen', 'Vorschau konnte nicht erstellt werden, bitte tragen Sie vorher die enstandenen Kosten ein');
        document.getElementById("addCost").focus();
        return;
      }
      this.loadingPreview = true;
      HTTP.post("/billing-of-incidentals/preview", this.getModel(), {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "nebenkostenabrechnung-vorschau.pdf"
            link.click()
            URL.revokeObjectURL(link.href)
          }).finally(() => this.loadingPreview = false);
    },
    validateForm: function () {
      let isValid = true;
      const paidValid = germanFormatter.parseNumber(this.paidIncidentals);
      this.paidIncidentalsValid = true;

      for (let i = 0; i < this.costs.length; i++) {
        this.costs[i].cost = germanFormatter.parseNumber(this.costs[i].cost);
        if (isNaN(this.costs[i].cost) || this.costs[i].cost === null || this.costs[i].cost === "") {
          this.costs[i].costState = false;
          isValid = false;
        } else {
          this.costs[i].costState = true;
        }

        this.costs[i].totalCost = germanFormatter.parseNumber(this.costs[i].totalCost);
        if (isNaN(this.costs[i].totalCost) || this.costs[i].totalCost === null || this.costs[i].totalCost === "") {
          this.costs[i].totalCostState = false;
          isValid = false;
        } else {
          this.costs[i].totalCostState = true;
        }

        if (this.costs[i].name === undefined || this.costs[i].name === null || this.costs[i].name.length < 3) {
          this.costs[i].nameState = false;
          isValid = false;
        } else {
          this.costs[i].nameState = true;
        }

        if (this.costs[i].billingPointKey === null) {
          this.costs[i].billingPointKeyState = false;
          isValid = false;
        } else {
          this.costs[i].billingPointKeyState = true;
        }
        this.costs[i].valid = this.costs[i].valid + 1;
      }

      if (this.customPaid && (isNaN(paidValid) || paidValid === null || paidValid === "")) {
        isValid = false;
        this.paidIncidentalsValid = false;
      }
      return isValid;
    },
    save: function () {
      if (!this.validateForm()) {
        messageService.hint('Speichern fehlgeschlagen', 'Konnte nicht gespeichert werden, bitte prüfen Sie die Eingaben');
        return;
      }

      /*
      if (this.costs.length === 0) {
        messageService.hint('Speichern fehlgeschlagen', 'Konnte nicht gespeichert werden, bitte tragen Sie vorher die enstandenen Kosten ein');
        document.getElementById("addCost").focus();
        return;
      }*/
      if (this.id !== null && this.id !== undefined) {
        HTTP.put("/billing-of-incidentals/" + this.id, this.getModel(), {responseType: 'blob'})
            .then(() => {
              this.$router.push({name: 'ListIncidentals'}).then(() => {
                messageService.success('Abrechnung gespeichert', 'Ihre Nebenkostenabrechnung wurde erfolgreich bearbeitet');
              })
            }).catch(() => {
          messageService.error('Speichern fehlgeschlagen', 'Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder wenden Sie sich an den Support.');
        });
      } else {
        HTTP.post("/billing-of-incidentals", this.getModel(), {responseType: 'blob'})
            .then(() => {
              this.$router.push({name: 'ListIncidentals'}).then(() => {
                messageService.success('Abrechnung gespeichert', 'Ihre Nebenkostenabrechnung wurde erfolgreich gespeichert');
              });
            }).catch(() => {
          messageService.error('Speichern fehlgeschlagen', 'Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder wenden Sie sich an den Support.');
        });
      }

    }
  }
}
</script>
<style></style>