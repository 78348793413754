<template>
  <b-container>
    <div class="content">
      <b-card header="Nebenkostenabrechnungen">
        <b-row>
          <b-col>
            <b-form-group>
              <InfoLabel label="Nach Gebäude filtern"
                         tooltip="Klicken Sie hier um nach Gebäuden zu filtern und Nebenkostenabrechnungen nur für das ausgewählte Gebäude anzuzeigen."/>
              <b-select :options="buildings"
                        v-model="buildingId"
                        @change="loadIncidentalsByBuilding"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <InfoLabel label="Nach Einheit filtern"
                         tooltip="Klicken Sie hier um nach Einheiten zu filtern und Nebenkostenabrechnungen nur für die ausgewählte Einheit anzuzeigen."/>
              <b-select :options="units" v-model="unitId" @change="loadIncidentalsByUnit"
                        :disabled="buildingId === null || buildingId === undefined"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button :to="{name: 'CreateIncidental', params: {unitId: this.unitId}}"
                      variant="primary"
                      v-b-tooltip="'Klicken Sie hier um eine Nebenkostenabrechnung zu erstellen.'"
                      class="float-right"
                      v-if="incidentals != null && incidentals.totalElements > 0">
              Weitere erstellen
            </b-button>
          </b-col>
        </b-row>
        <br/>
        <br/>
        <b-skeleton-table v-if="incidentals == null"/>
        <b-table v-if="incidentals != null && incidentals.totalElements > 0" ref="table" sort-by="created" sort-desc
                 :no-local-sorting="true"
                 :items="incidentals.content" :fields="fields" class="card-table" responsive="sm"
                 v-on:sort-changed="loadIncidentals">
          <template #cell(state)="data">
            <b-badge :variant="data.item.state | boiStateBadge">{{ data.item.state | boiStateGerman }}</b-badge>
          </template>
          <template #cell(created)="data">
            {{ data.item.created | germanDate }}
          </template>
          <template #cell(actions)="data">
            <b-dropdown size="sm"
                        class="mr-1"
                        variant="success"
                        v-b-tooltip="'Klicken Sie hier um den Status der Abrechnung zu ändern, oder diese zu bearbeiten.'"
                        text="Bearbeiten" right>
              <b-dropdown-item @click="switchStateModal('SENT', data.item.id)" v-if="data.item.state === 'IN_WORK'">Als
                Versendet markieren
              </b-dropdown-item>
              <b-dropdown-item @click="switchStateModal('OVERDUE', data.item.id)" v-if="data.item.state === 'SENT'">Als
                überfällig markieren
              </b-dropdown-item>
              <b-dropdown-item @click="switchStateModal('PAYMENT_RECEIVED', data.item.id)"
                               v-if="data.item.state === 'SENT' || data.item.state === 'OVERDUE'">Als bezahlt markieren
              </b-dropdown-item>
              <b-dropdown-item @click="switchStateModal('GOT_ISSUES', data.item.id)"
                               v-if="data.item.state !== 'ISSUES' && data.item.state !== 'PAID'">Als Problemfall
                markieren
              </b-dropdown-item>
              <b-dropdown-item :to="{name:'EditIncidental', params: {id:data.item.id}}">Bearbeiten</b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteModal(data.item.id)">Löschen</b-dropdown-item>
            </b-dropdown>
            <b-button size="sm" @click="showIncidental(data.item.id)" variant="primary">
              <b-spinner label="Datei wird erstellt" small v-if="loading(data.item.id)"></b-spinner>
              <b-icon icon="file-earmark-text" v-else
                      v-b-tooltip="'Klicken Sie hier um sich die Abrechnung als PDF anzeigen zu lassen'"></b-icon>
            </b-button>
          </template>
        </b-table>
        <PageableTable v-if="incidentals != null && incidentals.totalElements > 0" :pageable="incidentals"
                       :to-route="'ListIncidentals'"
                       :routing-params="{unitId: unitId}"></PageableTable>

        <NoResultsCreateSuggest v-if="incidentals != null && incidentals.totalElements === 0"
                                text="Es wurden noch keine Nebenkostenabrechnungen erstellt"
                                :route="{name: 'CreateIncidental', params: {unitId: this.unitId}}"/>
      </b-card>
    </div>
    <b-modal id="delete-modal" title="Nebenkostenabrechnung wirklich löschen?" ok-variant="danger" ok-title="Löschen"
             cancel-title="Abbrechen"
             @ok="deleteIncidental()">Möchten Sie die ausgewählte Nebenkostenabrechnung wirklich löschen?
    </b-modal>
    <b-modal id="switch-state" title="Status" ok-variant="success" ok-title="Ja"
             cancel-title="Nein"
             @ok="switchState()">Möchten Sie die den Status der Nebenkostenabrechnung wirklich ändern?
    </b-modal>
  </b-container>
</template>
<script>
import NoResultsCreateSuggest from "@/components/NoResultsCreateSuggest";
import messageService from '@/services/message.service';
import PageableTable from "@/components/PageableTable";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'ListIncidentals',
  components: {InfoLabel, NoResultsCreateSuggest, PageableTable},
  data() {
    return {
      incidentals: null,
      sortStr: '',
      page: this.$route.params.page,
      unitId: this.$route.params.unitId === undefined ? null : this.$route.params.unitId,
      buildingId: null,
      buildings: null,
      unit: null,
      forDelete: null,
      eventToFire: null,
      changeId: null,
      units: [{
        text: "Bitte zuerst ein Gebäude wählen",
        value: null
      }],
      fields: [
        {
          key: 'buildingName',
          label: 'Gebäude',
          sortable: false
        },
        {
          key: 'unitName',
          label: 'Einheit',
          sortable: false
        },
        {
          key: 'created',
          label: 'Erstellt',
          sortable: true
        },
        {
          key: 'state',
          label: 'Status',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: "sm-col"
        },
      ],
      loadingId: null,
    }
  },
  created() {
    this.loadIncidentals();
    if (this.unitId !== null && this.unitId !== undefined) {
      HTTP.get("/units/" + this.unitId).then(response => {
        this.unit = response.data;
        this.buildingId = this.unit.buildingId;

        this.loadUnits();
      });
    }
    HTTP.get("/buildings?size=999").then(resp => {
      this.buildings = [{
        value: null,
        text: 'Bitte ein Gebäude auswählen'
      }];
      resp.data.content.forEach(item => {
        this.buildings.push({
          text: item.city + " " + item.street,
          value: item.id
        });
      });
    });
  },
  watch: {
    incidentals(to, from) {
      if (to != from) {
        this.loadNamings();
      }
    }
  },
  methods: {
    loading: function (id) {
      return id === this.loadingId;
    },
    deleteModal: function (id) {
      this.forDelete = id;
      this.$bvModal.show("delete-modal");
    },
    switchStateModal: function (event, id) {
      this.eventToFire = event;
      this.changeId = id;
      this.$bvModal.show('switch-state');
    },
    switchState: function () {
      for (let i = 0; i < this.incidentals.content.length; i++) {
        if (this.incidentals.content[i].id === this.changeId) {
          HTTP.post("/process-billing-of-incidentals/" + this.changeId + "/" + this.eventToFire).then(() => {
            HTTP.get("/billing-of-incidentals/" + this.changeId).then(response => {
              this.incidentals.content[i].state = response.data.state;
            });
          });
        }
      }
    },
    deleteIncidental: function () {
      HTTP.delete("/billing-of-incidentals/" + this.forDelete).then(() => {
        messageService.success('Löschen erfolgreich', 'Nebenkostenabrechnungen erfolgreich gelöscht')
        this.loadIncidentals();
      });
    },
    loadUnits: function () {
      this.units = [{
        text: "Bitte Auswahl treffen",
        value: null
      }];
      if (this.buildingId == null) {
        this.units = [{
          text: "Bitte zuerst ein Gebäude wählen",
          value: null
        }];
      } else {
        HTTP.get("/units?buildingId=" + this.buildingId + "&size=999").then(response => {
          response.data.content.forEach(item => {
            this.units.push({
              text: item.name,
              value: item.id
            })
          });
        });
      }
    },
    showIncidental: function (id) {
      this.loadingId = id;
      HTTP.get("/billing-of-incidentals/pdf/" + id, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "Nebenkostenabrechnung.pdf"
            link.click()
            URL.revokeObjectURL(link.href)
            this.loadingId = null;
          }).catch(() => {
        messageService.error("Fehler aufgetreten", "Bitte wenden Sie sich falls der Fehler häufiger auftritt an den Support");
      })
    },
    loadNamings: function () {
      for (let i = 0; i < this.incidentals.content.length; i++) {
        HTTP.get("/units/" + this.incidentals.content[i].unitId).then(response => {
          let unit = response.data;
          this.incidentals.content[i].unitName = unit.name;
          this.$refs.table.refresh();

          HTTP.get("/buildings/" + unit.buildingId).then(response => {
            this.incidentals.content[i].buildingName = response.data.city + " " + response.data.street;
            this.$refs.table.refresh();
          });
        });
      }
    },
    loadIncidentalsByUnit: function () {
      this.loadIncidentals();
    },
    loadIncidentalsByBuilding: function () {
      this.unitId = null;
      this.loadUnits();
      this.loadIncidentals();
    },
    loadIncidentals: function (sort) {
      let currentPage = this.page - 1;
      if (currentPage == null || isNaN(currentPage)) {
        currentPage = 0;
      }

      if (sort === undefined || sort === null) {
        this.sortStr = "&sort=created,desc";
      } else if (sort !== undefined && sort !== null) {
        this.sortStr = "&sort=" + sort.sortBy + "," + (sort.sortDesc ? "desc" : "asc");
      }

      let filter = "";
      if (this.unitId != null) {
        filter += "&unitId=" + this.unitId;
      }
      if (this.buildingId != null) {
        filter += "&buildingId=" + this.buildingId;
      }
      HTTP.get("/billing-of-incidentals?page=" + currentPage + filter + this.sortStr)
          .then(response => this.incidentals = response.data);
    }
  }
}
</script>
<style></style>