export class States {

    static getStateLocalized(STATE) {
        switch (STATE) {
            case "OPEN":
                return "Offen";
            case "PAYMENT_REMINDER_SENT":
                return "Zahlungserinnerung gesendet";
            case "FIRST_MONITION_SENT":
                return "Erste Mahnung gesendet";
            case "SECOND_MONITION_SENT":
                return "Zweite Mahnung gesendet";
            case "LEGAL_DUNNING":
                return "Gerichtsverfahren läuft";
            case "PAID":
                return "Bezahlt";
            case "CANCELED":
                return "Abgebrochen";
            case "FAILED":
                return "Fehlgeschlagen";
        }
    }

    static getStateBadgeVariant(STATE) {
        switch (STATE) {
            case "OPEN":
                return "warning";
            case "PAYMENT_REMINDER_SENT":
                return "warning";
            case "FIRST_MONITION_SENT":
                return "danger";
            case "SECOND_MONITION_SENT":
                return "danger";
            case "LEGAL_DUNNING":
                return "danger";
            case "PAID":
                return "success";
            case "CANCELED":
                return "secondary";
            case "FAILED":
                return "danger";
        }
    }
}