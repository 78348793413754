<template>
  <div class="mt-3">
    <b-pagination-nav :use-router="true" :link-gen="linkGen" :value="pageable.page+1"
                      :number-of-pages="pageable.totalPages"
                      align="center"></b-pagination-nav>
  </div>
</template>
<script>
export default {
  props: ['pageable', 'toRoute', 'routingParams'],
  data() {
    return {
      'routeName': this.toRoute,
      'routeParams': this.routingParams
    }
  },
  methods: {
    linkGen(pageNum) {
      let routeParams = {};
      Object.assign(routeParams, this.routeParams, {page: pageNum});

      return {
        name: this.routeName,
        params: routeParams
      }
    }
  }
}
</script>
<style>

</style>