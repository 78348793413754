import Vue from 'vue'
import App from './App.vue'
import router from "@/config/router";
import store from "@/config/store";
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import VeeValidate from 'vee-validate';

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCogs, faEuroSign, faPowerOff, faQuestion, faUserSecret} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import moment from 'moment';
import {States} from "@/models/states";
import {Salutations} from "@/models/static/salutations";
import {UnitTypes} from "@/models/static/unit-types";
import {CounterTypes} from "@/models/static/counter-types";
import {SupplierTypes} from "@/models/static/supplier-types";
import {BillingPointKeys} from "@/models/static/billing-point-keys";
import {BillingOfIncidentalsStates} from "@/models/static/boi-states";
import {GarageTypes} from "@/models/static/garage-types";

Vue.filter('germanDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
});

Vue.filter('germanDateTime', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
    }
});

Vue.filter('monitionStateGerman', function (value) {
    return States.getStateLocalized(value);
});

Vue.filter('monitionStateBadge', function (value) {
    return States.getStateBadgeVariant(value);
});

Vue.filter('boiStateGerman', function (value) {
    return BillingOfIncidentalsStates.getStateLocalized(value);
});

Vue.filter('boiStateBadge', function (value) {
    return BillingOfIncidentalsStates.getStateBadgeVariant(value);
});

Vue.filter('germanSalutation', function (value) {
    return Salutations.germanSalutation(value);
});

Vue.filter('germanUnitType', function (value) {
    return UnitTypes.germanType(value);
});
Vue.filter('germanCounterType', function (value) {
    return CounterTypes.germanType(value);
});
Vue.filter('germanSupplierType', function (value) {
    return SupplierTypes.germanType(value);
});

Vue.filter('germanBillingPoint', function (value) {
    return BillingPointKeys.germanKey(value);
});

Vue.filter('germanGarageType', function (value) {
    return GarageTypes.germanType(value);
});

Vue.filter('germanNumber', function (value) {
    return new Intl.NumberFormat("de-de", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
});

Vue.filter('invoiceBadge', function (value) {
    if (value === "open") {
        return "warning"
    } else if (value === "paid") {
        return "success"
    } else if (value === "overdue") {
        return "danger"
    }
    return "secondary";
});

Vue.filter('invoiceStatusGerman', function (value) {
    if (value === "open") {
        return "Offen"
    } else if (value === "paid") {
        return "Bezahlt"
    } else if (value === "overdue") {
        return "Überfällig"
    } else if (value === "voided") {
        return "Storniert";
    }
    return value;
});

Vue.filter('dec', function (value) {
    return new Intl.NumberFormat("de-DE", {
        style: "decimal",
        maximumFractionDigits: 2, minimumFractionDigits: 2
    }).format(value);
});

Vue.filter('currency', function (value) {
    return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(value);
});

Vue.filter('percent', function (value) {
    return new Intl.NumberFormat("de-DE", {style: "percent",}).format(value / 100);
});

Vue.filter('entityNameToGerman', function (value) {
    if (value === 'BillingOfIncidentals') {
        return 'Nebenkostenabrechnung';
    }

    if (value === 'renter') {
        return 'Mieter';
    }

    if (value === 'unit') {
        return 'Einheit';
    }

    if (value === 'Building') {
        return 'Gebäude';
    }

    if (value === 'Monition') {
        return 'Mahnung';
    }

    if (value === 'supplier') {
        return 'Dienstleister';
    }
    return value;
});

library.add(faCogs)
library.add(faPowerOff)
library.add(faUserSecret)
library.add(faQuestion)
library.add(faEuroSign)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VeeValidate, {fieldsBagName: 'veeFields'});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
