<template>
  <div class="container">
    <div class="content" v-if="counter != null">
      <b-card header="Zählerinformationen & Zählerstand erfassen">
        <b-row>
          <b-col>
            <b>Zählernummer:</b> {{ counter.counterId }}
          </b-col>
          <b-col>
            <b>Zählertyp:</b> {{ counter.counterType | germanCounterType }}
          </b-col>
        </b-row>
        <hr>
        <b-form @submit.prevent="saveCounterValue">
          <b-row>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Neuen Zählerstand erfassen" tooltip="Tragen Sie hier den aktuellen Stand ein."/>
                <b-form-input type="number" v-model="newCounter" id="newCounter" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group v-if="this.loadingPicture"
                            label-for="input-6">
                <InfoLabel label="Bild wird hochgeladen" tooltip="Das Bild des Zählerstands wird aktuell hochgeladen"/>
                <div class="text-center">
                  <b-icon animation="cylon" icon="three-dots"></b-icon>
                </div>
              </b-form-group>
              <b-form-group v-if="this.pictureId == null && !this.loadingPicture">
                <InfoLabel label="Bild vom Zählerstand"
                           tooltip="Laden Sie hier ein Bild des Zählerstands hoch, als Nachweis für den Mieter"/>
                <b-form-file
                    placeholder="Datei hochladen (PDF, JPEG oder PNG)"
                    v-on:change="this.uploadImage"
                ></b-form-file>
              </b-form-group>
              <b-form-group v-if="this.pictureId != null && !this.loadingPicture">
                <InfoLabel label="Bild bereits hochgeladen"
                           tooltip="Es wurde bereits ein Bild hochgeladen, klicken Sie auf Neue Datei hochladen um dieses zu ersetzen"/>
                <br>
                <b-button
                    variant="secondary"
                    v-on:click="this.resetImage"
                >Neue Datei hochladen
                </b-button>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Datum eintragen" tooltip="Tragen Sie hier das Datum des Zählerstands ein"/>
                <b-datepicker v-model="date" :value-as-date="true"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="9" sm="0"></b-col>
            <b-col lg="3" sm="12" class="text-right">
              <b-button type="submit" variant="primary">Zählerstand hinzufügen</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <br>
      <b-card header="Erfasste Zählerstände">
        <b-table :items="counterValues.content" :fields="fields" sort-by="date" v-if="counterValues.totalElements > 0"
                 sort-desc>
          <template #cell(date)="data">{{ data.item.date|germanDateTime }}</template>
          <template #cell(actions)="data">
            <b-button @click.prevent="showImage(data.item.pictureId)" variant="success"
                      v-b-tooltip="'Hier klicken um das Bild des Zählerstandes anzuzeigen'">
              <b-icon icon="image"></b-icon>
            </b-button>&nbsp;&nbsp;
            <b-button @click.prevent="deleteValue(data.item.id)" variant="danger"
                      v-b-tooltip="'Hier klicken um den Zählerstand zu löschen'">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
        <b-alert :show="counterValues.totalElements ===0" variant="secondary">Es wurden noch keine Zählerstände
          erfasst
        </b-alert>
      </b-card>
      <b-modal hide-footer id="image-modal" :cancel-disabled="true"><img :src="imageContent" id="image"
                                                                         style="max-width: 100%; max-height: 300px">
        <b-button class="mt-3" block @click="$bvModal.hide('image-modal')">schließen</b-button>
      </b-modal>
    </div>
    <DeleteModal ref="delmod" modal-text="Zählerstand wirklich löschen" title="Zählerstand löschen?" v-model="deleteId"
                 rest-url="counter-values" id="delete-mdl" @deleted="loadValues"/>
  </div>
</template>
<script>
import messageService from '@/services/message.service';
import DeleteModal from "@/components/DeleteModal";
import FileService from "@/services/file.service";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'ShowCounter',
  components: {InfoLabel, DeleteModal},
  data() {
    return {
      counter: null,
      counterValues: null,
      loadingPicture: false,
      pictureId: null,
      imageContent: null,
      newCounter: null,
      date: new Date(),
      fields: [
        {
          key: 'date',
          label: 'Datum'
        },
        {
          key: 'value',
          label: 'Stand'
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      deleteId: null
    }
  },
  created() {
    HTTP.get("/counters/" + this.$route.params.id).then(resp => this.counter = resp.data);
    this.loadValues();
  },
  methods: {
    deleteValue: function (id) {
      this.deleteId = id;
      this.$refs.delmod.openModal();
    },
    loadValues: function () {
      HTTP.get("/counter-values?counterId=" + this.$route.params.id).then(resp => this.counterValues = resp.data);
    },
    saveCounterValue: function () {
      if (this.pictureId == null) {
        messageService.hint('Kein Bild hochgeladen', 'Es wird ein Bild für den Zählerstand benötigt');
        return;
      }

      let newCounterValue = {
        value: this.newCounter,
        pictureId: this.pictureId,
        date: this.date,
        counterId: this.counter.id
      };


      HTTP.post("/counter-values", newCounterValue).then(() => {
        messageService.success('Speichern erfolgreich', 'Zählerstand erfolgreich hinterlegt');
        this.loadValues();
        this.newCounter = null;
        this.pictureId = null;
        this.date = null;
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          messageService.hint('Zählerstand zu gering', 'Der eingetragene Zählerstand ist zu gering');
        }

      });
    },
    showImage: function (imageId) {
      this.$bvModal.show("image-modal");
      HTTP.get("/files/data/" + imageId).then(resp => {
        this.imageContent = resp.data;
      });
    },
    uploadImage: function (item) {
      console.log(item.target.files);
      this.loadingPicture = true;
      FileService.upload(item.target.files[0]).then(resp => {
        this.pictureId = resp.data.id;
        this.loadingPicture = false;
        messageService.success('Speichern erfolgreich', 'Datei erfolgreich hochgeladen');
      }).catch(() => {
        this.pictureId = null;
        this.loadingPicture = false;
        messageService.error('Speichern fehlgeschlagen', 'Datei konnte nicht hochgeladen werden');
      });
    },
    resetImage: function () {
      this.pictureId = null;
      HTTP.delete('/files/' + this.pictureId);
    }
  }
}
</script>
<style>

</style>