<template>
  <div class="text-center createSuggest">
    <b-icon icon="plus-square" class="plus-icon" font-scale="3"/>
    <br/><br/>
    {{ text }}<br/><br/>
    <router-link v-if="btnText === undefined" tag="a" :to="route" :id="uuid"
                 class="btn btn-primary" v-b-tooltip="tooltip">Jetzt anlegen
    </router-link>
    <router-link v-if="btnText !== undefined" tag="a" :to="route" :id="uuid"
                 class="btn btn-primary" v-b-tooltip="tooltip">{{btnText}}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'NoResultsCreateSuggest',
  props: ['text', 'route', 'tooltip','btnText'],
  data () {
    return {
      uuid : "btn"+this._uid.toString()
    }
  },
  updated() {
    document.getElementById(this.uuid).focus();
  }
}
</script>
<style scoped>
.plus-icon {
  color: #72a3d2 !important;
}

.createSuggest{
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>