import HTTP from "@/http";

class ZipcodeService {

    list(zipcode) {
        if (zipcode === undefined || zipcode === null) {
            return null;
        }

        return HTTP.get("/zip-code-cities?zipCode=" + zipcode);
    }

    autocomplete(zipCode) {
        if (zipCode === null || zipCode === undefined || zipCode.length < 4) {
            return new Promise((resolve) => {
                resolve('');
            });
        }

        let list = this.list(zipCode).then(response => {
            if (response.data.totalElements === 1) {
                return response.data.content[0].city;
            }
        });

        if (list !== null && list !== undefined) {
            return list;
        }
        return new Promise((resolve) => {
            resolve('');
        });
    }
}

export default new ZipcodeService();