export class GarageTypes {
  static get() {
    return [
      {
        value: null,
        text: 'Bitte Auswahl treffen'
      },
      {
        value: 'DOUBLE',
        text: 'Doppelgarage'
      },
      {
        value: 'SINGLE',
        text: 'Einfache Garage'
      },
      {
        value: 'CARPORT',
        text: 'Carport'
      },
      {
        value: 'LOT',
        text: 'Stellplatz'
      },
      {
        value: 'UNDERGROUND',
        text: 'Tiefgarage'
      },
      {
        value: 'CAMPER',
        text: 'Campingwagen Stellplatz'
      }
    ];
  }

  static germanType(type) {
    let types = this.get();
    for (let i = 0; i < types.length; i++) {
      if (types[i].value == type) {
        return types[i].text;
      }
    }
    return type;
  }
}