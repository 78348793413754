<template>
  <div class="container">
    <div class="content">
      <b-card header="Garage anlegen">
        <b-form @submit.prevent="persist">
          <b-row>
            <b-col>
              <b-form-group>
                <InfoLabel label="Name" tooltip="Tragen Sie hier einen Bezeichner für die Garage / den Stellplatz ein"/>
                <b-form-input type="text" v-model="garage.name" placeholder="Tiefgaragenplatz 2" max="10" min="3"
                              :state="nameState"
                              trim
                              required></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  Bitte trage mindestens 3 Zeichen und maximal 255 Zeichen ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <InfoLabel label="Typ" tooltip="Typ der Garage / des Stellplatzes"/>
                <b-form-select :options="garageTypes" type="text" id="counterType"
                               :state="typeState"
                               v-model="garage.type"
                               required
                               placeholder=""></b-form-select>

                <b-form-invalid-feedback id="input-live-feedback">
                  Bitte wählen Sie einen passenden Typ aus
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <InfoLabel label="Anzahl Fahrzeuge"
                           tooltip="Tragen Sie hier ein, wie viele Fahrzeuge auf dem Platz abgestellt werden können."/>
                <b-form-input type="number" v-model="garage.vehicles" placeholder="1" min="1"
                              :state="vehicleState"
                              trim
                              required></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  Bitte trage eine korrekte Zahl ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <InfoLabel label="Monatlicher Preis"
                           tooltip="Tragen Sie hier den gewünschten Mietpreis pro Monat ein."/>
                <b-form-input type="text" v-model="garage.monthlyLease" placeholder="1" min="1"
                              :state="monthlyLeaseState"
                              trim
                              required></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  Bitte trage eine korrekte Zahl ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="float-right" variant="primary">Garage speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>

import InfoLabel from "@/components/InfoLabel";
import CreateUpdateGarage from "@/models/api/create-garage";
import {GarageTypes} from "@/models/static/garage-types";
import messageService from "@/services/message.service";
import validatorService from "@/services/validator.service";
import {germanFormatter} from '@/services/formatter';
import HTTP from "@/http";

export default {
  name: 'CreateGarage',
  components: {InfoLabel},
  data() {
    return {
      'garageTypes': GarageTypes.get(),
      'garage': new CreateUpdateGarage(),
      'unitId': this.$route.params.unitId,
      'buildingId': this.$route.params.buildingId,
      'typeState': undefined
    }
  },
  computed: {
    nameState() {
      if (this.garage.name === undefined) {
        return undefined;
      }
      return this.garage.name.length > 3 && this.garage.name.length < 255;
    },
    vehicleState() {
      if (this.garage.vehicles === undefined) {
        return undefined;
      }
      return !isNaN(this.garage.vehicles) && Number.isInteger(Number.parseInt(this.garage.vehicles));
    },
    monthlyLeaseState() {
      if (this.garage.monthlyLease === undefined) {
        return undefined;
      }
      const monthlyLeaseCheck = germanFormatter.parseNumber(this.garage.monthlyLease);
      return !validatorService.correctNumberRequired(monthlyLeaseCheck);
    }
  },
  created() {
    if (this.$route.params.copy || this.$route.params.id !== undefined) {
      HTTP.get('/garages/' + this.$route.params.id).then(response => {
        const apiGarage = response.data;
        this.garage = new CreateUpdateGarage(
            apiGarage.buildingId,
            apiGarage.type,
            apiGarage.monthlyLease,
            apiGarage.vehicles,
            apiGarage.name,
        );
      });
    }
    this.garage.type = null;
    this.garage.buildingId = this.$route.params.buildingId;
    this.garage.unitId = this.$route.params.unitId === undefined ? null : this.$route.params.unitId;
  },
  methods: {
    validate() {
      this.typeState = this.garage.type !== null;
      return this.typeState && this.nameState && this.monthlyLeaseState;
    },
    persist() {
      if (!this.validate()) {
        messageService.hint('Bitte alle Felder füllen', 'Es wurden nicht alle Felder korrekt ausgefüllt, bitte prüfen Sie Ihre Eingabe.');
        return;
      }

      if (!this.$route.params.copy && this.$route.params.id !== undefined) {
        HTTP.put("/garages/" + this.$route.params.id, this.garage).then(() => {
          this.$router.push({name: "ShowBuildingTab", params: {id: this.garage.buildingId, activeTab:'garage'}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Die Garage wurde erfolreich bearbeitet');
          });
        }).catch(() => messageService.error('Fehlgeschlagen', 'Leider ist beim Speichern etwas fehlgeschlagen'));
      } else {
        HTTP.post("/garages", this.garage).then(() => {
          this.$router.push({name: "ShowBuildingTab", params: {id: this.garage.buildingId, activeTab:'garage'}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Die Garage wurde erfolreich angelegt');
          });
        }).catch(() => messageService.error('Fehlgeschlagen', 'Leider ist beim Speichern etwas fehlgeschlagen'));
      }
    }
  }
}
</script>
<style>

</style>