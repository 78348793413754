import moment from "moment/moment";

export const germanFormatter = {
    parseNumber: (number) => {
        if (number === undefined || number === null) {
            return null;
        }
        return (number + "").replaceAll(",", ".");
    },
    formatNumber: (number) => {
        if(number.includes(",")){
            return number;
        }

        if (number === undefined || number === null) {
            return null;
        }

        if (number === "") {
            return null;
        }
        return new Intl.NumberFormat('de-de', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping:false}).format(number);
    },
    formatDate: (date) => {
        if (date === null || date === undefined) {
            return "";
        }

        return moment(String(date)).format('DD.MM.YYYY')
    }
}
