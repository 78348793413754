<template>
  <b-row>
    <b-col lg="1" sm="12" v-if="!hideDelete">
      <b-form-group label="x" label-class="dltlbl">
        <b-button variant="danger" @click="$emit('remove')" v-b-tooltip="'Mit einem Klick auf diesen Button löschen Sie diesen Kostenpunkt herraus.'">
          <b-icon icon="trash"/>
        </b-button>
      </b-form-group>
    </b-col>
    <b-col lg="4" sm="12" v-if="!hideDelete">
      <b-form-group>
        <InfoLabel label="Name" tooltip="Tragen Sie hier den Namen des Kostenpunktes ein."/>
        <b-form-input type="text"
                      v-model="value.name"
                      :state="value.nameState"
                      placeholder="Bspw.: Grundsteuer"
                      :disabled="hideDelete"/>
      </b-form-group>
      <b-form-invalid-feedback :state="value.nameState">
        Bitte tragen Sie einen Namen ein min. 3 Zeichen
      </b-form-invalid-feedback>
    </b-col>
    <b-col lg="5" sm="12" v-if="hideDelete">
      <b-form-group>
        <InfoLabel label="Name" tooltip="Tragen Sie hier den Namen des Kostenpunktes ein."/>
        <b-form-input type="text"
                      v-model="value.name"
                      :state="value.nameState"
                      placeholder="Bspw.: Grundsteuer"
                      :disabled="hideDelete"/>
      </b-form-group>
      <b-form-invalid-feedback :state="value.nameState">
        Bitte tragen Sie einen Namen ein min. 3 Zeichen
      </b-form-invalid-feedback>
    </b-col>
    <b-col lg="2" sm="12">
      <b-form-group>
        <InfoLabel label="Gesamtkosten" tooltip="Tragen Sie hier die Gesamtkosten für das Gebäude ein. Bei Verbräuchen den Verbrauchswert für die gewählte Einheit."/>
        <b-input-group append="€">
          <b-form-input type="text"
                        v-model="value.totalCost"
                        :state="value.totalCostState"
                        placeholder="Bspw.: 2300,00"
                        :disabled="hideDelete"/>
        </b-input-group>
        <b-form-invalid-feedback :state="value.totalCostState">
          Bitte tragen Sie eine Zahl ein
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <b-col lg="3" sm="12">
      <b-form-group>
        <InfoLabel label="Verteilerschlüssel" tooltip="Der Verteilerschlüssel gibt die Berechnungsgrundlage an."/>
        <b-form-select :options="billingPointKeys" type="text" :state="value.billingPointKeyState" v-model="value.billingPointKey" :disabled="hideDelete"/>
      </b-form-group>
      <b-form-invalid-feedback :state="value.billingPointKeyState">
        Bitte wählen Sie einen Verteilerschlüssel aus
      </b-form-invalid-feedback>
    </b-col>
    <b-col lg="2" sm="12">
      <b-form-group>
        <InfoLabel label="Mieterbeitrag" tooltip="Tragen Sie hier die Kosten ein, welche durch den Mieter getragen werden." />
        <b-input-group append="€">
          <b-form-input type="text"
                        v-model="value.cost"
                        :state="value.costState"
                        placeholder="Bspw.: 23,40"
                        :disabled="hideDelete"/>
        </b-input-group>
        <b-form-invalid-feedback :state="value.costState">
          Bitte tragen Sie Kosten ein
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import {BillingPointKeys} from "@/models/static/billing-point-keys";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CostRow',
  components: {InfoLabel},
  props: ['modelValue', 'noDelete'],
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {

  },
  data() {
    return {
      billingPointKeys: BillingPointKeys.get(),
      hideDelete: this.noDelete
    }
  }
}
</script>
<style>
.dltlbl {
  visibility: hidden;
}
</style>