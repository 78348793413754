<template>
  <div></div>
</template>

<script>
import AuthService from '@/services/auth.service';

export default {
  name: 'Logout',
  created() {
    if (this.$store.state.auth.status.loggedIn) {
      AuthService.logout();
      this.$emit('logout');
      this.$router.push({name:'Login'});
    }
  }
};
</script>

<style scoped>
</style>
