<template>
  <div class="container">
    <div class="content">
      <b-alert variant="warning" v-if="id != null && !copy" show dismissible>
        Achtung die bestehenden Kosten zu ändern ist nicht empfehlenswert! Sollten diese schon bei
        Nebenkostenabrechnungen angegeben worden sein
        kann dies deren Berechnung verfälschen. Deaktivieren sie stattdessen die kosten und kopieren Sie diese.
      </b-alert>
      <b-alert variant="secondary" v-if="copy" dismissible show>
        Die eingetragenen Daten wurden kopiert
      </b-alert>
      <b-card header="Gebäudekosten anlegen">
        <b-form @submit.prevent="save" novalidate>
          <b-row>
            <b-col lg="8" sm="12">
              <b-form-group>
                <InfoLabel label="Beschriftung der Kosten"
                           tooltip="Diese Beschriftung erscheint auch bei der Nebenkostenabrechnung"/>
                <b-form-input v-model="buildingCost.name"
                              placeholder="Bspw.: Grundsteuer"
                              :state="nameValid"
                              required/>
                <b-form-invalid-feedback>
                  Bitte tragen Sie einen Namen mit mindestens 3 Zeichen ein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group>
                <InfoLabel label="Umlageschlüssel"
                           tooltip="Der Umlageschlüssel wird bei der Nebenkostenabrechnung angewendet"/>
                <b-form-select :options="billingPointKeys" v-model="buildingCost.billingPointKey"
                               :state="billingPointKeyValid"

                               required></b-form-select>
                <b-form-invalid-feedback>
                  Bitte wählen Sie einen Umlageschlüssel aus
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="8" sm="12">
              <b-form-group>
                <InfoLabel label="Kosten in Euro" tooltip="Dies ist der Gesamtbetrag in Euro pro Jahr für das gesamte Gebäude"/>
                <b-form-input v-model="buildingCost.cost" :state="costValid"
                              :formatter="germanFormatter.formatNumber" type="text"
                              placeholder="Bspw.: 104,68" required/>
                <b-form-invalid-feedback>
                  Der eingetragene Wert muss eine Zahl sein
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="2" sm="12">
              <b-form-group label=""><br>
                <b-form-checkbox v-model="buildingCost.active" name="check-button" switch>
                  aktiviert
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="2" sm="12">
              <b-form-group label=""><br>
                <b-form-checkbox v-model="buildingCost.allocatable" name="check-button" switch>
                  umlegbar
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" class="float-right" variant="primary">Speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>
import {BillingPointKeys} from "@/models/static/billing-point-keys";
import messageService from '@/services/message.service';
import {germanFormatter} from "@/services/formatter";
import HTTP from "@/http";
import InfoLabel from "@/components/InfoLabel";

export default {
  name: 'CreateBuildingCost',
  components: {InfoLabel},
  data() {
    return {
      id: this.$route.params.id,
      copy: !!this.$route.params.copy,
      buildingCost: {
        buildingId: this.$route.params.buildingId,
        billingPointKey: null,
        allocatable: true,
        active: true,
        cost: null,
        name: null,
        paymentInterval: 'YEARLY'
      },
      costValid: null,
      billingPointKeyValid: null,
      nameValid: null,
      billingPointKeys: BillingPointKeys.get('BY_USAGE'),
      germanFormatter: germanFormatter
    }
  },
  created() {
    if (this.id == null) {
      return;
    }
    HTTP.get("/building-costs/" + this.id).then(response => {
      this.buildingCost = response.data;
      this.buildingCost.cost = germanFormatter.formatNumber(this.buildingCost.cost);
    });
  },
  methods: {
    validateForm: function () {
      let isValid = true;
      this.buildingCost.cost = germanFormatter.parseNumber(this.buildingCost.cost);
      if (isNaN(this.buildingCost.cost) || this.buildingCost.cost == null) {
        this.costValid = false;
        isValid = false;
      } else {
        this.costValid = true;
      }

      if (this.buildingCost.name == null || this.buildingCost.name.length < 3) {
        isValid = false;
        this.nameValid = false;
      } else {
        this.nameValid = true;
      }

      if (this.buildingCost.billingPointKey == null) {
        isValid = false;
        this.billingPointKeyValid = false;
      } else {
        this.billingPointKeyValid = true;
      }

      return isValid;
    },
    save: function () {
      if (!this.validateForm()) {
        return;
      }

      if (this.copy || this.id == null) {
        HTTP.post("/building-costs", this.buildingCost).then(() => {
          this.$router.push({name: "ShowBuildingTab", params: {id: this.buildingCost.buildingId, activeTab:'building-cost'}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Kosten erfolgreich erstellt');
          });
        });
      } else {
        HTTP.put("/building-costs/" + this.id, this.buildingCost).then(() => {
          this.$router.push({name: "ShowBuildingTab", params: {id: this.buildingCost.buildingId, activeTab:'building-cost'}}).then(() => {
            messageService.success('Speichern erfolgreich', 'Kosten erfolgreich bearbeitet');
          });
        });
      }
    }
  }
}
</script>
<style>

</style>